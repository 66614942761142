import React from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { scrollToElement } from '../../../helpers/Data';
import { WithAnchor } from '../../../hoc';
import './ScrollDown.scss';

interface Props {
  color?: string | undefined;
  fontSize?: string | undefined;
  anchor?: string | undefined;
}

export default (props: Props) => {
  const { color, fontSize, anchor } = props;

  return (
    <div className='arrow bounce'>
      <WithAnchor anchor={anchor} component={scrollToElement}>
        <KeyboardArrowDownIcon style={{ color: color, fontSize: fontSize }} />
      </WithAnchor>
    </div>
  );
};
