import React, { MouseEvent, ReactElement, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import ContentConst from '../../../constants/ContentConst';
import ButtonsConst from '../../../constants/ButtonsConst';

interface Props {
  catalogId?: string | undefined;
  children?: ReactElement | undefined;
  handleRemoveCatalogItem: () => void;
  toggleEditState: () => void;
  editState: boolean | false;
}

export default (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMoreMenu = Boolean(anchorEl);
  const [removeEntryAlert, setRemoveEntryAlert] = useState(false);

  const handleMoreMenuClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreMenuClose = (event: MouseEvent<HTMLElement>) => {
    if (event.currentTarget.dataset.action === 'remove')
      handleRemoveEntryAlert();

    if (event.currentTarget.dataset.action === 'edit') handleEditButton();

    setAnchorEl(null);
  };

  const handleEditButton = () => {
    props.toggleEditState();
  };

  const handleRemoveEntryAlert = () => {
    setRemoveEntryAlert(!removeEntryAlert);
  };

  return (
    <>
      <IconButton
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleMoreMenuClick}
        size='small'
      >
        <MoreVert />
      </IconButton>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={openMoreMenu}
        onClose={handleMoreMenuClose}
      >
        <MenuItem
          onClick={handleMoreMenuClose}
          data-action='edit'
          // disabled={props.editState}
          disabled={true}
        >
          {ButtonsConst.EDIT}
        </MenuItem>
        <MenuItem onClick={handleMoreMenuClose} data-action='remove'>
          {ButtonsConst.REMOVE}
        </MenuItem>
      </Menu>

      <Dialog
        open={removeEntryAlert}
        onClose={handleRemoveEntryAlert}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {ContentConst.USER_CATALOG.REMOVE_CATALOG_ITEM.ALERT.HEADLINE}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description' color='secondary'>
            {ContentConst.USER_CATALOG.REMOVE_CATALOG_ITEM.ALERT.CONTENT}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleRemoveEntryAlert}
            variant='outlined'
            color='secondary'
            autoFocus
          >
            {ButtonsConst.CANCEL}
          </Button>
          <Button
            onClick={() => props.handleRemoveCatalogItem()}
            variant='contained'
          >
            {ButtonsConst.CONTINUE}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
