import React from 'react';
import { Link } from 'react-router-dom';
import { Link as ExternalLink } from '@material-ui/core';

// Wrap something in a link with react router "to" or external links with an "href" by setting internal as false
export const WrapWithLink = ({
  link,
  internal = true,
  color,
  className = null,
  target = '_blank',
  children,
}: any) =>
  link ? (
    internal === true ? (
      <Link className={className} to={link} color={color}>
        {children}
      </Link>
    ) : internal === false ? (
      <ExternalLink
        className={className}
        href={link}
        target={target}
        color={color}
      >
        {children}
      </ExternalLink>
    ) : (
      children
    )
  ) : (
    children
  );
