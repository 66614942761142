import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default (props: any) => {
  return (
    <SvgIcon {...props} viewBox='0 0 150 150'>
      <path
        className='color--primary'
        d='M84.2,57v4.6h-1.4v0.7h-2.5v-0.7h-1.4V57H84.2z M68.2,57v4.6h-1.4v0.7h-2.5v-0.7h-1.4V57H68.2z
        M81.5,57.7c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4C82.9,58.3,82.3,57.7,81.5,57.7z M65.5,57.7
       c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4s1.4-0.6,1.4-1.4C67,58.3,66.3,57.7,65.5,57.7z M146.3,66.2h-28l28,1V66.2z M148,68.4
       c0,0.2,0,0.4,0,0.6h-46.5v-0.7l-44-2.2v-0.7h2.8h87.1C147.7,65.7,148,67.1,148,68.4L148,68.4z M99,73.6c0-0.4-0.3-0.7-0.7-0.7H76
       c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h22.3C98.7,74.3,99,74,99,73.6L99,73.6z M58.6,75.3H47.9c-0.2,0.2-0.4,0.4-0.6,0.6V77
       c0,2.2,1.4,4.6,4.4,4.6h1.7c3.2,0,5.1-2.8,5.1-3.6V75.3z M32.1,73.1c-0.8-2.6-2-3.6-6.7-2.9C21,70.8,7.6,73.1,7.6,73.1H32.1z
        M100.6,69.2v2.6v1.6c0,1.1-1,1.9-2,1.9H59.4V78c0,1.3-2.4,4.5-6,4.5h-1.7c-3.7,0-5.3-2.8-5.3-5.4v-0.4c-3.2,2.7-9.7,7.5-9.7,7.5
       s-4.5-4.4-7.7-2.8C25.8,83,9.4,92.7,9.4,92.7s-0.9,0.8-1.8,0C7.1,92.3,5,89.7,4,86.8c-1.5-4.4-2.5-9-3-13.5c3.8-1,22.3-3.9,26-4.2
       c1.7-0.2,3.2-0.3,4.2,0.3c1.5,0.8,2.3,2.7,2.8,4c4.9-2.8,9.3-5.2,13.3-6.5c0.6-0.2,1.3-0.4,1.9-0.5L100.6,69.2L100.6,69.2z'
      />
      <path
        className='color--secondary'
        d='M51.9,76.2c-1.6,2.8,1,4.8,1,4.8s-3.7-0.1-2.8-4.8l0,0H51.9z M148,70
        c-0.1,1.1-0.4,1.9-0.6,1.9l0,0h-45.8V70H148z M83,63.2c1,0,1.9,0.6,2.3,1.6l0,0H60.5c0.4-0.9,1.2-1.6,2.3-1.6l0,0H83z M146,62.5v2.2
        h-5.6v-0.5c1.7-0.4,4-1.3,4.5-1.7H146z M57.2,57.3l5.3,0.7v2.8l-5.3,1V57.3z M89.9,57v4.5l-5.3-0.7V58L89.9,57z M78.5,58.3v2.5h-9.9
        v-2.5H78.5z M81.7,58.2c0.4,0.1,0.8,0.5,0.8,0.9c0,0.5-0.3,0.8-0.8,0.9l0,0V58.2z M81.3,58.2V60c-0.4-0.1-0.8-0.5-0.8-0.9
        C80.5,58.7,80.9,58.3,81.3,58.2L81.3,58.2z M65.8,58.2c0.4,0.1,0.8,0.5,0.8,0.9c0,0.5-0.3,0.8-0.8,0.9l0,0V58.2z M65.3,58.2V60
        c-0.4-0.1-0.8-0.5-0.8-0.9C64.6,58.7,64.9,58.3,65.3,58.2L65.3,58.2z'
      />
    </SvgIcon>
  );
};
