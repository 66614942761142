import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Zoom,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { TransitionProps } from '@material-ui/core/transitions';
import { useStores } from '../../../models';

interface Props {
  children: React.ReactElement | undefined;
  handleOpenModal: () => any;
  modalOpen: boolean | false;
}

const useStyles = makeStyles((theme: Theme) => ({
  centerContent: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
  },
  appbarNotScrolled: {
    backgroundColor: 'transparent',
  },
  dialogAppBar: {
    position: 'relative',
  },
  dialogToolbar: {
    maxWidth: 'unset',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  toolbar: {
    '& > *:not(div)': {
      margin: theme.spacing(1),
    },
  },
  fab: {
    position: 'fixed',
    top: theme.spacing(10),
    right: theme.spacing(2),
    zIndex: 10,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

export default (props: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const {
    manufacturersStore,
    manufacturerModelsStore,
    subtypesStore,
    firearmCalibersStore,
  } = useStores();

  const handleOpen = () => {
    props.handleOpenModal();
  };

  const toggleHover = () => {
    setHovered(!hovered);
  };

  return (
    <>
      <Dialog
        fullScreen
        disableEscapeKeyDown
        open={props.modalOpen}
        onClose={handleOpen}
        TransitionComponent={Transition}
      >
        <Toolbar />

        <AppBar elevation={0} color='transparent' position='fixed'>
          <Toolbar className={classes.dialogToolbar}>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            >
              <IconButton
                edge='start'
                color='inherit'
                onClick={handleOpen}
                aria-label='close'
                sx={{ alignSelf: 'flex-end' }}
              >
                <Close />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>

        {React.Children.map(props.children, (child) =>
          React.cloneElement(child, {
            handleOpen: handleOpen,
            modalOpenState: props.modalOpen,
          })
        )}
      </Dialog>
    </>
  );
};
