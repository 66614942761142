import React, { FormEvent, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  Link,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import GeneralConst from '../../../constants/GeneralConst';
import ButtonsConst from '../../../constants/ButtonsConst';
import { useStores } from '../../../models';
import { useHistory } from 'react-router-dom';
import ErrorsConst from '../../../constants/ErrorsConst';

export default () => {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [verifyCode, setVerifyCode] = useState(null);
  const { authStore } = useStores();
  const [errors, setErrors] = useState<string>();
  const history = useHistory();

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    const results = await authStore.forgotPasswordConfirm(
      username,
      password,
      verifyCode
    );

    // console.log('***', 'results.kind', results.kind);

    switch (results.kind) {
      case 'bad-data':
        const errorText = results.errors.errors.session[0];

        setErrors(
          errorText.match('ExpiredCodeException') ? 'expired' : 'bad-data'
        );
        break;
      case 'ok':
        authStore.setConfirmation(false);
        break;
      default:
        setErrors(ErrorsConst.INVALID_LOGIN);
        break;
    }
  };

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant='h3'>Reset Your Password</Typography>
          <Typography variant='subtitle1' gutterBottom>
            Enter your email address, new password, and verification code below.
          </Typography>

          <FormControl required fullWidth margin='normal'>
            <TextField
              name='email'
              fullWidth
              type='email'
              required={true}
              onChange={(e) => setUsername(e.target.value)}
              label={GeneralConst.GENERAL.EMAIL}
            />
          </FormControl>

          <FormControl required fullWidth margin='normal'>
            <TextField
              name='password'
              fullWidth
              type='password'
              required={true}
              onChange={(e) => setPassword(e.target.value)}
              label={GeneralConst.GENERAL.NEW_PASSWORD}
            />
          </FormControl>

          <FormControl required fullWidth margin='normal'>
            <TextField
              name='verifyCode'
              fullWidth
              required={true}
              onChange={(e) => setVerifyCode(e.target.value)}
              label={GeneralConst.GENERAL.VERIFY_CODE}
            />
          </FormControl>
        </Grid>

        <Grid
          container
          item
          sm={12}
          spacing={2}
          alignItems='stretch'
          justifyContent='center'
        >
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Button
                color='primary'
                disabled={!verifyCode}
                size='large'
                type='submit'
                variant='outlined'
                onClick={(event) => handleSubmit(event)}
              >
                {ButtonsConst.SAVE}
              </Button>
            </FormControl>
          </Grid>

          <Grid
            container
            item
            xs={12}
            sm={6}
            spacing={1}
            alignItems='center'
            justifyContent='flex-start'
          >
            <Grid item sx={{ top: '1px', position: 'relative' }}>
              <Typography variant='caption'>or</Typography>
            </Grid>

            <Grid item>
              <Typography variant='caption'>
                <Link
                  color='secondary'
                  component='button'
                  variant='caption'
                  onClick={() => authStore.setConfirmation(false)}
                >
                  {ButtonsConst.CANCEL_RESET_PASSWORD}
                </Link>
              </Typography>
            </Grid>
          </Grid>

          {errors && errors !== 'expired' && (
            <>
              <Divider className='spacer' />
              <Typography color='error' variant='caption'>
                {errors}
              </Typography>
            </>
          )}

          {errors && errors === 'expired' && (
            <>
              <Divider className='spacer' />
              <Typography color='error' variant='caption'>
                Your verification code has expired. Please cancel and go back to
                the login, and try again.
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
