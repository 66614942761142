import { Button, MenuItem } from '@material-ui/core';
import React, { useContext } from 'react';
import ButtonsConst from '../../../constants/ButtonsConst';
import { isEmpty } from 'ramda';
import { AuthContext } from '../../../providers/Auth';
import { useStores } from '../../../models';
import { useHistory } from 'react-router-dom';

interface Props {
  handleOpenLogin?: () => any | undefined;
  handleOpenRegister?: () => any | undefined;
}

export const RegisterButton = (props: Props) => {
  return (
    <Button
      color='primary'
      disableElevation
      onClick={props.handleOpenRegister}
      variant='outlined'
    >
      {ButtonsConst.REGISTER}
    </Button>
  );
};

export const RegisterMenuItem = (props: Props) => {
  return (
    <MenuItem data-modal='register' onClick={props.handleOpenRegister}>
      {ButtonsConst.REGISTER}
    </MenuItem>
  );
};

export const LoginButton = (props: Props) => {
  const authContext = useContext(AuthContext);
  const { authStore } = useStores();
  const history = useHistory();

  const handleLogout = () => {
    authContext.logout(authStore);
    history.push('/');
  };

  return (
    <Button
      color={isEmpty(authContext.user) ? 'primary' : 'secondary'}
      disableElevation
      onClick={isEmpty(authContext.user) ? props.handleOpenLogin : handleLogout}
      variant={isEmpty(authContext.user) ? 'contained' : 'outlined'}
    >
      {isEmpty(authContext.user) ? ButtonsConst.LOGIN : ButtonsConst.LOGOUT}
    </Button>
  );
};

export const LoginMenuItem = (props: Props) => {
  const authContext = useContext(AuthContext);
  const { authStore } = useStores();
  const history = useHistory();

  const handleLogout = () => {
    authContext.logout(authStore);
    history.push('/');
  };

  return (
    <MenuItem
      data-modal='login'
      onClick={isEmpty(authContext.user) ? props.handleOpenLogin : handleLogout}
    >
      {isEmpty(authContext.user) ? ButtonsConst.LOGIN : ButtonsConst.LOGOUT}
    </MenuItem>
  );
};
