import React from 'react';
import Link from '@material-ui/core/Link';

/*
 * Contents:
 *
 * buildFormData
 * countMatchedFirearmTypes
 * createExtraAPIData
 * createExtraCSSData
 * createLinkData
 * formatMultiLineText
 * formatStringLink
 * formatStringSpan
 * formatStringStrong
 * ifEmpty
 * maskSensitiveData
 * replaceStringItems
 * restrictAlpha
 * scrollToElement
 */

export const buildFormData = (formData, data, parentKey) => {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
};

export const countMatchedFirearmTypes = (arr, val) => {
  return arr.reduce(function (n, item) {
    return n + (item.firearm_type.name === val);
  }, 0);
};

export const createExtraAPIData = (
  propName,
  propType,
  propDefaultValue,
  propDescription
) => ({ propName, propType, propDefaultValue, propDescription });

export const createExtraCSSData = (cssRuleName, cssClass, cssDescription) => ({
  cssRuleName,
  cssClass,
  cssDescription,
});

export const createLinkData = (linkText, linkValue) => ({
  linkText,
  linkValue,
});

export const ifEmpty = (val) => val || `--`;

export const matchItem = (arr, val) => {
  return arr.some((item) => val === item);
};

export const formatMultiLineText = (string) =>
  string.split('\n').map((str, index) => (
    <span key={index}>
      {str}
      <br />
    </span>
  ));

export const formatStringLink = (string, value, component) => {
  if (!value) return string;

  return (
    <>
      {string.split(value).reduce((prev, current, i) => {
        if (!i) {
          return [current];
        }

        return prev.concat(
          <Link href='#' onClick={component} key={value + current}>
            {value}
          </Link>,
          current
        );
      }, [])}
    </>
  );
};

export const formatStringSpan = (string, value) => {
  if (!value) return string;

  return (
    <>
      {string.split(value).reduce((prev, current, i) => {
        if (!i) {
          return [current];
        }

        return prev.concat(<span key={value + current}>{value}</span>, current);
      }, [])}
    </>
  );
};

export const formatStringStrong = (string, value) => {
  if (!value) return string;

  return (
    <>
      {string.split(value).reduce((prev, current, i) => {
        if (!i) {
          return [current];
        }

        return prev.concat(
          <strong key={value + current}>{value}</strong>,
          current
        );
      }, [])}
    </>
  );
};

export const maskSensitiveData = (string, mask = '*') =>
  Array(string.length + 1).join(mask);

export const replaceStringItems = (string, replaceWith) =>
  string.replace(/%(\d+)/g, (_, n) => replaceWith[n - 1]);

export const restrictAlpha = (e) => {
  const x = e.which || e.keycode;

  return x >= 48 && x <= 57 ? true : false;
};

export const scrollToElement = (event) => {
  const anchor = (event.target.ownerDocument || document).querySelector(
    event.currentTarget.dataset.anchor
  );

  if (anchor) {
    anchor.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};
