import React, { FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Container,
  FormControl,
  Grid,
  Hidden,
  List,
  ListItem,
  makeStyles,
  Slide,
  Snackbar,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { observer } from 'mobx-react-lite';
import { grey } from '@material-ui/core/colors';
import ButtonsConst from '../../../constants/ButtonsConst';
import GeneralConst from '../../../constants/GeneralConst';
import { useStores } from '../../../models';
import { formatStringLink } from '../../../helpers/Data';
import TagManager from 'react-gtm-module';

interface Props {
  image?: string | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  left: {
    backgroundPosition: '50%',
    backgroundSize: 'cover',
    color: grey[100],
    marginTop: theme.spacing(-9),
    padding: theme.spacing(9, 6, 6, 6),
    position: 'relative',
    zIndex: 0,
    '&::before': {
      background: grey[800],
      bottom: 0,
      content: '""',
      display: 'block',
      left: 0,
      opacity: '0.9',
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 0,
    },
    '& > *': {
      position: 'relative',
      zIndex: 1,
    },
    '& .MuiTypography-h4': {
      color: 'inherit',
    },
  },
  right: {
    marginTop: theme.spacing(-9),
    padding: theme.spacing(9, 6, 6, 6),
  },
}));

export default observer((props: Props) => {
  const classes = useStyles();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmationCode, setConfirmationCode] = useState(null);
  const [errors, setErrors] = useState(null);
  const { authStore } = useStores();
  const history = useHistory();

  const handleRegister = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    authStore.setEmail(email);

    const results = await authStore.signUp(email, password);

    // console.log('***', 'handleRegister | results.message', results);

    switch (results.kind) {
      case 'rejected':
        if (results.message === 'Duplicate resource found') {
          authStore.setConfirmation(true);
        } else {
          setErrors(results.message);
        }
        break;
      case 'bad-data':
        setErrors(results.errors.errors.user_register);
        break;
      case 'duplicate':
        setErrors('An account with this email address already exists');
        break;
      case 'ok':
        const tagManagerArgs = {
          dataLayer: {
            formName: 'mgc-Registration',
            event: 'formSubmit',
          },
        };

        TagManager.dataLayer(tagManagerArgs);

        authStore.setConfirmation(true);
        break;
    }
  };

  const handleConfirm = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    const results = await authStore.confirmSignUp(
      authStore.email,
      confirmationCode
    );

    // console.log('***', 'handleConfirm | results.kind', results.kind);

    switch (results.kind) {
      // case 'rejected':
      //   if (results.message === 'Duplicate resource found') {
      //     authStore.setConfirmation(true);
      //   } else {
      //     setErrors(results.message);
      //   }
      //   break;
      case 'bad-data':
        setErrors('The confirmation code you entered is incorrect.');
        break;
      case 'ok':
        const tagManagerArgs = {
          dataLayer: {
            formName: 'mgc-Validation',
            event: 'formSubmit',
          },
        };

        TagManager.dataLayer(tagManagerArgs);

        const location = { pathname: '/?login=true' };

        authStore.setConfirmation(false);

        history.push(location);
        history.go(0);
        break;
    }
  };

  const handleResendConfirm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // const results = await authStore.resendConfirmToken(authStore.email);
    await authStore.resendConfirmToken(authStore.email);

    // if (results.kind !== 'ok') {
    //   // TODO: SET ERRORS
    // }
  };

  const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrors(null);
  };

  const splitScreenImage = {
    backgroundImage: `url(${props.image})`,
  };

  return (
    <Container
      className='split-dialog-content'
      disableGutters={true}
      maxWidth={false}
    >
      <Grid container className={classes.root}>
        <Hidden smDown>
          <Grid
            item
            md={5}
            sm={6}
            className={`${classes.left} ${classes.root}`}
            style={splitScreenImage}
          >
            <Grid container alignItems='center' justifyContent='center'>
              <Grid item xs={12}>
                <Typography variant='h4' gutterBottom>
                  Find out why others are using MyGunCatalog to manage their
                  firearm collection.
                </Typography>

                <List dense disablePadding className='list--bulleted'>
                  <ListItem disableGutters>
                    Military-grade encryption to maintain anonymity and security
                  </ListItem>

                  <ListItem disableGutters>
                    Gun Collection Software That's Fun
                  </ListItem>

                  <ListItem disableGutters>
                    {GeneralConst.GENERAL.TAGLINE}
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>

        <Grid
          className={`${classes.root} ${classes.right}`}
          item
          xs={12}
          md={7}
          sm={6}
        >
          <Grid container item alignItems='center' justifyContent='center'>
            <Grid item xs={12} md={7}>
              <Slide
                direction='up'
                in={!authStore.confirmation}
                mountOnEnter
                unmountOnExit
              >
                <Container>
                  <Typography variant='h3'>Get started</Typography>

                  <Typography variant='subtitle1' gutterBottom>
                    Secure. Free. Private.
                  </Typography>

                  <form onSubmit={handleRegister}>
                    {errors && (
                      // errors.map((err: any, index: React.ReactText) => (
                      <Snackbar
                        open={errors ? true : false}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        autoHideDuration={6000}
                        onClose={handleCloseAlert}
                      >
                        <Alert
                          onClose={handleCloseAlert}
                          severity='error'
                          variant='filled'
                        >
                          {errors}
                        </Alert>
                      </Snackbar>
                      // ))}
                    )}

                    <Grid
                      container
                      alignItems='center'
                      justifyContent='flex-start'
                    >
                      <Grid item xs={12} sm={12}>
                        <FormControl required fullWidth margin='normal'>
                          <TextField
                            name='email'
                            fullWidth
                            type='email'
                            required={true}
                            onChange={(e) => setEmail(e.target.value)}
                            label={GeneralConst.GENERAL.EMAIL}
                          />
                        </FormControl>

                        <FormControl required fullWidth margin='normal'>
                          <TextField
                            name='password'
                            fullWidth
                            type='password'
                            required={true}
                            onChange={(e) => setPassword(e.target.value)}
                            label={GeneralConst.GENERAL.PASSWORD}
                          />
                        </FormControl>

                        <Typography variant='caption'>
                          Password requirements:
                          <List className='list--bulleted' disablePadding dense>
                            <ListItem disableGutters>
                              Minimum 8 characters in length
                            </ListItem>
                            <ListItem disableGutters>
                              At least 1 capital letter
                            </ListItem>
                            <ListItem disableGutters>
                              At least 1 lowercase letter
                            </ListItem>
                            <ListItem disableGutters>
                              At least 1 special character
                            </ListItem>
                          </List>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl margin='normal' fullWidth>
                          <Button
                            color='primary'
                            disabled={!email || !password}
                            size='large'
                            type='submit'
                            variant='outlined'
                          >
                            {ButtonsConst.REGISTER_NOW}
                          </Button>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </form>
                </Container>
              </Slide>

              <Slide
                direction='up'
                in={authStore.confirmation}
                mountOnEnter
                unmountOnExit
              >
                <Container>
                  <Typography variant='h3'>Confirm your account</Typography>

                  {errors && (
                    // errors.map((err: any, index: React.ReactText) => (
                    <Snackbar
                      open={errors ? true : false}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      autoHideDuration={6000}
                      onClose={handleCloseAlert}
                    >
                      <Alert
                        onClose={handleCloseAlert}
                        severity='error'
                        variant='filled'
                      >
                        {errors}
                      </Alert>
                    </Snackbar>
                    // ))}
                  )}

                  <Grid container justifyContent='center'>
                    <Grid item sm={12}>
                      <FormControl required fullWidth margin='normal'>
                        <TextField
                          name='confirmation_code'
                          fullWidth
                          type='password'
                          required={true}
                          onChange={(e) => setConfirmationCode(e.target.value)}
                          label={GeneralConst.GENERAL.CONFIRMATION_CODE}
                        />
                      </FormControl>

                      <FormControl margin='normal'>
                        <Typography>
                          {formatStringLink(
                            GeneralConst.GENERAL.RESEND_CONFIRMATION_CODE,
                            GeneralConst.GENERAL.RESEND,
                            handleResendConfirm
                          )}
                        </Typography>
                      </FormControl>

                      <FormControl margin='normal'>
                        <Button
                          color='primary'
                          disabled={!confirmationCode}
                          size='large'
                          type='submit'
                          variant='outlined'
                          onClick={(event) => handleConfirm(event)}
                        >
                          {ButtonsConst.CONFIRM}
                        </Button>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Container>
              </Slide>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
});
