import { IMAGES_URL } from '../config/env';

export const getPhoto = (photo, size = {}) => {
  if (!photo) return null;

  const meta_data = photo.meta_data ? photo.meta_data : {};

  let edits = {
    ...meta_data,
    resize: {
      height: size.height ? size.height : undefined,
      width: size.width ? size.width : undefined,
      fit: size.fit ? size.fit : 'cover',
    },
  };

  let photo_data = {
    bucket: 'myguncatalog-images',
    key: photo.s3_path,
    edits,
  };

  return IMAGES_URL + '/' + btoa(JSON.stringify(photo_data));
};
