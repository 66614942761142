import React, { useEffect, useRef } from 'react';
import Fab from '@material-ui/core/Fab';
import FormHelperText from '@material-ui/core/FormHelperText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import GeneralConst from '../../../constants/GeneralConst';
import { Button } from '@material-ui/core';
import ButtonsConst from '../../../constants/ButtonsConst';

interface Props {
  changeFile: (event: any) => any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: 'none',
    },
  })
);

export default (props: Props) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [imageWidth, setImageWidth] = React.useState<Number>(
    GeneralConst.RIDE_ALONG_IMAGE_SIZE_WIDTH
  );
  const [imageHeight, setImageHeight] = React.useState<Number>(
    GeneralConst.RIDE_ALONG_IMAGE_SIZE_HEIGHT
  );
  const [imageType, setImageType] = React.useState<String>();
  const [imageSize, setImageSize] = React.useState<Number>(
    GeneralConst.RIDE_ALONG_IMAGE_SIZE_MAX
  );
  const [imageErrorArray, setImageErrorArray] = React.useState<any[]>([]);
  const [hasImage, setHasImage] = React.useState<Boolean>(false);
  const inputId = 'contained-button-file';

  const handleUploadClick = (event: any) => {
    props.changeFile(event);
  };

  function handleClickEvent(event: any) {
    event.preventDefault();
    const target = document.querySelector(`#${inputId}`) as HTMLElement;

    if (event.keyCode === 32 || event.type === 'click') {
      target.click();
    }
  }

  const renderInitialState = () => {
    return (
      <>
        <input
          accept='image/*'
          id={inputId}
          multiple
          className={classes.input}
          type='file'
          onChange={(event: any) => {
            handleUploadClick(event);
          }}
        />
        <label htmlFor='contained-button-file'>
          <Button
            variant='contained'
            component='span'
            color='primary'
            className='disable-elevation button'
            onClick={(event: any) => {
              handleClickEvent(event);
            }}
          >
            {ButtonsConst.UPLOAD}
          </Button>
        </label>

        {imageErrorArray &&
          imageErrorArray.length > 0 &&
          imageErrorArray.map((errorMessage, index) => (
            <FormHelperText key={index} error>
              {errorMessage}
            </FormHelperText>
          ))}
      </>
    );
  };

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, []);

  if (loading) return <></>;

  return <>{renderInitialState()}</>;
};
