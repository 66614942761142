import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default (props: any) => {
  return (
    <SvgIcon {...props} viewBox='0 0 120.23 122.88'>
      <circle cx='93.9' cy='28.7' r='5.6' />
      <path d='M98.2,12c3.3,0,6-2.7,6-6c0-3.3-2.7-6-6-6s-6,2.7-6,6C92.2,9.3,94.9,12,98.2,12z' />
      <path
        d='M120,56.9c-8.5,5.2-15,2.9-20.2-4.8c-20.3,6.5-35.4-7.7-28.7-29.5c-7.5-2.8-10-10.9-7-20.1C31,0.8,1.7,26.6,0.1,59.7
		c-1.7,33.2,23.9,61.4,57,63.1c33.2,1.7,61.4-23.9,63.1-57C120.3,62.8,120.2,59.8,120,56.9z M21,56.8c0-5,4-9,9-9c5,0,9,4,9,9
		c0,5-4,9-9,9C25.1,65.8,21,61.7,21,56.8z M35.9,93.2c-3.5,0-6.3-2.8-6.3-6.3c0-3.5,2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3
		C42.2,90.4,39.4,93.2,35.9,93.2z M49.5,42.1c-2.7,0-5-2.2-5-5s2.2-5,5-5c2.7,0,5,2.2,5,5C54.4,39.9,52.2,42.1,49.5,42.1z
		 M58.3,69.6c-2.9,0-5.2-2.3-5.2-5.2c0-2.9,2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2S61.2,69.6,58.3,69.6z M76.4,99.4
		c-4.6,0-8.3-3.7-8.3-8.3c0-4.6,3.7-8.3,8.3-8.3c4.6,0,8.3,3.7,8.3,8.3C84.7,95.7,81,99.4,76.4,99.4z'
      />
    </SvgIcon>
  );
};
