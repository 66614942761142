import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';
import { SubtypeStoreModel } from './Subtype';

/**
 * Store for ManufacturerModel
 */
export const ManufacturerModelStoreModel = types.model(
  'ManufacturerModelStore',
  {
    id: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
    firearm_subtype: types.maybe(SubtypeStoreModel),
  }
);

/**
 * Store for ManufacturerModels
 */
export const ManufacturerModelsStoreModel = types
  .model('ManufacturerModelsStore', {
    manufacturerModels: types.array(ManufacturerModelStoreModel),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setManufacturerModels(manufacturerModels) {
      self.manufacturerModels = manufacturerModels;
    },
  }))
  .actions((self) => ({
    async getManufacturerModels(
      manufacturerId: string,
      firearmTypeId: string,
      search: String
    ) {
      const manufacturerModelResponse = await self.environment.api.manufacturerModels(
        manufacturerId,
        firearmTypeId,
        search
      );

      if (
        manufacturerModelResponse &&
        'manufacturer_models' in manufacturerModelResponse
      ) {
        self.setManufacturerModels(
          manufacturerModelResponse.manufacturer_models
        );

        return manufacturerModelResponse.manufacturer_models;
      } else {
        self.setManufacturerModels([]);

        return [];
      }
    },
    async getManufacturerModelsByManufacturerAndFirearmType(
      manufacturerId: String,
      firearmTypeId: String,
      modelName: String
    ) {
      const manufacturerModelResponse = await self.environment.api.getManufacturerModelsByManufacturerAndFirearmType(
        manufacturerId,
        firearmTypeId,
        modelName
      );
      if (
        manufacturerModelResponse &&
        'manufacturer_models' in manufacturerModelResponse
      ) {
        self.setManufacturerModels(
          manufacturerModelResponse.manufacturer_models
        );

        return manufacturerModelResponse.manufacturer_models;
      } else {
        self.setManufacturerModels([]);

        return [];
      }
    },
  }));

type ManufacturerModelStoreType = Instance<typeof ManufacturerModelStoreModel>;
export interface ManufacturerModelStore extends ManufacturerModelStoreType {}

type ManufacturerModelStoreSnapshotType = SnapshotOut<
  typeof ManufacturerModelStoreModel
>;
export interface ManufacturerModelStoreSnapshot
  extends ManufacturerModelStoreSnapshotType {}
