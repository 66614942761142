import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default (props: any) => {
  return (
    <SvgIcon {...props} viewBox='0 0 150 150'>
      <path
        className='color--primary'
        d='M60.3,59v11H49.9c-0.3,0-0.6,0-0.9,0v2.6c0,0.9,1,1.9,1.5,1.9h5.1c1.8,0,3.6-1.8,3.6-3.1
        V71H61V59.7h24v13H61v-0.8H60c-0.3,1.8-2.4,3.5-4.5,3.5h-5.1c-1.1,0-2.4-1.5-2.4-2.8v-2.5c-4.3,0.7-8.7,4.3-8.7,11.8h-2.1L31.6,77
        L9.1,91C6.7,89,5,85.4,5,83V68.8l29.9-4.6c0,0,3.5,2.1,5,2.9c2.9-2.5,12.9-8,15.5-8H60.3z M31,65.7L6.3,70v4.4L31,65.7z M147.2,60
        c0.7,0,1.8,0.6,1.8,2.5c0,1.8-1.1,2.5-1.8,2.5H121v6H95v-6h-9v-5H147.2z M97.1,65.7H96v3.2l1.1,1.2V65.7z M99.2,65.7h-1.1v3.2
        l1.1,1.2V65.7z M101.3,65.7h-1.1v3.2l1.1,1.2V65.7z M103.3,65.7h-1.1v3.2l1.1,1.2V65.7z M105.4,65.7h-1.1v3.2l1.1,1.2V65.7z
         M107.5,65.7h-1.1v3.2l1.1,1.2V65.7z M109.6,65.7h-1.1v3.2l1.1,1.2V65.7z M111.7,65.7h-1.1v3.2l1.1,1.2V65.7z M113.8,65.7h-1.1v3.2
        l1.1,1.2V65.7z M115.9,65.7h-1.1v3.2l1.1,1.2V65.7z M117.9,65.7h-1.1v3.2l1.1,1.2V65.7z M120,65.7h-1.1v3.2L120,70V65.7z M59.4,60.4
        h-3v4l3,4V60.4z M80.3,61.4h-9.8c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h9.8c1,0,1.8-0.8,1.8-1.8S81.2,61.4,80.3,61.4z M147,61H87.1
        v1.5h61C148.1,61.4,147.4,61,147,61z'
      />
      <path
        className='color--secondary'
        d='M4.1,69.6V84H3.8c-1,0-1.8-0.8-1.8-1.8l0,0V71.4c0-1,0.8-1.8,1.8-1.8l0,0H4.1z M53.2,70.7v1.4h0
        c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.2,0.1,0.6,0.3,0.9c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2
        c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.2,0.4,0.3,0.4,0.3s-0.2-0.1-0.5-0.2c-0.1-0.1-0.3-0.1-0.5-0.2c-0.1,0-0.2-0.1-0.3-0.2
        c-0.1-0.1-0.2-0.1-0.2-0.2c-0.3-0.3-0.6-0.6-0.7-0.9c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1,0-0.1h0v-1.4H53.2z
         M121.7,66c0.5,0,1.3,0.5,1.3,1.8c0,1.3-0.8,1.8-1.3,1.8l0,0h-0.8V66H121.7z M94.1,66.1v3.5h-7.7v-3.5H94.1z'
      />
    </SvgIcon>
  );
};
