import React, { useEffect } from 'react';
import {
  Box,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  makeStyles,
  styled,
  Theme,
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ContentConst from '../../../constants/ContentConst';
import { toJS } from 'mobx';

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    scrollMarginTop: '64px',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

export default () => {
  const classes = useStyles();

  useEffect(() => {
    const faqData = ContentConst.FAQ.ACCORDION.CONTENT;

    // ContentConst.FAQ.ACCORDION.CONTENT,
    faqData.forEach((data) => {
      console.log('***', 'CONTENT TYPES', typeof data.DATA);
    });
  }, []);

  return (
    <Box sx={{ padding: '24px 0' }}>
      {Object.values(ContentConst.FAQ.ACCORDION).map(
        (accordionSection, accordionSectionIndex) => (
          <Box
            className={classes.section}
            id='section1'
            key={accordionSectionIndex}
          >
            <Container maxWidth='lg'>
              <Grid
                container
                justifyContent='center'
                alignItems='center'
                alignContent='center'
              >
                <Grid item xs={12} md={6}>
                  {typeof accordionSection === 'string' && (
                    <Typography variant='h2'>{accordionSection}</Typography>
                  )}

                  {typeof accordionSection === 'object' &&
                    Object.values(accordionSection).map((d, di) => (
                      <Accordion key={di}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`${di}-content`}
                          id={`${di}-header`}
                        >
                          <Typography>{d.HEADING}</Typography>
                        </AccordionSummary>

                        {typeof d.DATA === 'string' && (
                          <AccordionDetails>
                            <Typography>{d.DATA}</Typography>
                          </AccordionDetails>
                        )}

                        {typeof d.DATA === 'object' &&
                          Object.values(d.DATA).map((dC, dCI) => (
                            <AccordionDetails key={dCI}>
                              {typeof dC === 'string' && (
                                <Typography>{dC}</Typography>
                              )}

                              {typeof dC === 'object' && (
                                <>
                                  <List dense className='list--bulleted'>
                                    {Object.values(dC).map((dL, dLI) => (
                                      <ListItem disableGutters key={dLI}>
                                        {dL}
                                      </ListItem>
                                    ))}
                                  </List>
                                </>
                              )}
                            </AccordionDetails>
                          ))}
                      </Accordion>
                    ))}
                </Grid>
              </Grid>
            </Container>
          </Box>
        )
      )}
    </Box>
  );
};
