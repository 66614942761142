import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { Container, Grid, Typography } from '@material-ui/core';
import {
  Cookies,
  Disclaimer,
  PrivacyPolicy,
  SecondaryHeader,
  Terms,
} from '../../organisms';
import GeneralConst from '../../../constants/GeneralConst';

interface ParamTypes {
  id: string;
}

const policyTabs = [
  { label: 'Privacy Policy', value: 'privacy' },
  { label: 'Cookie Policy', value: 'cookies' },
  { label: 'Terms of Use', value: 'terms' },
  { label: 'Disclaimer', value: 'disclaimer' },
];

export default () => {
  const [value, setValue] = useState('privacy');
  const { pathname, hash } = useLocation();
  const { id } = useParams<ParamTypes>();
  const history = useHistory();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (id) setValue(id);
  }, [id]); // do this on route change

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    const path = pathname.split('/');

    setValue(newValue);
    history.push(`/${path[1]}/${newValue}`);
  };

  return (
    <>
      <TabContext value={value}>
        <SecondaryHeader>
          <>
            <Grid item xs={12}>
              <Typography variant='h2'>
                {GeneralConst.GENERAL.POLICIES}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ width: '100%' }}>
                <Grid
                  alignItems='flex-start'
                  container
                  direction='row'
                  justifyContent='flex-start'
                >
                  <Grid item xs={12}>
                    <TabList
                      onChange={handleChange}
                      aria-label='Policies Tab List'
                    >
                      {policyTabs.map((tab, index) => {
                        return (
                          <Tab
                            key={index}
                            label={tab.label}
                            value={tab.value}
                          />
                        );
                      })}
                    </TabList>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </>
        </SecondaryHeader>

        <Container maxWidth='lg'>
          <Grid
            alignItems='flex-start'
            container
            direction='row'
            justifyContent='flex-start'
            spacing={4}
          >
            <TabPanel id='privacy' value='privacy'>
              <PrivacyPolicy />
            </TabPanel>

            <TabPanel id='cookies' value='cookies'>
              <Cookies />
            </TabPanel>

            <TabPanel id='terms' value='terms'>
              <Terms />
            </TabPanel>

            <TabPanel id='disclaimer' value='disclaimer'>
              <Disclaimer />
            </TabPanel>
          </Grid>
        </Container>
      </TabContext>
    </>
  );
};
