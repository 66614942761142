import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default (props: any) => {
  return (
    <SvgIcon {...props} viewBox='0 0 150 150'>
      <path
        className='color--primary'
        d='M75,85.4c0,0-0.6,11.7,3.4,17.9c-0.3,1.9-7,3.7-10.2,3.3c-3.3-0.5-4.6-5.2-4.9-9.2
        c-0.3-4,0-9,0-9L75,85.4z M65.5,88.6l-1.3,0.3c0,0-0.3,4.6,0,8.3c0.3,3.7,1.5,8,4.5,8.4c0.4,0.1,0.9,0.1,1.4,0.1
        C64.2,103.9,65.5,88.6,65.5,88.6z M77.4,66.6L77.4,66.6l3.6,0v11.6h-3.2c-0.6,0-0.8,0.5-0.8,1.2v5.1l-14.9,3.7H50.6
        c0,0-3.7,9.4-4.1,10.5c-0.3,0.7-0.6,1.4-1.1,1.9v-2.7l4-9.5l-4.8-2.8L44.4,86l0-0.1c0.1-0.2,0.1-0.3,0.1-0.3v-3.7L40,78.3V78h6.4
        l4-3.3l0.7-0.6v-5.5H40v-2H77.4z M63.2,79.7h-8.7c-1.5,0-2.8,1.2-2.8,2.7v1.4c0,1.5,1.2,2.7,2.8,2.7h8.7c1.5,0,2.8-1.2,2.8-2.7v-1.4
        C66,80.9,64.8,79.7,63.2,79.7z M60.9,74.3c-1.2,0-2.2,1-2.2,2.1c0,1.2,1,2.1,2.2,2.1c1.2,0,2.2-1,2.2-2.1
        C63.1,75.3,62.1,74.3,60.9,74.3z M39.2,67.3v9l-1.8,0v-9H39.2z M27.6,67.3v6.5L23,75.4H2.2c-0.6-2.3-1-4.1-1.1-4.5
        c-0.4-1.5,0.5-3.6,1.7-3.6H27.6z M143,70.7v3.5h-22.3v-3.5H143z M75.1,71.6H56.7c-0.5,0-0.9,0.4-0.9,0.9v0.1c0,0.5,0.4,0.9,0.9,0.9
        h18.4c0.5,0,0.9-0.4,0.9-0.9v-0.1C76,72,75.6,71.6,75.1,71.6z M18.3,68.2h-2.2v4.2h2.2V68.2z M21.1,68.2H19v4.2h2.2V68.2z M24,68.2
        h-2.2v4.2H24V68.2z M26.8,68.2h-2.2v4.2h2.2V68.2z M75.1,68.8H56.7c-0.5,0-0.9,0.4-0.9,0.9v0.1c0,0.5,0.4,0.9,0.9,0.9h18.4
        c0.5,0,0.9-0.4,0.9-0.9v-0.1C76,69.1,75.6,68.8,75.1,68.8z M116.5,60.9c0.3,0,0.6,0.3,0.6,0.5v5.4h-7.2c1.3-1.3,5.2-5.1,5.6-5.4
        C115.9,61.1,116.1,60.9,116.5,60.9z M115.8,62c-0.5,0-1,0.4-1,0.9s0.4,0.9,1,0.9c0.5,0,1-0.4,1-0.9S116.3,62,115.8,62z M51.6,59.5
        c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.4,0.8-0.8,0.8c-0.4,0-0.8-0.3-0.8-0.8C50.8,59.8,51.2,59.5,51.6,59.5z M92,44.6v10.2h-4.2l-5.4-2.3
        V47l5.4-2.3H92z M50.1,47v5.5l-9.6,2.1h-7.7V47H50.1z M78.4,47v5.5h-4.9v-3.8h-2.7v3.8h-9.2v-3.8H59v3.8h-8.5V47H78.4z M41.7,47.7
        h-8.2v3.8l6.2-3.1h2V47.7z M87.8,45.8l-4.4,1.8h4.4V45.8z'
      />
      <path
        className='color--secondary'
        d='M45,85.1l4,2.4l-3.7,8.8l0,3.3l0,0l0,0l0,0h-3.3c-2.2,0-3.5-2.9-2.7-4.2
        C39.9,94.2,43.7,87.3,45,85.1z M37.6,67.1v9l-4.2,0L9.5,84.8v3.9H7c0,0-1.6-6.6-2.9-11.8L4,76.5h20.6l5.6-2v-7.4H37.6z M56.9,79.6
        c0,0-3.6,3.8,1.3,5.9c-2.4,0-5.1-2.4-3.3-5.9l0,0H56.9z M82.3,66.6l10.6,0.2v7.9c0,0.2,0.2,0.5,0.4,0.5l0.1,0h0
        c0.3,0,0.5-0.2,0.5-0.4l0-0.1v-7.9l0.8,0v7.8c0,0.2,0.2,0.5,0.4,0.5l0.1,0h0c0.2,0,0.5-0.2,0.5-0.4l0-0.1v-7.8l0.9,0v7.8
        c0,0.2,0.2,0.5,0.4,0.5l0.1,0h0c0.2,0,0.5-0.2,0.5-0.4l0-0.1v-7.8l0.9,0v7.8c0,0.2,0.2,0.5,0.4,0.5l0.1,0h0c0.2,0,0.5-0.2,0.5-0.4
        l0-0.1v-7.8l0.9,0v7.8c0,0.2,0.2,0.5,0.4,0.5l0.1,0h0c0.2,0,0.5-0.2,0.5-0.4l0-0.1v-7.7l0.8,0v7.7c0,0.2,0.2,0.5,0.4,0.5l0.1,0h0
        c0.2,0,0.5-0.2,0.5-0.4l0-0.1v-7.7l0.9,0v7.7c0,0.2,0.2,0.5,0.4,0.5l0.1,0h0c0.2,0,0.5-0.2,0.5-0.4l0-0.1v-7.7l0.9,0v7.7
        c0,0.2,0.2,0.5,0.4,0.5l0.1,0h0c0.2,0,0.5-0.2,0.5-0.4l0-0.1V67l0.9,0v7.7c0,0.2,0.2,0.5,0.4,0.5l0.1,0h0c0.3,0,0.5-0.2,0.5-0.4
        l0-0.1V67l0.9,0v7.6c0,0.2,0.2,0.5,0.4,0.5l0.1,0h0c0.3,0,0.5-0.2,0.5-0.4l0-0.1V67h0.9v7.6c0,0.2,0.2,0.5,0.4,0.5l0.1,0h0
        c0.2,0,0.5-0.2,0.5-0.4l0-0.1V67h0.8v7.6c0,0.2,0.2,0.5,0.4,0.5l0.1,0h0c0.2,0,0.5-0.2,0.5-0.4l0-0.1V67h0.9v7.6
        c0,0.2,0.2,0.5,0.4,0.5l0.1,0h0c0.2,0,0.5-0.2,0.5-0.4l0-0.1V67h0.9v7.6c0,0.2,0.2,0.5,0.4,0.5l0.1,0h0c0.2,0,0.5-0.2,0.5-0.4l0-0.1
        V67h0.6c0.5,0,1.3,0.5,1.4,1.1l0,0.1V76c0,0.6-0.7,1.2-1.4,1.2c-0.7,0-30.3,0.5-37,0.6l-0.2,0V66.6z M60.9,75.4
        c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1S60.3,75.4,60.9,75.4z M51.3,68.9v4.4l-4.1,3.5h-6v-7.8H51.3z
         M148,70.5V74h-4.2v-0.7h3.1v-0.4h-3.1v-0.5h3.1v-0.4h-3.1v-0.5h3.1v-0.4h-3.1v-0.7H148z M79,57.6v8H47l2-2.6v-3.5
        c0-0.9,1-1.9,2-1.9H79z M77.5,58.3H55.2c-0.5,0-0.9,0.4-0.9,0.9V64c0,0.5,0.4,0.9,0.9,0.9h22.3c0.5,0,0.9-0.4,0.9-0.9v-4.8
        C78.4,58.7,78,58.3,77.5,58.3z M51.6,58.6c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6
        C53.2,59.4,52.5,58.6,51.6,58.6z M62,47.9v8.7h-1.9v-8.7H62z M73.7,47.9v8.7h-1.9v-8.7H73.7z M82,45.8v5.5h-2.4v-5.5H82z M71.9,45.1
        v0.4h-3.8v-0.4H71.9z M57.7,45.1v0.4h-3.8v-0.4H57.7z M48.3,44.4v1.1h-3.1v-1.1H48.3z M71.3,43.3c0.4,0,0.7,0.3,0.7,0.7v0.8h-3.8V44
        c0-0.4,0.3-0.7,0.7-0.7H71.3z M57,43.3c0.4,0,0.7,0.3,0.7,0.7v0.8h-3.8V44c0-0.4,0.3-0.7,0.7-0.7H57z M48.4,42.6
        c0.2,0,0.4,0.2,0.4,0.4l0,0.1v0.5c0,0.2-0.2,0.4-0.4,0.4l-0.1,0H45c-0.2,0-0.4-0.2-0.4-0.4l0-0.1V43c0-0.2,0.2-0.4,0.4-0.4l0.1,0
        H48.4z'
      />
    </SvgIcon>
  );
};
