import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';
import { ManufacturerStoreModel } from './Manufacturer';
import { ManufacturerModelStoreModel } from './ManufacturerModel';
import { FirearmTypeStoreModel } from './FirearmType';
import { SubtypeStoreModel } from './Subtype';
import { PhotoStoreModel } from './Photo';
import moment from 'moment';

/**
 * Store for UserCatalogStoreModel
 */
export const UserCatalogStoreModel = types.model('UserCatalogStore', {
  id: types.optional(types.string, ''),
  manufacturer: types.maybe(ManufacturerStoreModel),
  manufacturer_model: types.maybe(ManufacturerModelStoreModel),
  firearm_type: types.maybe(FirearmTypeStoreModel),
  firearm_subtype: types.maybe(SubtypeStoreModel),
  photos: types.array(PhotoStoreModel),
  custom_build: types.optional(types.boolean, false),
});

/**
 * Store for UserCatalogItemsStoreModel
 */
export const UserCatalogItemsStoreModel = types
  .model('UserCatalogItemsStore', {
    userCatalogItems: types.optional(types.array(UserCatalogStoreModel), []),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setUserCatalogItems(userCatalogItems) {
      self.userCatalogItems = userCatalogItems;
    },
    appendUserCatalogItems(userCatalogItems) {
      if (userCatalogItems) {
        self.userCatalogItems.push(...userCatalogItems);
      }
    },
  }));

/**
 * Store for adding items to the user catalog
 */
export const CatalogStoreModel = types
  .model('CatalogStore', {
    manufacturer: types.optional(types.string, ''),
    manufacturerName: types.optional(types.string, ''),
    model: types.optional(types.string, ''),
    modelName: types.optional(types.string, ''),
    step: types.optional(types.number, 1),
    subtype: types.optional(types.string, ''),
    subtypeName: types.optional(types.string, ''),
    type: types.optional(types.string, ''),
    typeName: types.optional(types.string, ''),
    serialNumber: types.optional(types.string, ''),
    purchaseDate: types.optional(types.maybeNull(types.string), null),
    purchaseAmount: types.optional(types.maybeNull(types.number), null),
    finishType: types.optional(types.string, ''),
    caliber: types.optional(types.string, ''),
    caliberName: types.optional(types.string, ''),
    curios: types.optional(types.string, ''),
    condition: types.optional(types.string, ''),
    description: types.optional(types.string, ''),
    userCatalog: types.optional(types.string, ''),
    custom_build: types.optional(types.boolean, false),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setManufacturer(manufacturer: string) {
      self.manufacturer = manufacturer;
    },
    setManufacturerName(manufacturerName: string) {
      self.manufacturerName = manufacturerName;
    },
    setModel(model: string) {
      self.model = model;
    },
    setModelName(modelName: string) {
      self.modelName = modelName;
    },
    setStep(step: number) {
      self.step = step;
    },
    setSubtype(subtype: string) {
      self.subtype = subtype;
    },
    setSubtypeName(subtypeName: string) {
      self.subtypeName = subtypeName;
    },
    setType(type: string) {
      self.type = type;
    },
    setTypeName(typeName: string) {
      self.typeName = typeName;
    },
    setSerialNumber(serialNumber: string) {
      self.serialNumber = serialNumber;
    },
    setPurchaseDate(purchaseDate: string) {
      self.purchaseDate = purchaseDate
        ? moment(purchaseDate).format('MM/DD/YYYY')
        : null;
    },
    setPurchaseAmount(purchaseAmount: number) {
      self.purchaseAmount = purchaseAmount;
    },
    setFinishType(finishType: string) {
      self.finishType = finishType;
    },
    setCaliber(caliber: string) {
      self.caliber = caliber;
    },
    setCaliberName(caliberName: string) {
      self.caliberName = caliberName;
    },
    setCurios(curios: string) {
      self.curios = curios;
    },
    setCondition(condition: string) {
      self.condition = condition;
    },
    setDescription(description: string) {
      self.description = description;
    },
    setCustomBuild(custom_build: boolean) {
      self.custom_build = custom_build;
    },
    async resetCatalogEntry() {
      self.manufacturer = '';
      self.manufacturerName = '';
      self.model = '';
      self.modelName = '';
      self.step = 1;
      self.type = '';
      self.typeName = '';
      self.subtype = '';
      self.subtypeName = '';
      self.serialNumber = '';
      self.purchaseDate = null;
      self.purchaseAmount = null;
      self.finishType = '';
      self.caliber = '';
      self.caliberName = '';
      self.curios = '';
      self.condition = '';
      self.description = '';
      self.custom_build = false;
      return true;
    },
    setUserCatalog(userCatalog) {
      self.userCatalog = userCatalog;
    },
  }))
  .actions((self) => ({
    getManufacturer() {
      return self.manufacturer;
    },
    getModel() {
      return self.model;
    },
    getStep() {
      return self.step;
    },
    getSubtype() {
      return self.subtype;
    },
    getType() {
      return self.type;
    },
    getCustomBuild() {
      return self.custom_build;
    },
    getCatalog(files = []) {
      const catalog = {
        condition: self.condition,
        curios: self.curios,
        description: self.description,
        files,
        finish_type: self.finishType,
        firearm_caliber_id: self.caliber,
        firearm_subtype_id: self.subtype,
        firearm_type_id: self.type,
        manufacturer_id: self.manufacturer,
        manufacturer_model_id: self.model,
        purchase_amount: self.purchaseAmount,
        purchase_date: self.purchaseDate,
        serial_number: self.serialNumber,
        custom_build: self.custom_build,
      };

      return catalog;
    },
  }))
  .actions((self) => ({
    async addToCatalog(files = []) {
      if (files.length === 0) {
        files = null;
      }

      const catalog = self.getCatalog(files);

      return await self.environment.api.addToCatalog(catalog);
    },
    async updateCatalogItem(catalogId: String, files = []) {
      if (files.length === 0) {
        files = null;
      }
      const catalog = self.getCatalog(files);
      return await self.environment.api.updateCatalog(catalogId, catalog);
    },
    async deleteCatalogItem(catalogId: String) {
      return await self.environment.api.deleteCatalogItem(catalogId);
    },
    async catalog(id: String) {
      return await self.environment.api.catalog(id);
    },
    async catalogTypes() {
      return await self.environment.api.catalogTypes();
    },
    async allCatalogs(page: Number = 1, per_page: Number = 30) {
      const results = await self.environment.api.allCatalogs(page, per_page);

      self.setUserCatalog(JSON.stringify(results.catalog_items));

      return results;
    },
  }));

type CatalogStoreType = Instance<typeof CatalogStoreModel>;
export interface CatalogStore extends CatalogStoreType {}

type CatalogStoreSnapshotType = SnapshotOut<typeof CatalogStoreModel>;
export interface CatalogStoreSnapshot extends CatalogStoreSnapshotType {}
