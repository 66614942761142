import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  Catalog,
  Dashboard,
  FrequentlyAskedQuestions,
  Main,
  NotFound,
  Policies,
  Profile,
  VerifyAccount,
} from './components/pages';
import { useAuth } from './providers/Auth';
import { isEmpty } from 'ramda';
import { Toolbar } from '@material-ui/core';
import { Default, Landing, Secondary } from './components/templates';

export default () => {
  const AuthContext = useAuth();

  return (
    <>
      <Toolbar id='back-to-top-anchor' />

      <Switch>
        {isEmpty(AuthContext.user) && (
          <Landing exact path='/' component={withAuth(Main, false)} />
        )}
        {!isEmpty(AuthContext.user) && (
          <Default exact path='/' component={withAuth(Dashboard)} />
        )}
        <Default
          exact
          path='/signup/confirm'
          component={withAuth(Dashboard, false)}
        />
        <Default exact path='/catalog/:id' component={withAuth(Catalog)} />
        <Secondary exact path='/policies/:id?' component={Policies} />
        <Secondary exact path='/profile' component={withAuth(Profile)} />
        <Default exact path='/faq' component={FrequentlyAskedQuestions} />
        {/* <Default
          exact
          path='/verify'
          component={withAuth(VerifyAccount, false)}
        /> */}
        <Default path='*' component={NotFound} />
      </Switch>
    </>
  );
};

const withAuth =
  (Component, requireAuth = true) =>
  (props) => {
    const authContext = useAuth();

    if (authContext.user === undefined) return null;

    if (requireAuth && isEmpty(authContext.user)) {
      return <Redirect to='/' />;
    } else if (!requireAuth && !isEmpty(authContext.user)) {
      return <Redirect to='/' />;
    }

    return <Component {...props} />;
  };
