import { Box, Container, Grid } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';

interface Props {
  children: React.ReactElement;
}

export default (props: Props) => {
  const { children } = props;

  return (
    <Container disableGutters={true} maxWidth={false}>
      <Box
        sx={{
          backgroundColor: 'secondary.contrastText',
          borderBottomColor: grey[300],
          borderBottomStyle: 'solid',
          borderBottomWidth: 1,
          marginTop: -11,
          marginBottom: 10,
          pt: 10,
        }}
      >
        <Container maxWidth='lg'>
          <Grid
            alignItems='center'
            container
            direction='row'
            item
            justifyContent='center'
            spacing={4}
          >
            {children}
          </Grid>
        </Container>
      </Box>
    </Container>
  );
};
