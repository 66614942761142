import { ApiResponse } from "apisauce";

export type GeneralApiProblem =
  /**
   * Times up.
   */
  | { kind: "timeout"; temporary: true }
  /**
   * Cannot connect to the server for some reason.
   */
  | { kind: "cannot-connect"; temporary: true }
  /**
   * The server experienced a problem. Any 5xx error.
   */
  | { kind: "server" }
  /**
   * We're not allowed because we haven't identified ourself. This is 401.
   */
  | { kind: "unauthorized"; message: string }
  /**
   * We don't have access to perform that request. This is 403.
   */
  | { kind: "forbidden"; message: string }
  /**
   * Unable to find that resource.  This is a 404.
   */
  | { kind: "not-found"; message: string }
  /**
   * Duplicate resource. This is 409.
   */
  | { kind: "duplicate"; message: string }
  /**
   * All other 4xx series errors.
   */
  | { kind: "rejected"; message: string }
  /**
   * Something truly unexpected happened. Most likely can try again. This is a catch all.
   */
  | { kind: "unknown"; temporary: true }
  /**
   * The data we received is not in the expected format.
   */
  | { kind: "bad-data"; errors: any };

/**
 * Attempts to get a common cause of problems from an api response.
 *
 * @param response The api response.
 */
export function getGeneralApiProblem(
  response: ApiResponse<any>
): GeneralApiProblem | void | null {
  switch (response.problem) {
    case "CONNECTION_ERROR":
      return { kind: "cannot-connect", temporary: true };
    case "NETWORK_ERROR":
      return { kind: "cannot-connect", temporary: true };
    case "TIMEOUT_ERROR":
      return { kind: "timeout", temporary: true };
    case "SERVER_ERROR":
      return { kind: "server" };
    case "UNKNOWN_ERROR":
      return { kind: "unknown", temporary: true };
    case "CLIENT_ERROR":
      switch (response.status) {
        case 400:
          return { kind: "bad-data", errors: response.data };
        case 401:
          return { kind: "unauthorized", message: response.data.message };
        case 403:
          return { kind: "forbidden", message: response.data.message };
        case 404:
          return { kind: "not-found", message: response.data.message };
        case 409:
          return { kind: "duplicate", message: response.data.message };
        default:
          return { kind: "rejected", message: response.data.message };
      }
    case "CANCEL_ERROR":
      return null;
  }
}
