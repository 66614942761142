export default {
  GENERAL: {
    ACTIVE: 'Active',
    AVATAR: 'Your Avatar',
    CHANGE_PASSWORD: 'Need to update your password?',
    CONFIRM_PASSWORD: 'Confirm Password',
    CONFIRMATION_CODE: 'Confirmation Code',
    COPYRIGHT: `Copyright &copy; %1 %2. All Rights Reserved.`,
    CURRENT_PASSWORD: 'Current Password',
    CUSTOM_BUILD_DESCRIPTION:
      'Select this for firearms that are custom built like Polymer 80 or a custom built (pieced together) AR platform firearms.',
    EMAIL: 'Email',
    FORGOT_PASSWORD: 'or',
    NAME: 'Name',
    NEW_PASSWORD: 'New Password',
    PASSWORD: 'Password',
    POLICIES: 'Policies',
    PROFILE: 'Profile',
    RESEND_CONFIRMATION_CODE:
      "Didn't receive an email? Resend the confirmation email now.",
    RESEND: 'Resend',
    TAGLINE: 'More Shooting, Less Organizing',
    TAGLINE_DESCRIPTION:
      'Keeping tabs on your gun collection is cumbersome. Just take a photo, enter the basic info (Manufacturer, model, caliber, etc.), and MyGunCatalog takes care of the rest.',
    SITE_TITLE: 'My Gun Catalog',
    STANDARD_BUILD_DESCRIPTION:
      'This is the most common option. Select this for firearms that are not custom built / pieced together.',
    VERIFY_CODE: 'Verification Code',
  },
  LABELS: {
    AMOUNT: 'Purchase Amount',
    CALIBER: 'Caliber',
    CONDITION: 'Condition',
    CURIOS_RELIC: 'Curios & Relics',
    DESCRIPTION: 'Description',
    FINISH: 'Finish',
    SERIAL: 'Serial Number',
    SUBTYPE: 'Subtype',
    PURCHASE_DATE: 'Purchase Date',
  },
  ARIA_LABELS: {
    AMOUNT: 'Purchase Amount',
    CALIBER: 'Caliber',
    CONDITION: 'Condition',
    CURIOS_RELIC: 'Curios and Relics',
    DESCRIPTION: 'Description',
    FINISH: 'Finish',
    SERIAL: 'Serial Number',
    SUBTYPE: 'Subtype',
    PURCHASE_DATE: 'Purchase Date',
  },
  FIREARM_TERMS: {
    DISPLAY: 'Display Only',
    EXCELLENT: 'Excellent',
    FAIR: 'Fair',
    GOOD: 'Good',
    NEW: 'Factory New',
    POOR: 'Poor',
    YES: 'Yes',
    YES_NO_REPORT: 'Yes - No report',
    NO: 'No',
    BLUING: 'Gun Bluing (Black Oxide)',
    CERAKOTE: 'Cerakote',
    PARKERIZING: 'Parkerizing for Guns (Phosphating)',
    ANODIZING: 'Gun Anodizing',
    NICKEL_BORON: 'Nickel Boron',
    NITROCARBURIZING: 'Ferritic Nitrocarburizing',
    QPQ: 'Quench Polish Quench (QPQ)',
  },
};
