import { Instance, SnapshotOut, types } from "mobx-state-tree";
import { AuthStoreModel } from "./Auth";
import {
  CatalogStoreModel,
  UserCatalogStoreModel,
  UserCatalogItemsStoreModel,
} from "./Catalog";
import { EditCatalogStoreModel } from "./EditCatalog";
import { FirearmCalibersStoreModel } from "./FirearmCaliber";
import { FirearmTypesStoreModel } from "./FirearmType";
import { ManufacturerModelsStoreModel } from "./ManufacturerModel";
import { ManufacturersStoreModel } from "./Manufacturer";
import { ManufacturerTypesStoreModel } from "./ManufacturerType";
import { SIBStoreModel } from "./Sib";
import { SubtypesStoreModel } from "./Subtype";

/**
 * A RootStore model.
 */
export const Store = types.model("RootStore").props({
  authStore: types.optional(AuthStoreModel, {}),
  catalogStore: types.optional(CatalogStoreModel, {}),
  editCatalogStore: types.optional(EditCatalogStoreModel, {}),
  firearmCalibersStore: types.optional(FirearmCalibersStoreModel, {}),
  firearmTypesStoreModel: types.optional(FirearmTypesStoreModel, {}),
  manufacturerModelsStore: types.optional(ManufacturerModelsStoreModel, {}),
  manufacturersStore: types.optional(ManufacturersStoreModel, {}),
  manufacturerTypesStore: types.optional(ManufacturerTypesStoreModel, {}),
  subtypesStore: types.optional(SubtypesStoreModel, {}),
  userCatalogStore: types.optional(UserCatalogStoreModel, {}),
  userCatalogItemsStore: types.optional(UserCatalogItemsStoreModel, {}),
  sibStore: types.optional(SIBStoreModel, {}),
});

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof Store> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof Store> {}
