import React, { PropsWithChildren } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogProps } from '@material-ui/core';
import { DialogTitleWithCloseButton } from './DialogTitleWithCloseButton';

export type EpicDialogProps = DialogProps & {
  title?: string;
};

type DialogContainerProps = EpicDialogProps & {
  closeDialog: (data?: any) => void;
};

export function DialogContainer({
  children,
  title,
  closeDialog,
  ...props
}: PropsWithChildren<DialogContainerProps>) {
  return (
    <Dialog {...props}>
      <DialogTitleWithCloseButton onClose={closeDialog}>
        {title ? title : null}
      </DialogTitleWithCloseButton>
      {children}
    </Dialog>
  );
}
