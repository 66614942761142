import React from 'react';

export const WithAnchor = ({ anchor, children, component }) =>
  anchor ? (
    <span onClick={component} data-anchor={anchor}>
      {children}
    </span>
  ) : (
    children
  );
