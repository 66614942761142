import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default (props: any) => {
  return (
    <SvgIcon {...props} viewBox='0 0 150 150'>
      <path
        className='color--primary'
        d='M40.9,89.1c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5c0,1.4,1.1,2.5,2.5,2.5
        C39.8,91.6,40.9,90.5,40.9,89.1L40.9,89.1z M45.8,84l-0.2,0.7c-1.1,5.4-2,9.6-2.2,11.5c0,0.6-0.5,1.5-1.3,1.6
        c-3.2,0.4-9.2,0.1-10.1-0.1c0,0,0,0-0.1,0c0-0.2,0-0.4,0-0.6V93c0-2.9,5.9-11.2,9.3-16.6c0,0,0,0,0,0c0.2,0.1,0.4,0.2,0.6,0.3
        c1,0.5,2.7,1.3,3.6,2.5C46.4,80.5,46.1,82.4,45.8,84L45.8,84z M65,69.6h22.7V51.6H65V69.6z M82.4,74.9L82.4,74.9l-2.4,0
        c-2.7-1.9-5.6-2.5-6.9-2.5h-4.1c-2.1,0-6.1,2.3-6.1,5.2v4.9h-1.2c0,1.2,1.2,3.7,4,4.2c1.8,0.3,7.1,0.3,9.6-0.2
        c2.7-0.6,4.6-1.7,5.5-3.1c0.8-1.3,1.5-3.6,1.7-5.2C82.4,76.7,82.4,75,82.4,74.9L82.4,74.9z M63,65v3.3h1V56l-4.6,3.2
        C59.4,59.3,63,62.8,63,65L63,65z M92.5,52.6v9.2l-3.1,4.1V73c0,1.4-1.1,1.9-2.2,1.9h-3.9c0,0.2,0.1,1.8-0.1,3.4
        c-0.2,1.7-0.9,4.1-1.8,5.6c-1,1.7-3.1,2.9-6.1,3.6c-1.4,0.3-3.9,0.4-6.1,0.4c-1.8,0-3.4-0.1-4.2-0.2c-3.4-0.6-4.8-3.6-4.8-5.1
        c0-0.2,0-0.4,0-0.6c-0.1-1.6-0.9-3-2.2-3.8c-0.9-0.6-1.5-0.9-3.4-1c0.2-2.7,0.1-7.9-2.3-11.1c-2.3-3-4.5-2.9-7.5-2.9
        c-0.4,0-0.7,0-1.1,0v-1.3c1.7,0,3.8-0.3,6-0.9c0.1,0,0.3-0.1,0.5-0.3c0.1,0,0.2,0,0.3-0.1c2.3-0.6,5.6-1.7,7.7-3.7
        c2.1-2,2.8-4.3,2.9-6c0,0,0,0,0,0c0-1.2,2.1-1.8,2.1-1.8h25.8l3.5,3.6H92.5z M92.9,59.3h25.9v-5.2H92.9V59.3z'
      />
      <path
        className='color--secondary'
        d='M44.7,64.2c3,0,4.8-0.1,6.7,2.5c2.3,3,2.3,8.2,2.1,10.7c-2.5,1.2-6.6,7.7-6.6,10.5l0,0
        v10.9c0,2-1.5,2.9-3.3,2.9l0,0h-8.8c-1.1,0-2-1.5-2.5-3c1,0.1,3.3,0.3,5.6,0.3c1.6,0,3.2-0.1,4.4-0.2c1.4-0.2,2.1-1.6,2.1-2.5
        c0.1-1.8,1-6,2.1-11.4l0,0l0.2-0.7c0.4-1.7,0.7-3.9-0.4-5.5c-1-1.5-2.9-2.3-4-2.9c-0.2-0.1-0.4-0.2-0.6-0.3c1.1-1.7,1.8-3.1,1.8-3.7
        l0,0v-7.7C44,64.2,44.3,64.2,44.7,64.2z M38.6,87.3c0.9,0.1,1.5,0.8,1.5,1.7c0,0.9-0.7,1.6-1.5,1.7l0,0V87.3z M38.2,87.3v3.5
        c-0.9-0.1-1.5-0.8-1.5-1.7C36.6,88.2,37.3,87.5,38.2,87.3L38.2,87.3z M73.1,73.1v1c-1.5,1-2.7,3.1-2.7,5.7c0,1.6,0.9,3,2.7,3.7
        c0.8,0.3,0.5,1,0,1c-3.6,0-5.8-4-5.1-8.9l0,0h-2.6C65.4,73.6,69.2,73.1,73.1,73.1L73.1,73.1z M85.3,52.6l0,2H70v1.5h15.2l0,3H70
        l0,2.9h15.3l0,3H70l0,1.5h15.2v2H67.5V52.6H85.3z M118.8,59.8v1.6H92.9v-1.6H118.8z M58.3,50.8c0.2-0.2,0.9-0.2,1.7-0.1
        c-0.1,1.6-0.7,3.7-2.6,5.4c-1.6,1.5-3.9,2.4-5.7,3c0.7-0.9,1.4-2,1.4-3.2c0-1.7-3.4-2.1-3.4-2.1l0,0v-1h4c0.5,1.1,0.6,2.4,1.8,2.4
        c1.1,0,1.8-2,2.3-3.2C57.9,51.7,57.9,51.1,58.3,50.8z M118.8,50.9v2.7H92.9v-1h13l10.1-1.7H118.8z'
      />
    </SvgIcon>
  );
};
