export default {
  SUBSCRIBE: {
    LABELS: {
      EMAIL_ADDRESS: 'E-mail Address',
    },
    BUTTONS: {
      SUBMIT: 'Be the first to know',
    },
    MESSAGING: {
      CONFIRMATION:
        'Woo-Hoo! You took the first step in making your life not suck anymore. We will notify you when we launch.',
      DUPLICATE:
        'Whoa there, slow down partner! It looks like we already have you in our system.',
    },
  },
  USER_CATALOG: {
    PLACEHOLDERS: {
      ENTER_MANUFACTURER: 'Start typing a manufacturer name',
      ENTER_SUBTYPE: 'Select a subtype',
      ENTER_DESCRIPTION:
        'Add more details here that are important to this firearm.',
    },
  },
};
