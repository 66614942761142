import { ApisauceInstance, create, ApiResponse } from 'apisauce';
import { getGeneralApiProblem } from './Problem';
import { ApiConfig, DEFAULT_API_CONFIG } from './Config';
import * as Types from './Auth';
import { load, save, remove } from '../../utils/storage';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { isEmpty } from 'ramda';
import { buildFormData } from '../../helpers/Data';

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  async setup() {
    const me: any = await load('me');
    const accessToken = me ? me['accessToken'] : '';
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const refreshAuthLogic = async (failedRequest) =>
      this.apisauce.axiosInstance
        .post('/session/refresh', {
          email: me ? me['email'] : '',
          refresh_token: me ? me['refreshToken'] : '',
          device_key: me ? me['deviceKey'] : '',
          fingerprint: me ? me['fingerprint'] : '',
        })
        .then(async (tokenRefreshResponse: any) => {
          if (isEmpty(me)) {
            return Promise.resolve();
          }
          me['accessToken'] =
            tokenRefreshResponse.data.session_refresh.AccessToken;
          await save('me', me);
          this.apisauce.headers.Authorization = `Bearer ${tokenRefreshResponse.data.session_refresh.AccessToken}`;
          failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data.session_refresh.AccessToken}`;
          return Promise.resolve();
        });

    await createAuthRefreshInterceptor(
      this.apisauce.axiosInstance,
      refreshAuthLogic
    );

    this.apisauce.axiosInstance.interceptors.response.use(
      async (response) => {
        return response;
      },
      async (error) => {
        if ('errors' in error.response.data) {
          if ('session' in error.response.data.errors) {
            if (
              error.response.data.errors.session[0] ===
              'Incorrect access token.'
            ) {
              await remove('me');
              await remove('root');
              window.location.href = '/';
            }
          }
        }
        return error;
      }
    );
  }

  /*
   * Unauthenticated Endpoints
   */
  async createSibContact(email): Promise<any> {
    const response = await this.apisauce.post(`/create_sib_contact/`, {
      email,
    });

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return response;
  }

  async signIn(
    email: string,
    password: string,
    fingerprint: string
  ): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(`/session/`, {
      email,
      password,
      fingerprint,
    });

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    const resultAuth: Types.Auth = {
      email: email,
      accessToken: response.data.session_auth.AccessToken,
      refreshToken: response.data.session_auth.RefreshToken,
      deviceKey: response.data.session_auth.NewDeviceMetadata.DeviceKey,
    };
    return { kind: 'ok', session_auth: resultAuth };
  }

  async signUp(email, password): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(`/user/`, {
      email,
      password,
    });

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', user_register: response };
  }

  async confirmAccount(email, code): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/session/confirm`,
      {
        email,
        code,
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', session_confirm: response };
  }

  async resendConfirmToken(email): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/session/confirm/resend`,
      {
        email,
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return {
      kind: 'ok',
      session_confirm_resend: response,
    };
  }

  async resetPassword(email): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/session/reset`,
      {
        email,
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', session_reset: response };
  }

  async resetPasswordConfirm(email, password, code): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/session/reset/confirm`,
      {
        email,
        password,
        code,
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return {
      kind: 'ok',
      session_reset_confirm: response,
    };
  }

  async refreshAuth(
    email: string,
    refreshToken: string,
    deviceKey: string,
    fingerprint: string
  ): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/session/refresh`,
      { email, refresh_token: refreshToken, device_key: deviceKey, fingerprint }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return {
      kind: 'ok',
      access_token: response.data.session_refresh.AccessToken,
    };
  }

  /*
   * Authenticated Endpoints
   */
  async signOut(): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.delete(`/session/`);

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok' };
  }

  async signOutAll(): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.delete(
      `/session/device`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok' };
  }

  async getDevice(deviceKey: string): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/session/device/${deviceKey}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', device: response.data.device };
  }

  async getDevices(): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/session/device`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', devices: response.data.devices };
  }

  async signOutDevice(deviceKey: string): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.delete(
      `/session/device/${deviceKey}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok' };
  }

  async getMe(): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(`/session/`);

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', user: response.data.user };
  }

  async manufacturer(search): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/manufacturer/`,
      { per_page: 100, filter_like: { name: { starts: search } } }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', manufacturers: response.data.manufacturers };
  }

  async manufacturerTypes(manufacturerId): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/manufacturer/${manufacturerId}/types`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return {
      kind: 'ok',
      manufacturer_types: response.data.manufacturer_types,
    };
  }

  async manufacturerModels(
    manufacturerId,
    firearmTypeId,
    search
  ): Promise<any> {
    let filter_by = {};

    if (manufacturerId) {
      filter_by = { 'manufacturer.id': manufacturerId };
    }

    if (firearmTypeId) {
      filter_by = { ...filter_by, 'firearm_type.id': firearmTypeId };
    }

    const response: ApiResponse<any> = await this.apisauce.get(
      `/manufacturer_model/`,
      {
        filter_by,
        per_page: 100,
        filter_like: { name: { starts: search } },
        unique: true,
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return {
      kind: 'ok',
      manufacturer_models: response.data.manufacturer_models,
    };
  }

  async addToCatalog(catalog): Promise<any> {
    const headers = {
      'Content-Type': 'multipart/form-data',
      Accept: 'multipart/form-data',
    };
    let formData = new FormData();
    for (let key in catalog) {
      if (key === 'files') {
        for (let k in catalog[key]) {
          formData.append(`files[${k}]`, catalog[key][k]);
        }
      } else {
        formData.append(key, catalog[key]);
      }
    }
    const response: ApiResponse<any> = await this.apisauce.post(
      `/catalog/`,
      formData,
      { headers }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', catalog: response.data.catalog };
  }

  async updateCatalog(catalogId, catalog): Promise<any> {
    const headers = {
      'Content-Type': 'multipart/form-data',
      Accept: 'multipart/form-data',
    };

    let formData = new FormData();

    for (let key in catalog) {
      if (key === 'files') {
        for (let k in catalog[key]) {
          formData.append(`files[${k}]`, catalog[key][k]);
        }
      } else {
        formData.append(key, catalog[key]);
      }
    }
    const response: ApiResponse<any> = await this.apisauce.post(
      `/catalog/${catalogId}`,
      formData,
      { headers }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', catalog: response.data.catalog };
  }

  async deleteCatalogItem(catalogId): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.delete(
      `/catalog/${catalogId}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return {
      kind: 'ok',
      data: response.data,
    };
  }

  async catalogTypes(): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/catalog/types`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', catalog_types: response.data.catalog_types };
  }

  async allCatalogs(page: Number = 1, per_page: Number = 30): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(`/catalog/`, {
      page,
      per_page,
    });

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return {
      kind: 'ok',
      catalog_items: response.data.catalog_items,
      count: response.data.count,
    };
  }

  async catalog(id: String): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/catalog/${id}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', catalog: response.data.catalog };
  }

  async getManufacturerModelsByManufacturerAndFirearmType(
    manufacturerId: String,
    firearmTypeId: String,
    modelName: String
  ): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/manufacturer_model/`,
      {
        filter_by: {
          'manufacturer.id': manufacturerId,
          'firearm_type.id': firearmTypeId,
          name: modelName,
        },
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return {
      kind: 'ok',
      manufacturer_models: response.data.manufacturer_models,
    };
  }

  async firearmCalibers(firearmTypeId, search): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/firearm_caliber/`,
      {
        per_page: 100,
        filter_by: { 'firearm_type.id': firearmTypeId },
        filter_like: { name: { starts: search } },
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return {
      kind: 'ok',
      firearm_calibers: response.data.firearm_calibers,
    };
  }

  async firearmTypes(): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/firearm_type/`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', firearm_types: response.data.firearm_types };
  }

  async changeEmail(newEmail: String): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(`/user/email`, {
      email: newEmail,
    });

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', user: response.data.user };
  }

  async changePassword(oldPassword: String, newPassword: String): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.put(
      `/user/password`,
      { old_password: oldPassword, password: newPassword }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', user: response.data.user };
  }

  async changeProfilePic(file, metaData): Promise<any> {
    const headers = {
      'Content-Type': 'multipart/form-data',
      Accept: 'multipart/form-data',
    };
    let formData = new FormData();
    formData.append(`file`, file);
    buildFormData(formData, metaData, 'meta_data');
    const response: ApiResponse<any> = await this.apisauce.put(
      `/user/profile_picture`,
      formData,
      { headers }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', user: response.data.user };
  }

  async confirmChangeEmail(
    email: string,
    password: string,
    code: string
  ): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.get(
      `/user/confirm`,
      { attribute: 'email', email, password, code }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', user_confirm: response.data.user_confirm };
  }

  async saveUserManufacturer(name: String): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/manufacturer/user`,
      {
        name,
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', manufacturer: response.data.manufacturer };
  }

  async saveUserSubtype(name: String, firearm_type_id: String): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/firearm_subtype/user`,
      {
        name,
        firearm_type_id,
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', firearm_subtype: response.data.firearm_subtype };
  }

  async saveUserManufacturerModel(
    name: String,
    firearm_type_id: String,
    firearm_subtype_id: String,
    manufacturer_id: String
  ): Promise<any> {
    const response: ApiResponse<any> = await this.apisauce.post(
      `/manufacturer_model/user`,
      {
        name,
        firearm_type_id,
        firearm_subtype_id,
        manufacturer_id,
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', manufacturer_model: response.data.manufacturer_model };
  }
}
