import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { omit } from 'ramda';
import { EnvironmentExtension } from '../extensions/Environment';
import { USER_POOL_ID, CLIENT_ID } from '../../config/env';

/**
 * Store for authenticating or signing up.
 */
export const AuthStoreModel = types
  .model('AuthStore', {
    email: types.optional(types.string, ''),
    accessToken: types.optional(types.string, ''),
    refreshToken: types.optional(types.string, ''),
    deviceKey: types.optional(types.string, ''),
    fingerprint: types.optional(types.string, ''),
    confirmation: types.optional(types.boolean, false),
    profile_pic: types.optional(types.maybeNull(types.frozen()), null),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setEmail(email: string) {
      self.email = email;
    },
    setAccessToken(accessToken: string) {
      self.accessToken = accessToken;
    },
    setRefreshToken(refreshToken: string) {
      self.refreshToken = refreshToken;
    },
    setDeviceKey(deviceKey: string) {
      self.deviceKey = deviceKey;
    },
    setFingerprint(fingerprint: string) {
      self.fingerprint = fingerprint;
    },
    setConfirmation(confirmation: boolean) {
      self.confirmation = confirmation;
    },
    setProfilePic(profilePic: any) {
      self.profile_pic = profilePic;
    },
  }))
  .actions((self) => ({
    async signIn(email: string, password: string) {
      self.setFingerprint(
        window.AmazonCognitoAdvancedSecurityData.getData(
          email,
          USER_POOL_ID,
          CLIENT_ID
        )
      );

      return await self.environment.api.signIn(
        email,
        password,
        self.fingerprint
      );
    },
    async signUp(email: string, password: string) {
      return await self.environment.api.signUp(email, password);
    },
    async confirmSignUp(email: string, code: string) {
      return await self.environment.api.confirmAccount(email, code);
    },
    async resendConfirmToken(email: string) {
      return await self.environment.api.resendConfirmToken(email);
    },
    async forgotPassword(email: string) {
      return await self.environment.api.resetPassword(email);
    },
    async confirmChangeEmail(email: string, password: string, code: string) {
      return await self.environment.api.confirmChangeEmail(
        email,
        password,
        code
      );
    },
    async forgotPasswordConfirm(email: string, password: string, code: string) {
      return await self.environment.api.resetPasswordConfirm(
        email,
        password,
        code
      );
    },
    async changeEmail(newEmail: string) {
      return await self.environment.api.changeEmail(newEmail);
    },
    async changePassword(oldPassword: string, newPassword: string) {
      return await self.environment.api.changePassword(
        oldPassword,
        newPassword
      );
    },
    async changeProfilePic(file, metaData) {
      return await self.environment.api.changeProfilePic(file, metaData);
    },
    async logout() {
      self.setEmail('');
      self.setAccessToken('');
      self.setRefreshToken('');
      self.setDeviceKey('');
      self.setFingerprint('');
      return await self.environment.api.signOut();
    },
    async logoutAll() {
      self.setEmail('');
      self.setAccessToken('');
      self.setRefreshToken('');
      self.setDeviceKey('');
      self.setFingerprint('');
      return await self.environment.api.signOutAll();
    },
    async logoutDevice(deviceKey: string) {
      if (deviceKey === self.deviceKey) {
        self.setEmail('');
        self.setAccessToken('');
        self.setRefreshToken('');
        self.setDeviceKey('');
        self.setFingerprint('');
      }
      return await self.environment.api.signOutDevice(deviceKey);
    },
    async getDevice(deviceKey: string) {
      return await self.environment.api.getDevice(deviceKey);
    },
    async getMe() {
      return await self.environment.api.getMe();
    },
    async getDevices() {
      return await self.environment.api.getDevices();
    },
    async refresh() {
      const response: any = self.environment.api.refreshAuth(
        self.email,
        self.refreshToken,
        self.deviceKey,
        self.fingerprint
      );
      self.setAccessToken(response.access_token);
    },
  }))
  .postProcessSnapshot(omit(['old_password', 'password']));

type AuthStoreType = Instance<typeof AuthStoreModel>;
export interface AuthStore extends AuthStoreType {}

type AuthStoreSnapshotType = SnapshotOut<typeof AuthStoreModel>;
export interface AuthStoreSnapshot extends AuthStoreSnapshotType {}
