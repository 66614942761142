import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { StyledEngineProvider } from '@material-ui/core';
import { Theme, ThemeProvider } from '@material-ui/core/styles';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import MomentUtils from '@date-io/moment';
import { AuthProvider } from './providers/Auth';
import { Setup, RootStore, RootStoreProvider } from './models';
import MGCTheme from './MGCTheme';
import { DialogProvider } from './components/molecules/Dialog/DialogProvider';
import App from './App';

export default (props: any) => {
  const [loading, setLoading] = useState(true);
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined);

  useEffect(() => {
    (async () => {
      if (loading) {
        Setup().then(setRootStore);
        setLoading(false);
      }
    })();
  }, [loading]);

  if (!rootStore) return null;

  return (
    <CookiesProvider>
      <RootStoreProvider value={rootStore}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider<Theme> theme={MGCTheme}>
            <AuthProvider>
              <Router>
                <LocalizationProvider dateAdapter={MomentUtils}>
                  <DialogProvider>
                    <App />
                  </DialogProvider>
                </LocalizationProvider>
              </Router>
            </AuthProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </RootStoreProvider>
    </CookiesProvider>
  );
};
