import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default (props: any) => {
  return (
    <SvgIcon {...props} viewBox='0 0 160 160'>
      <path
        d='M80,53.4c-8.4,0-15.3-6.8-15.3-15.3S71.5,22.9,80,22.9s15.3,6.8,15.3,15.3S88.4,53.4,80,53.4
        M71.3,80.4c0-4.8,3.9-8.7,8.7-8.7s8.7,3.9,8.7,8.7s-3.9,8.7-8.7,8.7S71.3,85.2,71.3,80.4 M80,137.9c-8.4,0-15.3-6.8-15.3-15.3
       s6.8-15.3,15.3-15.3s15.3,6.8,15.3,15.3S88.4,137.9,80,137.9 M43.5,74.5c-8.4,0-15.3-6.8-15.3-15.3S35.1,44,43.5,44
       s15.3,6.8,15.3,15.3S51.9,74.5,43.5,74.5 M43.5,116.8c-8.4,0-15.3-6.8-15.3-15.3s6.8-15.3,15.3-15.3s15.3,6.8,15.3,15.3
       S51.9,116.8,43.5,116.8 M116.4,86.3c8.4,0,15.3,6.8,15.3,15.3s-6.8,15.3-15.3,15.3s-15.3-6.8-15.3-15.3S108,86.3,116.4,86.3
        M116.4,44c8.4,0,15.3,6.8,15.3,15.3s-6.8,15.3-15.3,15.3s-15.3-6.8-15.3-15.3S108,44,116.4,44 M116.1,128.7c3,0,5.8,0.9,8.2,2.4
       c10.8-9.5,18.6-22.4,21.6-37c-5.1-2.5-8.6-7.7-8.6-13.8s3.5-11.3,8.6-13.8c-3-14.6-10.8-27.5-21.6-37c-2.4,1.5-5.2,2.4-8.2,2.4
       c-8.4,0-15.3-6.8-15.3-15.3c0-0.2,0-0.3,0-0.5C94.3,14.2,87.2,13,80,13s-14.3,1.2-20.9,3.3c0,0.2,0,0.3,0,0.5
       c0,8.4-6.8,15.3-15.3,15.3c-3,0-5.8-0.9-8.2-2.4C24.8,39.1,17.1,51.9,14,66.5c5.3,2.4,9,7.7,9,13.9c0,6.2-3.7,11.5-9,13.9
       c3.1,14.6,10.8,27.4,21.6,36.8c2.4-1.5,5.2-2.4,8.2-2.4c8.4,0,15.3,6.8,15.3,15.3c0,0.2,0,0.3,0,0.5c6.6,2.1,13.6,3.3,20.9,3.3
       s14.3-1.2,20.9-3.3c0-0.2,0-0.3,0-0.5C100.8,135.6,107.7,128.7,116.1,128.7z'
      />
    </SvgIcon>
  );
};
