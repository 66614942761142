import React, { useState } from 'react';
import logoWebp from '../../../assets/images/mgc-logo.webp';
import logodarkWebp from '../../../assets/images/mgc-logo-dark.webp';
import logoPng from '../../../assets/images/mgc-logo.png';
import logodarkPng from '../../../assets/images/mgc-logo-dark.png';
import { ImageWithFallback, WrapWithLink } from '../../../hoc';
import Box from '@material-ui/core/Box';

interface Props {
  link?: string;
  className?: string;
  variation: string | undefined;
  width?: string;
  sx?: object;
}

export default (props: Props) => {
  const [brandWidth] = useState(props.width ? props.width : '234');

  return (
    <Box className={props.className} sx={props.sx}>
      <WrapWithLink link={props.link}>
        <ImageWithFallback
          src={props.variation === 'light' ? logoWebp : logodarkWebp}
          fallback={props.variation === 'light' ? logoPng : logodarkPng}
          alt='My Gun Catalog'
          width={brandWidth}
        />
      </WrapWithLink>
    </Box>
  );
};
