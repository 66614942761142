import React from 'react';
import { Container, makeStyles, Theme } from '@material-ui/core';

interface Props {
  image?: string | undefined;
  children?: React.ReactElement | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  heroContent: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
  },
  heroLarge: {
    backgroundPosition: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    height: 'auto',
    marginTop: theme.spacing(-8),
    minHeight: '100vh',
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8),
    position: 'relative',
    width: '100vw',
    zIndex: 0,
    '&::before': {
      background: 'rgba(0,0,0, 0.6)',
      bottom: 0,
      content: '""',
      display: 'block',
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 0,
    },
    '& > *': {
      zIndex: 1,
    },
  },
}));

export default (props: Props) => {
  const classes = useStyles();

  const heroImage = {
    backgroundImage: `url(${props.image})`,
  };

  return (
    <Container
      style={heroImage}
      className={classes.heroLarge}
      disableGutters={true}
      maxWidth={false}
    >
      <Container maxWidth='lg' className={classes.heroContent}>
        {props.children}
      </Container>
    </Container>
  );
};
