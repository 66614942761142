import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default (props: any) => {
  return (
    <SvgIcon {...props} viewBox='0 0 150 150'>
      <path
        className='color--primary'
        d='M49.8,52.4v11.8h59.9v4.9H82.6c0,0-0.3,2.1-0.7,4.3c-0.2,1.2-0.9,4.3-0.8,6.5
        c-2.2,1.2-7.2,1.7-9.6,1.7c-2.2,0-4.6-0.2-7.3-0.9c-1.9-0.5-3.9-0.3-4.9-0.2l-5.1,13.9c0.7,0,1.8,1.8,1.8,3.5c0,1.7-1.4,3.6-1.9,3.6
        H37c-1.5,0-3.5-2-3.5-4.9v-1.8c1.8-5.7,8.8-19.3,9.8-20.9c0.3-0.5,0.2-1.4-0.2-2.1h3L35.8,99.6h14.7l10.1-29.9H41.1
        c-0.3-0.2-0.7-0.4-1.2-0.6c-1.7-0.8-4.1-1.2-6.8-1.2v-1.6c2.9-0.8,5.6-2.2,6.9-4.6c1.3-2.4,1.7-3.6,1.7-3.6h-2.6l0.4,0
        c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2l0,0h4c0.5-0.9,1.4-1.6,2.5-1.6H49.8z M75.7,69.9h-8.4c-2.6,0-4.7,1.8-4.7,4.1v2
        c0,2.3,2.1,4.1,4.7,4.1h8.4c2.6,0,4.7-1.8,4.7-4.1v-2C80.4,71.7,78.3,69.9,75.7,69.9z M96.2,65.1H83.7c-0.6,0-1,0.4-1,1s0.4,1,1,1
        h12.6c0.6,0,1-0.4,1-1S96.8,65.1,96.2,65.1z M115.6,52.4v13.8h-4.9v-3.1H70.2V52.4H115.6z M39.5,54.7c-0.7,0-1.3,0.6-1.3,1.3
        s0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3S40.2,54.7,39.5,54.7z'
      />
      <path
        className='color--secondary'
        d='M57.8,71.7L49,97.6H38.6l9.6-25.9H57.8z M48.2,81.6c-1.4,0-2.5,1.1-2.5,2.5
        s1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5S49.6,81.6,48.2,81.6z M48.4,82.3c0.9,0.1,1.5,0.8,1.5,1.7c0,0.9-0.7,1.6-1.5,1.7V82.3z
         M48,82.3v3.5c-0.9-0.1-1.5-0.8-1.5-1.7C46.5,83.2,47.1,82.5,48,82.3z M70.6,70.4c-3.2,4,1.9,7.5,1.9,7.5s-6.1-0.7-4.7-7.5l0,0H70.6
        z M52.2,53v8.5h1V53h0.3v8.5h1V53h0.3v8.5h1V53h0.3v8.5h1V53h0.3v8.5h1V53h0.3v8.5h1V53h0.3v8.5h1V53h0.3v8.5h1V53h0.3v8.5h1V53h0.3
        v8.5h1V53h0.3v8.5h1V53h0.3v8.5h1V53h1.3v10.2H50.8V53H52.2z M117.8,53.3v6.5H116v-6.5H117.8z M49.8,50.3V52H47
        c0.7-0.6,1.1-1.7,1.1-1.7l0,0H49.8z M112.4,49l3.2,1.2V52h-7c1.1-0.5,1.9-1.6,1.9-3l0,0H112.4z'
      />
    </SvgIcon>
  );
};
