import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';

/**
 * Store for Manufacturer
 */
export const ManufacturerStoreModel = types.model('ManufacturerStore', {
  id: types.optional(types.string, ''),
  created: types.optional(types.string, ''),
  updated: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
  active: types.optional(types.boolean, true),
  description: types.maybeNull(types.string),
});

/**
 * Store for Manufacturers
 */
export const ManufacturersStoreModel = types
  .model('ManufacturersStore', {
    manufacturers: types.array(ManufacturerStoreModel),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setManufacturers(manufacturers) {
      self.manufacturers = manufacturers;
    },
  }))
  .actions((self) => ({
    async getManufacturers(search: string) {
      const manufacturerResponse = await self.environment.api.manufacturer(
        search
      );

      if (manufacturerResponse && 'manufacturers' in manufacturerResponse) {
        self.setManufacturers(manufacturerResponse.manufacturers);

        return manufacturerResponse.manufacturers;
      } else {
        self.setManufacturers([]);

        return [];
      }
    },
    async saveUserManufacturer(name: String) {
      const manufacturerResponse = await self.environment.api.saveUserManufacturer(name);

      if (manufacturerResponse) {
        return manufacturerResponse;
      }

      return null;
    },
    async saveUserManufacturerModel(name: String, firearm_type_id: String, firearm_subtype_id: String, manufacturer_id: String) {
      const manufacturerModelResponse = await self.environment.api.saveUserManufacturerModel(
        name,
        firearm_type_id,
        firearm_subtype_id,
        manufacturer_id
      );

      if (manufacturerModelResponse) {
        return manufacturerModelResponse;
      }

      return null;
    }
  }));

type ManufacturerStoreType = Instance<typeof ManufacturerStoreModel>;
export interface ManufacturerStore extends ManufacturerStoreType {}

type ManufacturerStoreSnapshotType = SnapshotOut<typeof ManufacturerStoreModel>;
export interface ManufacturerStoreSnapshot
  extends ManufacturerStoreSnapshotType {}
