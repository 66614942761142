import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default (props: any) => {
  return (
    <SvgIcon {...props} viewBox='0 0 150 150'>
      <path
        className='color--primary'
        d='M3,71.8h2.7V56H3V71.8z M70.8,76.4h-3.6c-1.6,0-3-1.3-3-3v-2.6h1.2v2.6c0,1,0.8,1.8,1.8,1.8h3.6
        c1,0,1.8-0.8,1.8-1.8v-2.6h1.2v2.6C73.8,75.1,72.5,76.4,70.8,76.4L70.8,76.4z M76.4,97.2h1.8V57.3h-1.8V97.2z M87,57.6
        c0,0.6-0.3,1.2-0.8,1.6v-2.2H72.6v2.4c-0.6-0.4-1-1-1-1.7c0-1.1,0.9-2,2.1-2H85C86.1,55.5,87,56.4,87,57.6L87,57.6z M87,57.6
        C87,57.6,87,57.6,87,57.6C87,57.6,87,57.6,87,57.6L87,57.6z M71.6,57.5C71.6,57.6,71.6,57.6,71.6,57.5
        C71.6,57.6,71.6,57.6,71.6,57.5L71.6,57.5z M122.6,103h1.8V68.6h-1.8V103z M119.1,104h7.2v-1h-7.2V104z M104,65.2h34.8v-1.8H104
        V65.2z M95.6,57.7c0.3,1.5,1.7,2.7,3.3,2.7c1.6,0,3-1.2,3.3-2.7l0,0h20.1v3.8h-17.7l-1.4,1.9v5.2H93V57.7H95.6z M101,66.3h-5v1.2h5
        V66.3z M101,64.1h-5v1.2h5V64.1z M143,58.4v1.5h-17v-1.5H143z M65.8,62.3H48.5c-1.2,0-2.6-3.5-3.2-5c-0.1-0.2-0.2-0.4-0.2-0.6
        c0-0.1,0-0.1,0-0.1l0,0c0-0.1,0-0.2,0-0.3c0-1.2,1-2.2,2.2-2.2c1,0,1.8,0.7,2.1,1.6l0,0l0.6,1.2h15.8V62.3z M92.6,51.3H70.8V51H39.6
        v4h2l7.2,15.1h5.6h16.3V54.3h17v14.3h4.9V57.7V51.3z M39.7,60.3V69l-3-8.7v-2.6h3V60.3z M39.6,56.9h-4.1v14h4.9v-14H39.6z'
      />
      <path
        className='color--secondary'
        d='M63.4,60.4c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7h-7.8c-0.4,0-0.7-0.3-0.7-0.7
        c0-0.4,0.3-0.7,0.7-0.7H63.4z M63.4,58.3c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7h-7.8c-0.4,0-0.7-0.3-0.7-0.7
        c0-0.4,0.3-0.7,0.7-0.7H63.4z M47.3,55.6c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
        C45.7,56.3,46.4,55.6,47.3,55.6z M47.3,56.4c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9S47.8,56.4,47.3,56.4z
         M69.6,47.5c0.5,0,0.9,0.4,0.9,0.9l0,0v2H60.4v-2c0-0.5,0.4-0.9,0.9-0.9l0,0H69.6z M46.3,46c1.2,0,3.8,1.8,5.7,3l0,0v1.5h-8.3V48
        C44.6,47.1,45.1,46,46.3,46z M64.6,48.6c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7
        C65.3,49,65,48.6,64.6,48.6z M62.2,48.6c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7
        C62.9,49,62.6,48.6,62.2,48.6z M46.4,47c-0.5,0-1,0.4-1,1s0.4,1,1,1c0.5,0,1-0.4,1-1S46.9,47,46.4,47z M81,95.1c0.9,0,4,0.6,4,0.9
        c0,0.4-3,0.9-4,0.9h-0.6v-1.9H81z M80,95.1V97h-1.5v-1.9H80z M75.9,95.1V97h-1.5v-1.9H75.9z M81,92.3c0.9,0,4,0.6,4,0.9
        c0,0.4-3,0.9-4,0.9h-0.6v-1.9H81z M80,92.3v1.9h-1.5v-1.9H80z M75.9,92.3v1.9h-1.5v-1.9H75.9z M81,89.5c0.9,0,4,0.6,4,0.9
        c0,0.4-3,0.9-4,0.9h-0.6v-1.9H81z M80,89.5v1.9h-1.5v-1.9H80z M75.9,89.5v1.9h-1.5v-1.9H75.9z M81,86.7c0.9,0,4,0.6,4,0.9
        c0,0.4-3,0.9-4,0.9h-0.6v-1.9H81z M80,86.7v1.9h-1.5v-1.9H80z M75.9,86.7v1.9h-1.5v-1.9H75.9z M81,83.9c0.9,0,4,0.6,4,0.9
        c0,0.4-3,0.9-4,0.9h-0.6v-1.9H81z M80,83.9v1.9h-1.5v-1.9H80z M75.9,83.9v1.9h-1.5v-1.9H75.9z M81,81.1c0.9,0,4,0.6,4,0.9
        c0,0.4-3,0.9-4,0.9h-0.6v-1.9H81z M80,81.1v1.9h-1.5v-1.9H80z M75.9,81.1v1.9h-1.5v-1.9H75.9z M81,78.2c0.9,0,4,0.6,4,0.9
        c0,0.4-3,0.9-4,0.9h-0.6v-1.9H81z M80,78.2v1.9h-1.5v-1.9H80z M75.9,78.2v1.9h-1.5v-1.9H75.9z M81,75.4c0.9,0,4,0.6,4,0.9
        c0,0.4-3,0.9-4,0.9h-0.6v-1.9H81z M80,75.4v1.9h-1.5v-1.9H80z M75.9,75.4v1.9h-1.5v-1.9H75.9z M67.6,71.4c-0.8,1.7,1.3,3.4,1.3,3.4
        s-2.7-0.8-2.4-3.4H67.6z M81,72.6c0.9,0,4,0.6,4,0.9c0,0.4-3,0.9-4,0.9h-0.6v-1.9H81z M80,72.6v1.9h-1.5v-1.9H80z M75.9,72.6v1.9
        h-1.5v-1.9H75.9z M81,69.8c0.9,0,4,0.6,4,0.9c0,0.4-3,0.9-4,0.9h-0.6v-1.9H81z M80,69.8v1.9h-1.5v-1.9H80z M75.9,69.8v1.9h-1.5v-1.9
        H75.9z M81,67c0.9,0,4,0.6,4,0.9c0,0.4-3,0.9-4,0.9h-0.6V67H81z M80,67v1.9h-1.5V67H80z M75.9,67v1.9h-1.5V67H75.9z M81,64.2
        c0.9,0,4,0.6,4,0.9c0,0.4-3,0.9-4,0.9h-0.6v-1.9H81z M80,64.2V66h-1.5v-1.9H80z M75.9,64.2V66h-1.5v-1.9H75.9z M81,61.3
        c0.9,0,4,0.6,4,0.9c0,0.4-3,0.9-4,0.9h-0.6v-1.9H81z M80,61.3v1.9h-1.5v-1.9H80z M75.9,61.3v1.9h-1.5v-1.9H75.9z M81,58.5
        c0.9,0,4,0.6,4,0.9c0,0.4-3,0.9-4,0.9h-0.6v-1.9H81z M80,58.5v1.9h-1.5v-1.9H80z M75.9,58.5v1.9h-1.5v-1.9H75.9z M128,66.1v0.8
        c0,1-0.8,1.8-1.8,1.8h-6.9c-1,0-1.8-0.8-1.8-1.8v-0.8H128z M125.4,54.8V63h-2.7v-5.6h-2.2v-2.6H125.4z M148,57.8v4.1h-4.5v-4.1H148z
         M147.2,58.5h-2.5l2.5,2V58.5z M92,46.4c0.5,0,0.9,0.4,0.9,0.9l0,0V48h3.5l8.4,3.7l-1.4,3.4h-4.3v0.3c1.1,0.2,1.9,1.2,1.9,2.3
        c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1,0.7-1.9,1.6-2.2l0,0v-1.6h4.7l0.7-1.6l-7.1-3.2h-3.2v0.9c0,0.5-0.4,0.9-0.9,0.9l0,0
        H81.7c-0.5,0-0.9-0.4-0.9-0.9l0,0v-2.7c0-0.5,0.4-0.9,0.9-0.9l0,0H92z M98.6,56.5c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2
        c0.6,0,1.2-0.5,1.2-1.2C99.8,57.1,99.3,56.5,98.6,56.5z M135.5,51v7.2h-3.2c1.6-3.1,1.8-5.7,1.8-7.2H135.5z M122,55.1
        c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9C122.9,55.5,122.5,55.1,122,55.1z M91.8,47.6H82v2.2h9.8V47.6z
         M47.9,71.4h6.4v1.9H41.1v-1.9V60.8H42H43L47.9,71.4z M6,69.5h28.6v-1.9H6V69.5z M6,61.1h28.6v-1.9H6V61.1z M63.8,75.9
        c0,0.9-2.6,6.1-4,9.5c0.7,0.9,1.7,1.8,2.2,2.3c0.6,0.6,0.5,1.6,0,1.6h-4.3c-1.1,0-6.6-1.6-7.4-1.9c-1-0.3-1.4-0.7-1.5-1.2H56
        c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-6.8c0.1-0.2,0.3-0.5,0.6-0.9h7.1c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-6.2
        c0.2-0.3,0.4-0.6,0.6-0.9h6.5c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-5.5c0.2-0.3,0.4-0.6,0.6-0.9h5.9c0.4,0,0.7-0.3,0.7-0.7
        c0-0.4-0.3-0.7-0.7-0.7h-4.9c0.2-0.4,0.4-0.7,0.6-0.9h5.2c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7h-4.6v-3.9h8.7V75.9z'
      />
    </SvgIcon>
  );
};
