import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
  useRef,
} from 'react';
import { DialogContainer } from './DialogContainer';
import { EpicDialogProps } from './EpicDialogProps';
import { ProviderContext, EpicDialogOption } from './ProviderContext';

const DialogContext = createContext<ProviderContext>(
  null as unknown as ProviderContext
);

export const DialogProvider = ({ children }: PropsWithChildren<{}>) => {
  const [dialogs, setDialogs] = useState<EpicDialogProps[]>([]);

  const createDialog = (option: EpicDialogOption) => {
    const dialog = { ...option, open: true };
    setDialogs((dialogs) => [...dialogs, dialog]);
  };

  const closeDialog = () => {
    setDialogs((dialogs) => {
      dialogs.pop();
      return [...dialogs];
    });
  };

  const contextValue = useRef([createDialog, closeDialog] as const);

  return (
    <DialogContext.Provider value={contextValue.current}>
      {children}
      {dialogs.map((dialog, i) => {
        const { children: dialogChildren, ...dialogParams } = dialog;
        return (
          <DialogContainer key={i} closeDialog={closeDialog} {...dialogParams}>
            {dialogChildren}
          </DialogContainer>
        );
      })}
    </DialogContext.Provider>
  );
};

export const useDialog = (): ProviderContext => {
  const result = useContext(DialogContext);

  if (!result) {
    throw new Error('Dialog context is only available inside its provider');
  }
  return result;
};
