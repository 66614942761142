import { Instance, SnapshotOut, types } from "mobx-state-tree";
import { EnvironmentExtension } from "../extensions/Environment";

/**
 * Store for Photo
 */
export const PhotoStoreModel = types.model("PhotoStore", {
  s3_path: types.optional(types.string, ""),
});

/**
 * Store for Photos
 */
export const PhotosStoreModel = types
  .model("PhotosStore", {
    Photos: types.array(PhotoStoreModel),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setPhotos(Photos) {
      self.Photos = Photos;
    },
  }))
  .actions((self) => ({}));

type PhotoStoreType = Instance<typeof PhotoStoreModel>;
export interface PhotoStore extends PhotoStoreType {}

type PhotoStoreSnapshotType = SnapshotOut<typeof PhotoStoreModel>;
export interface PhotoStoreSnapshot extends PhotoStoreSnapshotType {}
