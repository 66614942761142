import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { WithAnchor, WrapWithLink } from '../../../hoc';
import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { scrollToElement } from '../../../helpers/Data';

export default () => {
  return (
    <>
      <Typography variant='h2'>Disclaimer</Typography>

      <Typography variant='subtitle1' gutterBottom>
        Last Updated Octber 31, 2021
      </Typography>

      <Typography variant='h3' gutterBottom>
        WEBSITE DISCLAIMER
      </Typography>

      <Typography variant='body1' paragraph>
        The information provided by Creative Lid LLC on{' '}
        <WrapWithLink link='/' className='styled--link'>
          My Gun Catalog
        </WrapWithLink>{' '}
        and our mobile application is for general informational purposes only.
        All information on the Site and our mobile application is provided in
        good faith, however we make no representation or warranty of any kind,
        express or implied, regarding the accuracy, adequacy, validity,
        reliability, availability or completeness of any information on the Site
        or our mobile application. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY
        LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT
        OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR RELIANCE ON ANY
        INFORMATION PROVIDED ON THE SITE AND OUR MOBILE APPLICATION. YOUR USE OF
        THE SITE AND OUR MOBILE APPLICATION AND YOUR RELIANCE ON ANY INFORMATION
        ON THE SITE AND OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.
      </Typography>
    </>
  );
};
