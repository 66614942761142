import React from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Hidden,
  List,
  ListItem,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Brand, ScrollDownArrow } from '../../atoms';
import heroImage from '../../../assets/images/hero.jpg';
import { Footer, Hero } from '../../organisms';
import ContentConst from '../../../constants/ContentConst';
import ButtonsConst from '../../../constants/ButtonsConst';
import GeneralConst from '../../../constants/GeneralConst';
import { ImageWithFallback } from '../../../hoc';
import screenShot1Png from '../../../assets/images/MyGunCatalog_screenshot-01.png';
import screenShot1Webp from '../../../assets/images/MyGunCatalog_screenshot-01.webp';
import { RegisterButton } from '../../molecules/Dialog/Dialog';
import { useDialog } from '../../molecules/Dialog/DialogProvider';
import { useRunAsEffect } from '../../molecules/Dialog/useRunAsEffect';
import { props } from 'ramda';
import { Register } from '../../molecules';
import registerImage from '../../../assets/images/register-background.jpg';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    flexGrow: 1,
  },
  section: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    scrollMarginTop: '64px',
  },
  bottomCTA: {
    '& > *': {
      margin: theme.spacing(4),
    },
  },
}));

export default () => {
  const classes = useStyles();
  const [openDialog] = useDialog();

  const handleOpenRegister = useRunAsEffect(() => {
    openDialog({
      fullScreen: true,
      children: (
        <>
          <Hidden smDown>
            <Brand
              className={classes.title}
              variation='light'
              width='380'
              sx={{
                position: 'absolute',
                top: 0,
                left: '40px',
                zIndex: 100,
              }}
            />
          </Hidden>

          <Hidden smUp>
            <Brand
              className={classes.title}
              variation='dark'
              width='280'
              sx={{
                position: 'absolute',
                top: '8px',
                left: '16px',
                zIndex: 100,
              }}
            />
          </Hidden>

          <Register image={registerImage} />
        </>
      ),
    });
  });

  return (
    <>
      <Hero image={heroImage}>
        <>
          <Box>
            <Typography
              variant='h1'
              color='primary'
              className='mega'
              align='center'
            >
              <span>{ContentConst.HOME.HERO.HEADLINE_SPAN}</span>
              {ContentConst.HOME.HERO.HEADLINE}
              <span>{ContentConst.HOME.HERO.HEADLINE_META}</span>
            </Typography>
          </Box>

          <ScrollDownArrow
            color={grey[100]}
            fontSize='4rem'
            anchor='#tagline'
          />
        </>
      </Hero>

      <Box
        className={classes.section}
        id='tagline'
        // sx={{
        //   backgroundColor: 'secondary.contrastText',
        // }}
      >
        <Container maxWidth='lg'>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='h2' color='secondary.dark' align='center'>
                {GeneralConst.GENERAL.TAGLINE}
              </Typography>

              <Typography variant='subtitle1' align='center'>
                {GeneralConst.GENERAL.TAGLINE_DESCRIPTION}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        className={classes.section}
        id='section1'
        sx={{
          backgroundColor: 'secondary.contrastText',
        }}
      >
        <Container maxWidth='lg'>
          <Grid container>
            <Grid container item spacing={10} xs={12} alignItems='center'>
              <Grid item sm={12} md={6}>
                <Typography variant='h2'>
                  {ContentConst.HOME.SECTION_1.HEADING}
                </Typography>

                {ContentConst.HOME.SECTION_1.SUB_HEADING !== '' && (
                  <Typography variant='subtitle1' gutterBottom>
                    {ContentConst.HOME.SECTION_1.SUB_HEADING}
                  </Typography>
                )}

                <Typography>{ContentConst.HOME.SECTION_1.CONTENT}</Typography>

                <Divider className='spacer' />

                <Typography variant='h2'>
                  {ContentConst.HOME.SECTION_2.HEADING}
                </Typography>

                {ContentConst.HOME.SECTION_2.SUB_HEADING !== '' && (
                  <Typography variant='subtitle1' gutterBottom>
                    {ContentConst.HOME.SECTION_2.SUB_HEADING}
                  </Typography>
                )}

                <List className='list--bulleted' disablePadding dense>
                  {ContentConst.HOME.SECTION_2.CONTENT.map((item, index) => (
                    <ListItem disableGutters key={index}>
                      {item.LIST_ITEM}
                    </ListItem>
                  ))}
                </List>
              </Grid>

              <Hidden smDown>
                <Grid item sm={12} md={6}>
                  <ImageWithFallback
                    src={screenShot1Webp}
                    fallback={screenShot1Png}
                    alt='Dashboard sample'
                    width='100%'
                  />
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className={classes.section}>
        <Container maxWidth='lg'>
          <Grid container>
            <Grid container item spacing={10} xs={12}>
              <Grid item xs={12}>
                <Typography
                  variant='h3'
                  color='secondary.dark'
                  className={classes.bottomCTA}
                  align='center'
                >
                  {ContentConst.HOME.SECTION_BOTTOM.CALL_TO_ACTION}

                  <Button
                    variant='contained'
                    size='large'
                    onClick={handleOpenRegister}
                  >
                    {ButtonsConst.REGISTER_NOW}
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
