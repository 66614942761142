import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Button,
  Container,
  FormControl,
  Grid,
  Hidden,
  Link,
  List,
  ListItem,
  makeStyles,
  Slide,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import ButtonsConst from '../../../constants/ButtonsConst';
import GeneralConst from '../../../constants/GeneralConst';
import { useStores } from '../../../models';
import { AuthContext } from '../../../providers/Auth';
import { useHistory } from 'react-router-dom';
import ErrorsConst from '../../../constants/ErrorsConst';
import { VerifyAccount } from '../../pages';
import TagManager from 'react-gtm-module';

interface Props {
  image?: string;
  handleClose?: () => any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  left: {
    backgroundPosition: '50%',
    backgroundSize: 'cover',
    color: grey[100],
    marginTop: theme.spacing(-9),
    padding: theme.spacing(9, 6, 6, 6),
    position: 'relative',
    zIndex: 0,
    '&::before': {
      background: grey[800],
      bottom: 0,
      content: '""',
      display: 'block',
      left: 0,
      opacity: '0.9',
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 0,
    },
    '& > *': {
      position: 'relative',
      zIndex: 1,
    },
    '& .MuiTypography-h4': {
      color: 'inherit',
    },
  },
  right: {
    marginTop: theme.spacing(-9),
    padding: theme.spacing(9, 6, 6, 6),
  },
}));

export default observer((props: Props) => {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<string>();
  const { authStore } = useStores();
  const authContext = useContext(AuthContext);
  const history = useHistory();

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    const results = await authContext.setMe(authStore, username, password);

    if (results.kind !== 'ok') {
      setErrors(ErrorsConst.INVALID_LOGIN);
    }

    if (results.kind === 'ok') {
      const tagManagerArgs = {
        dataLayer: {
          formName: 'mgc-Login',
          event: 'formSubmit',
        },
      };

      TagManager.dataLayer(tagManagerArgs);

      history.push('/');
      props.handleClose();
    }
  };

  const resetPassword = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    setUsername('');
    setPassword('');

    const results = await authStore.forgotPassword(username);

    switch (results.kind) {
      case 'bad-data':
        const errorText = results.errors.errors.session[0];

        setErrors(
          errorText.match('LimitExceededException') ? 'exceeded' : 'bad-data'
        );
        break;
      case 'ok':
        authStore.setConfirmation(true);
        break;
    }
  };

  const splitScreenImage = {
    backgroundImage: `url(${props.image})`,
  };

  return (
    <Container
      className='split-dialog-content'
      disableGutters={true}
      maxWidth={false}
    >
      <Grid container className={classes.root}>
        <Hidden smDown>
          <Grid
            item
            md={5}
            sm={6}
            className={`${classes.left} ${classes.root}`}
            style={splitScreenImage}
          >
            <Grid container alignItems='center' justifyContent='center'>
              <Grid item xs={12}>
                <Typography variant='h4' gutterBottom>
                  Find out why others are using MyGunCatalog to manage their
                  firearm collection.
                </Typography>
                <List className='list--bulleted'>
                  <ListItem disableGutters>
                    Military-grade encryption to maintain anonymity and security
                  </ListItem>
                  <ListItem disableGutters>
                    Gun Collection Software That's Fun
                  </ListItem>
                  <ListItem disableGutters>
                    {GeneralConst.GENERAL.TAGLINE}
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>

        <Grid
          className={`${classes.root} ${classes.right}`}
          item
          xs={12}
          md={7}
          sm={6}
        >
          <Grid container item alignItems='center' justifyContent='center'>
            <Grid item xs={12} md={7}>
              <Slide
                direction='up'
                in={!authStore.confirmation}
                mountOnEnter
                unmountOnExit
              >
                <Container>
                  <Typography variant='h3'>Sign in to your catalog.</Typography>
                  <Typography variant='subtitle1' gutterBottom>
                    Enter your details below.
                  </Typography>

                  <Grid container justifyContent='center' spacing={4}>
                    <Grid item sm={12}>
                      <FormControl required fullWidth margin='normal'>
                        <TextField
                          name='email'
                          fullWidth
                          type='email'
                          required={true}
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          label={GeneralConst.GENERAL.EMAIL}
                        />
                      </FormControl>

                      <FormControl required fullWidth margin='normal'>
                        <TextField
                          name='password'
                          fullWidth
                          type='password'
                          required={true}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          label={GeneralConst.GENERAL.PASSWORD}
                        />
                      </FormControl>
                    </Grid>

                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      spacing={2}
                      alignItems='stretch'
                      justifyContent='center'
                    >
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                          <Button
                            color='primary'
                            disabled={!username || !password}
                            size='large'
                            type='submit'
                            variant='outlined'
                            onClick={(event) => handleSubmit(event)}
                          >
                            {ButtonsConst.LOGIN}
                          </Button>
                        </FormControl>
                      </Grid>

                      <Grid
                        container
                        item
                        sm={12}
                        md={6}
                        spacing={1}
                        alignItems='center'
                        justifyContent='flex-start'
                      >
                        <Grid item>
                          <Typography variant='caption'>
                            {GeneralConst.GENERAL.FORGOT_PASSWORD}{' '}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography variant='caption' color='secondary'>
                            {!username && <>{ButtonsConst.RESET_PASSWORD}</>}
                            {username && (
                              <Link
                                disabled={!username}
                                color='secondary'
                                component='button'
                                variant='caption'
                                onClick={(event) => resetPassword(event)}
                              >
                                {ButtonsConst.RESET_PASSWORD}
                              </Link>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item sm={12}>
                      {errors && errors !== 'exceeded' && (
                        <>
                          <Typography color='error' variant='caption'>
                            {errors}
                          </Typography>
                        </>
                      )}

                      {errors && errors === 'exceeded' && (
                        <>
                          <Typography color='error' variant='caption'>
                            You have already requested a reset code.{' '}
                            <Link
                              color='secondary'
                              component='button'
                              variant='caption'
                              onClick={() => {
                                setErrors('');
                                authStore.setConfirmation(true);
                              }}
                            >
                              {ButtonsConst.ENTER_VERIFICATION}
                            </Link>
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Container>
              </Slide>

              <Slide
                direction='up'
                in={authStore.confirmation}
                mountOnEnter
                unmountOnExit
              >
                <Container>
                  <VerifyAccount />
                </Container>
              </Slide>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
});
