import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';

/**
 * Store for ManufacturerType
 */
export const FirearmTypeStoreModel = types.model('FirearmType', {
  id: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
});

/**
 * Store for FirearmTypes
 */
export const FirearmTypesStoreModel = types
  .model('FirearmTypesStore', {
    firearmTypes: types.array(FirearmTypeStoreModel),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setFirearmTypes(firearmTypes) {
      self.firearmTypes = firearmTypes;
    },
  }))
  .actions((self) => ({
    async getFirearmTypes() {
      const manufacturerTypeResponse = await self.environment.api.firearmTypes();

      if (
        manufacturerTypeResponse &&
        'firearm_types' in manufacturerTypeResponse
      ) {
        self.setFirearmTypes(manufacturerTypeResponse.firearm_types);

        return manufacturerTypeResponse.firearm_types;
      } else {
        self.setFirearmTypes([]);

        return [];
      }
    },
  }));

type FirearmTypeType = Instance<typeof FirearmTypeStoreModel>;
export interface FirearmType extends FirearmTypeType {}

type FirearmTypeSnapshotType = SnapshotOut<typeof FirearmTypeStoreModel>;
export interface FirearmTypeSnapshot extends FirearmTypeSnapshotType {}
