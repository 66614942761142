import { Instance, SnapshotOut, types } from "mobx-state-tree";
import { EnvironmentExtension } from "../extensions/Environment";

/**
 * Store for Subtype
 */
export const SubtypeStoreModel = types.model("SubtypeStore", {
  id: types.optional(types.string, ""),
  name: types.optional(types.string, ""),
});

/**
 * Store for Subtypes
 */
export const SubtypesStoreModel = types
  .model("SubtypesStore", {
    subtypes: types.array(SubtypeStoreModel),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setSubtypes(subtypes) {
      self.subtypes = subtypes;
    },
    async saveUserSubtype(name: String, firearm_type_id: String) {
      const subtypeResponse = await self.environment.api.saveUserSubtype(
        name,
        firearm_type_id
      );

      if (subtypeResponse) {
        return subtypeResponse;
      }

      return null;
    }
  }));

type SubtypeStoreType = Instance<typeof SubtypeStoreModel>;
export interface SubtypeStore extends SubtypeStoreType {}

type SubtypeStoreSnapshotType = SnapshotOut<typeof SubtypeStoreModel>;
export interface SubtypeStoreSnapshot extends SubtypeStoreSnapshotType {}
