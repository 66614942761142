import { createMuiTheme } from '@material-ui/core/styles';
import { amber, grey } from '@material-ui/core/colors';
import common from '@material-ui/core/colors/common';
import 'typeface-roboto';
import 'typeface-advent-pro';
import 'typeface-open-sans';

import './assets/scss/styles.scss';

const defaultTheme = createMuiTheme();

const MGCTheme = createMuiTheme({
  type: 'light',
  palette: {
    primary: {
      light: amber[500],
      main: amber[700],
      dark: amber[900],
      contrastText: amber[100],
    },
    secondary: {
      light: grey[400],
      main: grey[600],
      dark: grey[800],
      contrastText: grey[100],
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(0, 0, 0, 0.14)',
      disabled: grey[500],
      disabledBackground: grey[300],
    },
    background: {
      paper: common.white,
      default: common.white,
    },
    text: {
      primary: grey[800],
      secondary: grey[100],
      disabled: 'rgba(89, 89, 89, 0.38)',
      hint: 'rgba(89, 89, 89, 0.38)',
    },
    divider: 'rgba(89, 89, 89, 0.2)',
  },
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h1: {
      color: grey[800],
      fontFamily: '"Advent Pro", "Helvetica", "Arial", sans-serif',
      fontSize: '3.25rem',
      // fontSize: 'inherit',
      '&.styled--link, &.styled--link a, & .styled--link': {
        color: grey[800],
        fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
        textDecoration: 'none',
        width: 'unset',
        '&:hover': {
          textDecoration: 'underline',
          '@media (hover: none)': {
            textDecoration: 'underline',
          },
        },
      },
      '&.mega': {
        fontSize: '4.5rem',
        [defaultTheme.breakpoints.down('md')]: {
          fontSize: '3rem',
        },
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: '2.25rem',
        },
        '& span': {
          display: 'block',
          fontSize: '2.5rem',
          [defaultTheme.breakpoints.down('md')]: {
            fontSize: '2rem',
          },
          [defaultTheme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
          },
        },
      },
    },
    h2: {
      color: grey[800],
      fontFamily: '"Advent Pro", "Helvetica", "Arial", sans-serif',
      fontSize: '3rem',
      '&.styled--link, &.styled--link a, & .styled--link': {
        color: grey[800],
        fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
        textDecoration: 'none',
        width: 'unset',
        '&:hover': {
          textDecoration: 'underline',
          '@media (hover: none)': {
            textDecoration: 'underline',
          },
        },
      },
    },
    h3: {
      color: grey[800],
      fontFamily: '"Advent Pro", "Helvetica", "Arial", sans-serif',
      fontSize: '2.25rem',
      '&.styled--link, &.styled--link a, & .styled--link': {
        color: grey[800],
        fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
        textDecoration: 'none',
        width: 'unset',
        '&:hover': {
          textDecoration: 'underline',
          '@media (hover: none)': {
            textDecoration: 'underline',
          },
        },
      },
    },
    h4: {
      color: grey[800],
      fontFamily: '"Advent Pro", "Helvetica", "Arial", sans-serif',
      fontSize: '1.5rem',
      '&.styled--link, &.styled--link a, & .styled--link': {
        color: grey[800],
        fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
        textDecoration: 'none',
        width: 'unset',
        '&:hover': {
          textDecoration: 'underline',
          '@media (hover: none)': {
            textDecoration: 'underline',
          },
        },
      },
      [defaultTheme.breakpoints.down('sm')]: {
        marginBottom: 0,
      },
    },
    h5: {
      color: grey[800],
      fontFamily: '"Advent Pro", "Helvetica", "Arial", sans-serif',
      fontSize: '1.25rem',
      '&.styled--link, &.styled--link a, & .styled--link': {
        color: grey[800],
        fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
        textDecoration: 'none',
        width: 'unset',
        '&:hover': {
          textDecoration: 'underline',
          '@media (hover: none)': {
            textDecoration: 'underline',
          },
        },
      },
    },
    subtitle1: {
      color: grey[600],
      fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
      '&.styled--link, &.styled--link a, & .styled--link': {
        color: grey[800],
        fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
        textDecoration: 'none',
        width: 'unset',
        '&:hover': {
          textDecoration: 'underline',
          '@media (hover: none)': {
            textDecoration: 'underline',
          },
        },
      },
    },
    subtitle2: {
      color: grey[600],
      fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
      '&.styled--link, &.styled--link a, & .styled--link': {
        color: grey[800],
        fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
        textDecoration: 'none',
        width: 'unset',
        '&:hover': {
          textDecoration: 'underline',
          '@media (hover: none)': {
            textDecoration: 'underline',
          },
        },
      },
    },
    body1: {
      color: grey[800],
      fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
      fontSize: '1rem',
      '&.styled--link, &.styled--link a, & .styled--link': {
        color: grey[800],
        fontFamily: '"Helvetica Neue Regular", Helvetica, Arial, sans-serif;',
        textDecoration: 'none',
        width: 'unset',
        '&:hover': {
          textDecoration: 'underline',
          '@media (hover: none)': {
            textDecoration: 'underline',
          },
        },
      },
    },
    body2: {
      color: grey[800],
      fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
      fontSize: '1rem',
      fontWeight: 700,
      '&.styled--link, &.styled--link a, & .styled--link': {
        color: grey[800],
        fontFamily: '"Helvetica Neue Regular", Helvetica, Arial, sans-serif;',
        textDecoration: 'none',
        width: 'unset',
        '&:hover': {
          textDecoration: 'underline',
          '@media (hover: none)': {
            textDecoration: 'underline',
          },
        },
      },
    },
    caption: {
      color: grey[800],
      fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
      fontSize: '0.75rem',
      '&.styled--link, &.styled--link a, & .styled--link': {
        color: grey[800],
        fontFamily: '"Helvetica Neue Regular", Helvetica, Arial, sans-serif;',
        textDecoration: 'none',
        width: 'unset',
        '&:hover': {
          textDecoration: 'underline',
          '@media (hover: none)': {
            textDecoration: 'underline',
          },
        },
      },
    },
    button: {
      color: amber[500],
      fontSize: '0.875rem',
      fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
      fontWeight: 600,
      textTransform: 'capitalize',
      '&.styled--link, &.styled--link a, & .styled--link': {
        color: grey[800],
        fontFamily: '"Helvetica Neue Regular", Helvetica, Arial, sans-serif;',
        textDecoration: 'none',
        width: 'unset',
        '&:hover': {
          textDecoration: 'underline',
          '@media (hover: none)': {
            textDecoration: 'underline',
          },
        },
      },
    },
    overline: {
      color: grey[800],
      fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
      fontSize: '0.625rem',
      textTransform: 'capitalize',
      '&.styled--link, &.styled--link a, & .styled--link': {
        color: grey[800],
        fontFamily: '"Helvetica Neue Regular", Helvetica, Arial, sans-serif;',
        textDecoration: 'none',
        width: 'unset',
        '&:hover': {
          textDecoration: 'underline',
          '@media (hover: none)': {
            textDecoration: 'underline',
          },
        },
      },
    },
  },
  components: {
    Hidden: {
      styleOverrides: {
        '&.scrollspyContainer': {
          width: 400,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            minHeight: '48px',
          },
        },
        content: {
          '&.Mui-expanded': {
            margin: defaultTheme.spacing(1.5, 0),
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: grey[100],
          color: amber[500],
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        loading: {
          color: grey[600],
        },
        noOptions: {
          color: grey[600],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
        },
        sizeLarge: {
          fontSize: '0.938',
        },
        outlinedPrimary: {
          border: '1px solid '.concat(amber[700]),
          '&:hover': {
            border: '1px solid '.concat(amber[900]),
            color: amber[900],
          },
        },
        text: {
          color: grey[800],
          // padding: '6px 8px',
        },
        textPrimary: {
          color: amber[700],
          '&:hover': {
            color: amber[900],
          },
          '&.styled--link': {
            color: amber[900],
            fontWeight: 'normal',
            height: 'unset',
            minWidth: 'unset',
            padding: 0,
            position: 'relative',
            textDecoration: 'none',
            textTransform: 'none',
            width: 'unset',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
              '@media (hover: none)': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
              },
            },
          },
        },
        textSecondary: {
          color: grey[800],
          '&.styled--link': {
            color: grey[800],
            fontWeight: 'normal',
            height: 'unset',
            minWidth: 'unset',
            padding: 0,
            position: 'relative',
            textDecoration: 'none',
            textTransform: 'none',
            width: 'unset',
            '&:hover': {
              backgroundColor: 'transparent',
              textDecoration: 'underline',
              '@media (hover: none)': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
              },
            },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          '&.card--firearm-selection': {
            boxShadow: defaultTheme.shadows[1],
            '& .MuiButtonBase-root': {
              '& .mgc-icon': {
                '& .color--primary, & .color--secondary': {
                  transition: defaultTheme.transitions.create(['fill'], {
                    easing: defaultTheme.transitions.easing.easeInOut,
                    duration: defaultTheme.transitions.duration.complex,
                  }),
                },
                '&.no-image--placeholder': {
                  fontSize: '13.813rem',
                  [defaultTheme.breakpoints.down('sm')]: {
                    fontSize: '7.813rem',
                  },
                },
              },
            },
            '&:hover': {
              boxShadow: defaultTheme.shadows[8],
              '& .MuiButtonBase-root': {
                '& .mgc-icon': {
                  '& .color--secondary': {
                    fill: amber[500],
                    transition: defaultTheme.transitions.create(['fill'], {
                      easing: defaultTheme.transitions.easing.easeInOut,
                      duration: defaultTheme.transitions.duration.complex,
                    }),
                  },
                },
              },
            },
            '&.active': {
              '& .MuiButtonBase-root': {
                '& .mgc-icon': {
                  '& .color--primary': {
                    fill: amber[700],
                    transition: defaultTheme.transitions.create(['fill'], {
                      easing: defaultTheme.transitions.easing.easeInOut,
                      duration: defaultTheme.transitions.duration.complex,
                    }),
                  },
                  '& .color--secondary': {
                    fill: amber[500],
                    transition: defaultTheme.transitions.create(['fill'], {
                      easing: defaultTheme.transitions.easing.easeInOut,
                      duration: defaultTheme.transitions.duration.complex,
                    }),
                  },
                },
              },
            },
          },
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          boxShadow: defaultTheme.shadows[8],
        },
        focusHighlight: {
          backgroundColor: 'inherit',
          // boxShadow: defaultTheme.shadows[8],
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '&.main-content': {
            flexGrow: 1,
            paddingBottom: defaultTheme.spacing(3),
            paddingTop: defaultTheme.spacing(3),
          },
          '&.split-dialog-content': {
            display: 'flex',
            height: '100%',
            padding: 0,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          body: {
            fontSize: 16,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: grey[300],
          margin: defaultTheme.spacing(2, 'auto'),
          width: '100%',
          '&.spacer': {
            backgroundColor: 'transparent',
            border: 0,
          },
          '&.half-gutter-top': {
            marginTop: 15,
          },
          '&.half-gutter-bottom': {
            marginBottom: 15,
          },
          '&.half-gutter-x': {
            marginBottom: 15,
            marginTop: 15,
          },
          '&.no-gutter-top': {
            marginTop: 0,
          },
          '&.no-gutter-bottom': {
            marginBottom: 0,
          },
          '&.no-gutter-x': {
            marginBottom: 0,
            marginTop: 0,
          },
        },
      },
    },

    MuiFab: {
      styleOverrides: {
        root: {
          zIndex: 100,
          //   borderRadius: '50%',
        },
        extended: {
          '&.extendable-fab': {
            padding: 0,
            transition: defaultTheme.transitions.create(['padding'], {
              easing: defaultTheme.transitions.easing.easeInOut,
              duration: defaultTheme.transitions.duration.standard,
            }),
            '&:hover': {
              padding: defaultTheme.spacing(2, 2),
              transition: defaultTheme.transitions.create(['padding'], {
                easing: defaultTheme.transitions.easing.easeInOut,
                duration: defaultTheme.transitions.duration.standard,
              }),
            },
            '& .MuiCollapse-root': {
              whiteSpace: 'nowrap',
            },
          },
        },
        primary: {
          color: amber[50],
          backgroundColor: amber[700],
          '&:hover': {
            backgroundColor: amber[900],
            '@media (hover: none)': {
              backgroundColor: amber[700],
            },
          },
        },
      },
    },
    // MuiFilledInput: {
    //   underline: {
    //     '&:after': {
    //       borderBottom: `2px solid ${amber[500]}`,
    //     },
    //     '&:before': {
    //       borderBottom: `1px solid ${amber[500]}`,
    //     },
    //     '&:hover:before': {
    //       borderBottom: `1px solid ${amber[500]}`,
    //     },
    //   },
    // },
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          '&.remove-gutters': {
            marginTop: 0,
            marginBottom: 0,
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          '&.content--center': {
            textAlign: 'center',
            width: '100%',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              display: 'none',
            },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {
            color: grey[700],
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: grey[600],
          '&$focused': {
            color: grey[600],
          },
        },
        focused: {},
        filled: {
          color: grey[300],
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: grey[700],
          '&.input--xlarge': {
            '& .MuiInputBase-input': {
              fontSize: '1.875rem',
              textAlign: 'center',
            },
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        button: {
          transition: defaultTheme.transitions.create(['all'], {
            easing: defaultTheme.transitions.easing.easeInOut,
            duration: defaultTheme.transitions.duration.complex,
          }),
          '&:hover': {
            color: grey[800],
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
          '&.list--bulleted': {
            fontWeight: 500,
            listStyle: 'disc',
            paddingLeft: defaultTheme.spacing(2),
            '& li': {
              display: 'list-item',
              '&[class^="MuiListSubheader"]': {
                marginLeft: defaultTheme.spacing(-2),
              },
            },
          },
          '&.list--ordered': {
            fontWeight: 500,
            listStyle: 'number',
            paddingLeft: defaultTheme.spacing(2),
            '& li': {
              display: 'list-item',
              '&[class^="MuiListSubheader"]': {
                marginLeft: defaultTheme.spacing(-2),
              },
            },
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        dense: {
          paddingBottom: defaultTheme.spacing(0),
          paddingTop: defaultTheme.spacing(0),
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
        dense: {
          fontSize: 16,
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          color: common.black,
          fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
          fontSize: 16,
          fontWeight: 500,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.component-sample': {
            margin: defaultTheme.spacing(4, 0),
            padding: defaultTheme.spacing(4),
            '& .component-sample--narrow-container': {
              width: '100%',
              margin: defaultTheme.spacing(0, 'auto'),
              maxWidth: 500,
            },
            '& .component-sample--container': {
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              margin: defaultTheme.spacing(0, 'auto'),
              '& > *': {
                margin: defaultTheme.spacing(1),
              },
              '& > svg': {
                margin: defaultTheme.spacing(2),
              },
            },
          },
          '&.codeblock--container': {
            background: 'none',
            margin: defaultTheme.spacing(4, 'auto'),
            padding: defaultTheme.spacing(0),
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&$disabled': {
            color: grey[500],
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: grey[600],
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          alignSelf: 'baseline',
          '&.mgc-icon': {
            fontSize: '3rem',
          },
        },
        fontSizeLarge: {
          '&.mgc-icon': {
            fontSize: '12.5rem',
            '&.mega': {
              fontSize: '24rem',
              [defaultTheme.breakpoints.down('md')]: {
                fontSize: '18rem',
              },
              [defaultTheme.breakpoints.down('sm')]: {
                fontSize: '21.5rem',
              },
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          '&$checked': {
            color: amber[500],
            '& + $bar': {
              backgroundColor: amber[500],
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: common.black,
          fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: amber[500],
          height: 3,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: grey[600],
          fontWeight: 400,
          opacity: 1,
          paddingTop: defaultTheme.spacing(2),
          paddingBottom: defaultTheme.spacing(2),
          '&$selected': {
            color: grey[900],
            opacity: 1,
          },
        },
        selected: {},
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.invert': {
            '& .MuiFilledInput-underline:before': {
              borderBottomColor: grey[400],
            },
            '& input.MuiInputBase-input': {
              color: grey[100],
            },
            '& .MuiInputLabel-root': {
              '&.Mui-focused': {
                color: grey[100],
              },
            },
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          margin: '0 auto',
          maxWidth: 1280,
          width: '100%',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.text--upercase': {
            textTransform: 'uppercase',
          },
        },
        paragraph: {
          '&.dense': {
            marginBottom: defaultTheme.spacing(0),
          },
          '&:last-of-type': {
            marginBottom: 0,
          },
        },
        gutterBottom: {
          marginBottom: '1em',
          '&.mega': {
            marginBottom: '0.5em',
            [defaultTheme.breakpoints.down('md')]: {
              marginBottom: '0.35em',
            },
            [defaultTheme.breakpoints.down('sm')]: {
              marginBottom: '0.15em',
            },
          },
        },
      },
    },
  },
});

export default MGCTheme;
