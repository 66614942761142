export default {
  ADMIN: {
    ADD: {
      FIREARM: {
        SINGULAR: 'Add Firearm',
        PLURAL: 'Add Firearms',
      },
      MANUFACTURER: {
        SINGULAR: 'Add Manufacturer',
        PLURAL: 'Add Manufacturers',
      },
      MODEL: {
        SINGULAR: 'Add Model',
        PLURAL: 'Add Models',
      },
      TYPE: {
        SINGULAR: 'Add Type',
        PLURAL: 'Add Types',
      },
    },
  },
  AGREE: 'Agree',
  BACK: 'Back',
  CANCEL: 'Cancel',
  CANCEL_RESET_PASSWORD: 'Cancel and Login',
  CONFIRM: 'Confirm Email',
  CONTINUE: 'Continue',
  DISAGREE: 'Disagree',
  EDIT: 'Edit',
  ENTER_VERIFICATION: 'Enter Verification Code',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  REGISTER: 'Sign Up Free',
  REGISTER_NOW: 'Register Now',
  REMOVE: 'Remove',
  RESET_PASSWORD: 'Reset your password',
  SAVE: 'Save',
  SHOP: 'Shop',
  SUBMIT: 'Submit',
  UNDO: 'Undo',
  UPLOAD: 'Upload',
  UPDATE_PASSWORD: 'Update Password',
  USER: {
    ADD_ITEM_TO_CATALOG: 'Add item to catalog',
  },
  USER_CATALOG: {
    FINISH: 'Finish',
    STEP_1_CONTINUE: 'Continue to types',
    STEP_2_CONTINUE: 'Continue to models',
    STEP_3_CONTINUE: 'Enter firearm details',
    INCOMPLETE_ENTRY_CANCEL: 'Dust in the wind',
    INCOMPLETE_ENTRY_CONTINUE: 'Continue Sir Dude!',
  },
  USER_PROFILE: 'User Profile',
};
