import React, { useEffect, useState, useContext } from 'react';
import { Auth } from '../services/api';
import { load, save } from '../utils/storage';
import { AuthStore, useStores } from '../models';

export {};
declare global {
  interface Window {
    AmazonCognitoAdvancedSecurityData: any;
  }
}

export const AuthContext = React.createContext<{
  user?: Auth | null;
  setMe: (authStore: AuthStore, email: string, password: string) => any;
  logout: (authStore: AuthStore) => void;
}>({
  user: {},
  setMe: () => {},
  logout: async () => {},
});

interface AuthProviderProps {}

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<Auth | null>();
  const { authStore } = useStores();

  useEffect(() => {
    (async () => {
      let me: Auth = await load('me');
      if (!me) {
        me = {};
      }
      setUser(me);
      if (me.email) authStore.setEmail(me.email);
      if (me.accessToken) authStore.setAccessToken(me.accessToken);
      if (me.refreshToken) authStore.setRefreshToken(me.refreshToken);
    })();
  }, [authStore]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setMe: async (authStore, email, password) => {
          const response = await authStore.signIn(email, password);

          if (response.kind !== 'ok') return response;

          const accessToken = response.session_auth.accessToken;
          const refreshToken = response.session_auth.refreshToken;
          const deviceKey = response.session_auth.deviceKey;

          authStore.setEmail(email);
          authStore.setAccessToken(accessToken);
          authStore.setRefreshToken(refreshToken);
          authStore.setDeviceKey(deviceKey);

          const me = {
            email: email,
            accessToken: accessToken,
            refreshToken: refreshToken,
            deviceKey: deviceKey,
            fingerprint: authStore.fingerprint,
          };

          setUser(authStore);

          await save('me', me);
          await authStore.environment.api.setup();

          return response;
        },
        logout: async (authStore: AuthStore) => {
          await authStore.logout();
          setUser({});
          await save('me', {});
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
