import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';

/**
 * Store for adding items to the user catalog
 */
export const SIBStoreModel = types
  .model('SIBStore', {})
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    async createSibContact(email: string) {
      return await self.environment.api.createSibContact(email);
    },
  }));

type SIBStoreType = Instance<typeof SIBStoreModel>;
export interface SIBStore extends SIBStoreType {}

type SIBStoreSnapshotType = SnapshotOut<typeof SIBStoreModel>;
export interface SIBStoreSnapshot extends SIBStoreSnapshotType {}
