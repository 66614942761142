import React, { SyntheticEvent, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Slide from '@material-ui/core/Slide';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LoadingButton from '@material-ui/lab/LoadingButton';
import DatePicker from '@material-ui/lab/DatePicker';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import ButtonsConst from '../../../constants/ButtonsConst';
import ContentConst from '../../../constants/ContentConst';
import FormsConst from '../../../constants/FormsConst';
import GeneralConst from '../../../constants/GeneralConst';
import { ComboBox, Loader } from '../../atoms';
import { ManufacturerStoreModel, useStores } from '../../../models';
import { spaceToLowdash } from '../../../helpers/Typography';
import { iconsComponent } from '../../../helpers/Icons';
import { isEmpty } from 'ramda';
import { toJS } from 'mobx';

import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import moment from 'moment';
import { replaceStringItems, restrictAlpha } from '../../../helpers/Data';
import { InputAdornment } from '@material-ui/core';

interface Props {
  handleOpen?: () => void;
  modalOpenState?: string | undefined;
  refreshUserCatalog?: () => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const useStyles = makeStyles(() => ({
  centerContent: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
  },
}));

const uuid4Regex =
  /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
let userId = null;

export default observer((props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [pendingState, setPendingState] = useState(false);
  const [savedState, setSavedState] = useState(false);
  const [types, setTypes] = useState([]);
  const [uploads, setUploads] = useState([]);
  const [pendingSave, setPendingSave] = useState(false);
  const [value, setValue] = useState('1');

  const {
    catalogStore,
    firearmCalibersStore,
    manufacturersStore,
    manufacturerModelsStore,
    manufacturerTypesStore,
    subtypesStore,
    userCatalogItemsStore,
  } = useStores();

  const handleDateChange = (dateObj: moment.Moment): void => {
    // console.log(
    //   '***',
    //   'AddItemToCatalog | handleDateChange | dateObj',
    //   dateObj
    // );

    if (dateObj) catalogStore.setPurchaseDate(dateObj.format('MM/DD/YYYY'));
  };

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    if (newValue == '2') {
      catalogStore.setCustomBuild(true);
    } else {
      catalogStore.setCustomBuild(false);
    }
    setValue(newValue);
  };

  const getRelatedTypes = useCallback(async () => {
    if (props.modalOpenState && !pendingSave) {
      const types = await manufacturerTypesStore.getManufacturerTypes(
        catalogStore.manufacturer
      );

      setTypes(types);
    }
  }, [catalogStore, manufacturerTypesStore, pendingSave, props.modalOpenState]);

  const getManufacturers = async (search: string) => {
    if (props.modalOpenState && !pendingSave) {
      return await manufacturersStore.getManufacturers(search);
    }
  };

  const getRelatedModels = async (search: String) => {
    if (props.modalOpenState && !pendingSave)
      if (uuid4Regex.test(catalogStore.manufacturer) && !userId) {
        return await manufacturerModelsStore.getManufacturerModels(
          catalogStore.manufacturer,
          catalogStore.type,
          search
        );
      }
  };

  const getRelatedSubtypes = async () => {
    if (props.modalOpenState && !pendingSave) {
      if (!catalogStore.manufacturer) return;
      if ((!uuid4Regex.test(catalogStore.manufacturer) || !uuid4Regex.test(catalogStore.model)) || userId !== null) return;

      const results =
        await manufacturerModelsStore.getManufacturerModelsByManufacturerAndFirearmType(
          catalogStore.manufacturer,
          catalogStore.type,
          catalogStore.modelName
        );
      const subtypes = results.map((manufacturerModel) => {
        return manufacturerModel.firearm_subtype;
      });
      subtypesStore.setSubtypes(subtypes);
      return subtypes;
    }
  };

  const getRelatedCalibers = async (search: String) => {
    if (props.modalOpenState && !pendingSave) {
      if (!catalogStore.type) return;

      const results = await firearmCalibersStore.getFirearmCalibers(
        catalogStore.type,
        search
      );

      return results;
    }
  };

  const handleSelectType = async (event: React.MouseEvent<HTMLDivElement>) => {
    if (props.modalOpenState && !pendingSave) {
      const clickedElement = event.currentTarget;
      const currentActive =
        document.querySelector('.card--firearm-selection.active') || null;

      if (currentActive) currentActive.classList.remove('active');

      clickedElement.classList.add('active');

      catalogStore.setType(clickedElement.dataset.typeId);
    }
  };

  const handleSetSavedState = async (_savedState: boolean) => {
    return setSavedState(_savedState);
  };

  const handlePendingState = async (_pendingState: boolean) => {
    return setPendingState(_pendingState);
  };

  const resetCatalogEntry = async (close = false) => {
    if (props.modalOpenState) {
      await handleSetSavedState(false).then(async () => {
        await handlePendingState(false).then(async () => {
          await catalogStore.resetCatalogEntry().then(() => {
            if (close) {
              props.handleOpen();
              history.push('/');
            }
          });
        });
      });
    }
  };

  const saveCatalogEntry = async (e: any, close = true) => {
    e.preventDefault();

    if (props.modalOpenState) {
      handlePendingState(true);
      setPendingSave(true);

      if (!uuid4Regex.test(catalogStore.manufacturer) || userId) {
        await manufacturersStore
          .saveUserManufacturer(catalogStore.manufacturer)
          .then((results) => {
            const id = results.manufacturer.id;
            catalogStore.setManufacturer(id);
          })
          .then(async () => {
            await subtypesStore
              .saveUserSubtype(catalogStore.subtype, catalogStore.type)
              .then((results) => {
                const id = results.firearm_subtype.id;
                catalogStore.setSubtype(id);
              })
              .then(async () => {
                await manufacturersStore
                  .saveUserManufacturerModel(
                    catalogStore.model,
                    catalogStore.type,
                    catalogStore.subtype,
                    catalogStore.manufacturer
                  )
                  .then((results) => {
                    const id = results.manufacturer_model.id;
                    catalogStore.setModel(id);
                  })
                  .then(async () => {
                    await catalogStore
                      .addToCatalog(uploads)
                      .then(async () => {
                        const results = await catalogStore.allCatalogs(1, 30);
                        userCatalogItemsStore.setUserCatalogItems([]);
                        return results;
                      })
                      .then((results) => {
                        userCatalogItemsStore.appendUserCatalogItems(
                          results.catalog_items
                        );
                      })
                      .then(async () => {
                        // Reset the state of the catalog entry
                        await resetCatalogEntry(close);
                      });
                  });
              });
          });
      } else {
        await catalogStore
          .addToCatalog(uploads)
          .then(async () => {
            const results = await catalogStore.allCatalogs(1, 30);
            userCatalogItemsStore.setUserCatalogItems([]);
            return results;
          })
          .then((results) => {
            userCatalogItemsStore.appendUserCatalogItems(results.catalog_items);
          })
          .then(async () => {
            // Reset the state of the catalog entry
            await resetCatalogEntry(close);
          });
      }
    }
  };

  const handleNextStep = async (e: any) => {
    e.preventDefault();

    if (props.modalOpenState && !pendingSave) {
      const anchor = e.currentTarget;
      const direction = anchor.getAttribute('data-incdec');

      handlePendingState(true);

      switch (catalogStore.step) {
        case 1:
          await getRelatedTypes();
          handlePendingState(false);
          break;
        case 2:
          await getRelatedModels('');
          handlePendingState(false);
          break;
        case 3:
          await getRelatedSubtypes();
          handlePendingState(false);
          break;
      }

      if (direction === 'plus') catalogStore.setStep(catalogStore.step + 1);
      if (direction === 'minus') catalogStore.setStep(catalogStore.step - 1);
    }
  };

  const handleChangeStatus = ({ meta, file }: any, status: any) => {
    if (props.modalOpenState && !pendingSave) {
      let newList;

      if (status === 'done' && !uploads.includes(file)) {
        newList = [...uploads, file];

        setUploads(newList);
      } else if (status === 'removed') {
        newList = uploads.filter((item) => item !== file);

        setUploads(newList);
      }
    }
  };

  useEffect(() => {
    if (props.modalOpenState && !pendingSave && loading) {
      (async () => {
        if (catalogStore.manufacturer) {
          handleSetSavedState(true);

          // Make sure we have the types set to be used if needed
          await getRelatedTypes();
        }

        setLoading(false);
      })();
    }
  }, [
    catalogStore,
    getRelatedTypes,
    loading,
    pendingSave,
    props.modalOpenState,
  ]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Slide direction='up' in={savedState} mountOnEnter unmountOnExit>
            <Grid
              className={classes.centerContent}
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              spacing={4}
            >
              <Grid
                alignContent='center'
                alignItems='center'
                container
                direction='row'
                item
                justifyContent='center'
              >
                {loading ? (
                  <Loader color='primary' size={200} />
                ) : (
                  <>
                    <Grid item container justifyContent='center' xs={12}>
                      <Grid item xs={12} md={8}>
                        <Typography variant='h2' align='center' gutterBottom>
                          {
                            ContentConst.USER_CATALOG.FOUND_INCOMPLETE_ENTRY
                              .HEADING
                          }
                        </Typography>

                        <Typography align='center' gutterBottom>
                          {
                            ContentConst.USER_CATALOG.FOUND_INCOMPLETE_ENTRY
                              .CONTENT_1
                          }
                        </Typography>

                        <Typography align='center' gutterBottom>
                          {
                            ContentConst.USER_CATALOG.FOUND_INCOMPLETE_ENTRY
                              .CONTENT_2
                          }
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item container justifyContent='center' xs={12}>
                      <Grid container direction='row' item xs={12} md={6}>
                        <Grid item className='content--center'>
                          <FormControl required fullWidth margin='normal'>
                            <Box>
                              <Grid
                                container
                                justifyContent='center'
                                alignItems='center'
                                spacing={4}
                              >
                                <Grid item>
                                  <Button
                                    color='primary'
                                    disableElevation
                                    onClick={() => handleSetSavedState(false)}
                                    size='large'
                                    variant='contained'
                                  >
                                    {
                                      ButtonsConst.USER_CATALOG
                                        .INCOMPLETE_ENTRY_CONTINUE
                                    }
                                  </Button>
                                </Grid>

                                <Grid item>
                                  <Button
                                    color='secondary'
                                    disableElevation
                                    disableRipple
                                    disableTouchRipple
                                    disableFocusRipple
                                    onClick={() => resetCatalogEntry()}
                                    size='small'
                                    variant='text'
                                    className='styled--link remove-transform'
                                  >
                                    {
                                      ButtonsConst.USER_CATALOG
                                        .INCOMPLETE_ENTRY_CANCEL
                                    }
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Slide>

          <Slide
            direction='up'
            in={catalogStore.step === 1 && !savedState}
            mountOnEnter
            unmountOnExit
          >
            <Grid
              className={classes.centerContent}
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              spacing={4}
            >
              <Grid
                container
                item
                direction='row'
                justifyContent='center'
                alignItems='center'
              >
                {loading ? (
                  <Loader color='primary' size={200} />
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Typography variant='h2' align='center' gutterBottom>
                        {ContentConst.USER_CATALOG.STEPS.STEP_1.HEADING}
                      </Typography>
                    </Grid>

                    <Grid item md={6} className='content--center'>
                      <form
                        id='selectManufacturer'
                        name='selectManufacturer'
                        onSubmit={handleNextStep}
                        data-incdec='plus'
                      >
                        <TabContext value={value}>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList
                              onChange={handleChange}
                              aria-label='lab API tabs example'
                            >
                              <Tab label='Standard Purchase' value='1' />
                              <Tab label='Custom Build' value='2' />
                            </TabList>
                          </Box>

                          <TabPanel value='1'>
                            <Typography align='center' variant='body1'>
                              {GeneralConst.GENERAL.STANDARD_BUILD_DESCRIPTION}
                            </Typography>

                            <FormControl required fullWidth margin='normal'>
                              <ComboBox
                                getSearch={getManufacturers}
                                handleCloseChangeBlur={() => {
                                  manufacturersStore.setManufacturers([]);
                                }}
                                handleChangeClear={() => {
                                  manufacturersStore.setManufacturers([]);
                                }}
                                value={
                                  catalogStore.manufacturer
                                    ? {
                                        id: catalogStore.manufacturer,
                                        name: catalogStore.manufacturerName,
                                      }
                                    : null
                                }
                                startingOptions={async () => {
                                  if (props.modalOpenState && !pendingSave) {
                                    if (catalogStore.manufacturer) {
                                      return [
                                        {
                                          id: catalogStore.manufacturer,
                                          name: catalogStore.manufacturerName,
                                        },
                                      ];
                                    } else if (
                                      manufacturersStore.manufacturers.length >
                                      0
                                    ) {
                                      return manufacturersStore.manufacturers;
                                    } else {
                                      return await getManufacturers('');
                                    }
                                  }
                                }}
                                handleSelectedOption={(data) => {
                                  if (
                                    data !== null &&
                                    data.user !== null &&
                                    data.user !== undefined
                                  ) {
                                    userId = data.user.id;
                                  }
                                  catalogStore.setManufacturer(
                                    data ? data.id : ''
                                  );
                                  catalogStore.setManufacturerName(
                                    data
                                      ? data.inputValue
                                        ? data.inputValue
                                        : data.name
                                      : ''
                                  );
                                }}
                                inputClass='input--xlarge'
                                textFieldPlaceholder={
                                  FormsConst.USER_CATALOG.PLACEHOLDERS
                                    .ENTER_MANUFACTURER
                                }
                              />
                            </FormControl>
                          </TabPanel>

                          <TabPanel value='2'>
                            <Typography align='center' variant='body1'>
                              {GeneralConst.GENERAL.CUSTOM_BUILD_DESCRIPTION}
                            </Typography>

                            <FormControl required fullWidth margin='normal'>
                              <ComboBox
                                getSearch={getManufacturers}
                                handleCloseChangeBlur={() => {
                                  manufacturersStore.setManufacturers([]);
                                }}
                                handleChangeClear={() => {
                                  manufacturersStore.setManufacturers([]);
                                }}
                                value={
                                  catalogStore.manufacturer
                                    ? {
                                        id: catalogStore.manufacturer,
                                        name: catalogStore.manufacturerName,
                                      }
                                    : null
                                }
                                startingOptions={async () => {
                                  if (props.modalOpenState && !pendingSave) {
                                    if (catalogStore.manufacturer) {
                                      return [
                                        {
                                          id: catalogStore.manufacturer,
                                          name: catalogStore.manufacturerName,
                                        },
                                      ];
                                    } else if (
                                      manufacturersStore.manufacturers.length >
                                      0
                                    ) {
                                      return manufacturersStore.manufacturers;
                                    } else {
                                      return await getManufacturers('');
                                    }
                                  }
                                }}
                                handleSelectedOption={(data) => {
                                  if (
                                    data !== null &&
                                    data.user !== null &&
                                    data.user !== undefined
                                  ) {
                                    userId = data.user.id;
                                  }
                                  catalogStore.setManufacturer(
                                    data ? data.id : ''
                                  );
                                  catalogStore.setManufacturerName(
                                    data
                                      ? data.inputValue
                                        ? data.inputValue
                                        : data.name
                                      : ''
                                  );
                                }}
                                inputClass='input--xlarge'
                                textFieldPlaceholder={
                                  FormsConst.USER_CATALOG.PLACEHOLDERS
                                    .ENTER_MANUFACTURER
                                }
                              />
                            </FormControl>
                          </TabPanel>
                        </TabContext>

                        <FormControl margin='normal'>
                          <LoadingButton
                            color='primary'
                            disabled={catalogStore.manufacturer ? false : true}
                            disableElevation
                            endIcon={<ArrowForwardIosIcon />}
                            loadingPosition='end'
                            loading={pendingState}
                            type='submit'
                            size='large'
                            variant='outlined'
                          >
                            {ButtonsConst.USER_CATALOG.STEP_1_CONTINUE}
                          </LoadingButton>
                        </FormControl>
                      </form>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Slide>

          <Slide
            direction='up'
            in={catalogStore.step === 2 && !savedState}
            mountOnEnter
            unmountOnExit
          >
            <Grid
              className={classes.centerContent}
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={4}
            >
              {loading ? (
                <Loader color='primary' size={200} />
              ) : (
                <>
                  <Grid item xs={12}>
                    <Typography variant='h2' align='center' gutterBottom>
                      {replaceStringItems(
                        ContentConst.USER_CATALOG.STEPS.STEP_2.HEADING,
                        [catalogStore.manufacturerName]
                      )}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    item
                    direction='row'
                    justifyContent='center'
                    spacing={4}
                    alignItems='center'
                  >
                    {types.map((type, index) => {
                      const Icon =
                        iconsComponent[
                          spaceToLowdash(type.name, 'lower') || 'Handgun'
                        ]['main'];

                      return (
                        <Grid item key={index}>
                          <Card
                            className={
                              type.id === catalogStore.type
                                ? 'card--firearm-selection active'
                                : 'card--firearm-selection'
                            }
                            elevation={8}
                            id={spaceToLowdash(type.name, 'lower')}
                            data-type-id={type.id}
                            onClick={handleSelectType}
                          >
                            <CardActionArea>
                              <CardContent>
                                <Icon className='mgc-icon' fontSize='large' />
                                <Typography
                                  variant='h4'
                                  align='center'
                                  gutterBottom
                                >
                                  {type.name}
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>

                  <Grid
                    container
                    item
                    direction='row'
                    justifyContent='center'
                    spacing={4}
                    alignItems='center'
                  >
                    <Grid item md={6} className='content--center'>
                      <form
                        id='selectType'
                        name='selectType'
                        onSubmit={handleNextStep}
                        data-incdec='plus'
                      >
                        <FormControl margin='normal'>
                          <LoadingButton
                            color='primary'
                            disabled={catalogStore.type ? false : true}
                            disableElevation
                            endIcon={<ArrowForwardIosIcon />}
                            loadingPosition='end'
                            loading={pendingState}
                            type='submit'
                            size='large'
                            variant='outlined'
                          >
                            {ButtonsConst.USER_CATALOG.STEP_2_CONTINUE}
                          </LoadingButton>
                        </FormControl>
                      </form>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Slide>

          <Slide
            direction='up'
            in={catalogStore.step === 3 && !savedState}
            mountOnEnter
            unmountOnExit
          >
            <Grid
              className={classes.centerContent}
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              spacing={4}
            >
              <Grid
                container
                item
                direction='row'
                justifyContent='center'
                alignItems='center'
              >
                {loading ? (
                  <Loader color='primary' size={200} />
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Typography variant='h2' align='center' gutterBottom>
                        {replaceStringItems(
                          ContentConst.USER_CATALOG.STEPS.STEP_3.HEADING,
                          [catalogStore.manufacturerName]
                        )}
                      </Typography>
                    </Grid>

                    <Grid item md={6} className='content--center'>
                      <form
                        id='selectModel'
                        name='selectModel'
                        onSubmit={handleNextStep}
                        data-incdec='plus'
                      >
                        <FormControl required fullWidth margin='normal'>
                          {uuid4Regex.test(catalogStore.manufacturer) &&
                          !userId ? (
                            <ComboBox
                              getSearch={getRelatedModels}
                              handleCloseChangeBlur={() => {
                                manufacturerModelsStore.setManufacturerModels(
                                  []
                                );
                              }}
                              handleChangeClear={() => {
                                manufacturerModelsStore.setManufacturerModels(
                                  []
                                );
                              }}
                              value={
                                catalogStore.model
                                  ? {
                                      id: catalogStore.model,
                                      name: catalogStore.modelName,
                                    }
                                  : null
                              }
                              startingOptions={async () => {
                                if (props.modalOpenState && !pendingSave) {
                                  if (catalogStore.model) {
                                    return [
                                      {
                                        id: catalogStore.model,
                                        name: catalogStore.modelName,
                                      },
                                    ];
                                  } else if (
                                    manufacturerModelsStore.manufacturerModels
                                      .length > 0
                                  ) {
                                    if (
                                      manufacturerModelsStore.manufacturerModels
                                        .length === 1
                                    ) {
                                      const manufacturerModel = toJS(
                                        manufacturerModelsStore.manufacturerModels
                                      )[0];
                                      catalogStore.setModel(
                                        manufacturerModel.id
                                      );
                                      catalogStore.setModelName(
                                        manufacturerModel.name
                                      );
                                    }
                                    return manufacturerModelsStore.manufacturerModels;
                                  } else {
                                    return await getRelatedModels('');
                                  }
                                }
                              }}
                              handleSelectedOption={(data) => {
                                catalogStore.setModel(data ? data.id : '');
                                catalogStore.setModelName(
                                  data
                                    ? data.inputValue
                                      ? data.inputValue
                                      : data.name
                                    : ''
                                );
                              }}
                              inputClass='input--xlarge'
                              textFieldPlaceholder='Start typing a model name'
                            />
                          ) : (
                            <TextField
                              name='model'
                              fullWidth
                              type='text'
                              label='Model Name'
                              defaultValue={catalogStore.model}
                              required
                              onChange={(e) =>
                                catalogStore.setModel(e.target.value)
                              }
                            />
                          )}
                        </FormControl>

                        <FormControl margin='normal'>
                          <LoadingButton
                            color='primary'
                            disabled={catalogStore.model ? false : true}
                            disableElevation
                            endIcon={<ArrowForwardIosIcon />}
                            loadingPosition='end'
                            loading={pendingState}
                            type='submit'
                            size='large'
                            variant='outlined'
                          >
                            {ButtonsConst.USER_CATALOG.STEP_3_CONTINUE}
                          </LoadingButton>
                        </FormControl>
                      </form>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Slide>

          <Slide
            direction='up'
            in={catalogStore.step === 4 && !savedState}
            mountOnEnter
            unmountOnExit
          >
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              spacing={4}
            >
              <Grid
                container
                item
                direction='row'
                justifyContent='center'
                alignItems='center'
              >
                {loading ? (
                  <Loader color='primary' size={200} />
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Typography variant='h2' align='center' gutterBottom>
                        {replaceStringItems(
                          ContentConst.USER_CATALOG.STEPS.STEP_4.HEADING,
                          [catalogStore.manufacturerName]
                        )}
                      </Typography>
                    </Grid>

                    <form
                      id='saveCatalog'
                      name='saveCatalog'
                      onSubmit={saveCatalogEntry}
                    >
                      <Grid
                        container
                        alignContent='center'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          md={8}
                          alignContent='center'
                          alignItems='center'
                          justifyContent='center'
                          spacing={4}
                        >
                          <Grid item md={6}>
                            <FormControl fullWidth>
                              <TextField
                                onChange={(e) =>
                                  catalogStore.setSerialNumber(e.target.value)
                                }
                                value={catalogStore.serialNumber}
                                label={GeneralConst.LABELS.SERIAL}
                                variant='standard'
                              />
                            </FormControl>
                          </Grid>

                          <Grid item md={6}>
                            {(uuid4Regex.test(catalogStore.manufacturer) && uuid4Regex.test(catalogStore.model)) &&
                            !userId ? (
                              <ComboBox
                                textFieldLabel={
                                  FormsConst.USER_CATALOG.PLACEHOLDERS
                                    .ENTER_SUBTYPE
                                }
                                handleCloseChangeBlur={() => {
                                  subtypesStore.setSubtypes([]);
                                }}
                                handleChangeClear={() => {
                                  subtypesStore.setSubtypes([]);
                                }}
                                addNotFound={false}
                                formControlClass='remove-gutters'
                                getSearch={getRelatedSubtypes}
                                value={
                                  catalogStore.subtype
                                    ? {
                                        id: catalogStore.subtype,
                                        name: catalogStore.subtypeName,
                                      }
                                    : null
                                }
                                startingOptions={async () => {
                                  if (props.modalOpenState && !pendingSave) {
                                    if (!catalogStore.manufacturer) return [];
                                    if (catalogStore.subtype) {
                                      return [
                                        {
                                          id: catalogStore.subtype,
                                          name: catalogStore.subtypeName,
                                        },
                                      ];
                                    } else if (
                                      subtypesStore.subtypes.length > 0
                                    ) {
                                      if (subtypesStore.subtypes.length === 1) {
                                        const subtype = toJS(
                                          subtypesStore.subtypes
                                        )[0];
                                        catalogStore.setSubtype(subtype.id);
                                        catalogStore.setSubtypeName(
                                          subtype.name
                                        );
                                      }
                                      return subtypesStore.subtypes;
                                    } else {
                                      return await getRelatedSubtypes();
                                    }
                                  }
                                }}
                                handleSelectedOption={(data) => {
                                  if (!data) {
                                    catalogStore.setSubtype('');
                                    catalogStore.setSubtypeName('');
                                    return;
                                  }
                                  const manufacturerModels = toJS(
                                    manufacturerModelsStore.manufacturerModels
                                  );
                                  const selectedManufacturerModel =
                                    manufacturerModels.find(
                                      (manufacturerModel) => {
                                        return data['id'] ===
                                          manufacturerModel.firearm_subtype.id
                                          ? manufacturerModel
                                          : null;
                                      }
                                    );
                                  catalogStore.setModel(
                                    selectedManufacturerModel
                                      ? selectedManufacturerModel['id']
                                      : ''
                                  );
                                  catalogStore.setModelName(
                                    selectedManufacturerModel
                                      ? selectedManufacturerModel['name']
                                      : ''
                                  );
                                  catalogStore.setSubtype(
                                    selectedManufacturerModel
                                      ? selectedManufacturerModel[
                                          'firearm_subtype'
                                        ].id
                                      : ''
                                  );
                                  catalogStore.setSubtypeName(
                                    selectedManufacturerModel
                                      ? selectedManufacturerModel[
                                          'firearm_subtype'
                                        ].name
                                      : ''
                                  );
                                }}
                              />
                            ) : (
                              <TextField
                                name='model'
                                fullWidth
                                type='text'
                                label='Model Subtype'
                                defaultValue={catalogStore.subtype}
                                variant='standard'
                                required
                                onChange={(e) =>
                                  catalogStore.setSubtype(e.target.value)
                                }
                              />
                            )}
                          </Grid>

                          <Grid item md={6}>
                            <FormControl fullWidth>
                              <DatePicker
                                label={GeneralConst.LABELS.PURCHASE_DATE}
                                value={
                                  catalogStore.purchaseDate
                                    ? catalogStore.purchaseDate
                                    : null
                                }
                                onChange={(newValue) => {
                                  if (moment(newValue).isValid())
                                    catalogStore.setPurchaseDate(
                                      moment(newValue).format('MM/DD/YYYY')
                                    );
                                }}
                                disableFuture
                                renderInput={(params) => (
                                  <TextField {...params} variant='standard' />
                                )}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item md={6}>
                            <FormControl fullWidth>
                              <InputLabel id='firearmPurchaseamount'>
                                {GeneralConst.LABELS.AMOUNT}
                              </InputLabel>
                              <Input
                                value={catalogStore.purchaseAmount}
                                onChange={(e: { target: { value: string } }) =>
                                  catalogStore.setPurchaseAmount(
                                    // parseFloat(e.target.value).toFixed(2)
                                    parseFloat(e.target.value)
                                  )
                                }
                                // variant='standard'
                                type='number'
                                startAdornment={
                                  <InputAdornment
                                    position='start'
                                    variant='standard'
                                  >
                                    $
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                          </Grid>

                          <Grid item md={6}>
                            <ComboBox
                              getSearch={getRelatedCalibers}
                              addNotFound={false}
                              handleCloseChangeBlur={() => {
                                firearmCalibersStore.setFirearmCalibers([]);
                              }}
                              handleChangeClear={() => {
                                firearmCalibersStore.setFirearmCalibers([]);
                              }}
                              textFieldLabel={GeneralConst.LABELS.CALIBER}
                              value={
                                catalogStore.caliber
                                  ? {
                                      id: catalogStore.caliber,
                                      name: catalogStore.caliberName,
                                    }
                                  : null
                              }
                              startingOptions={async () => {
                                if (props.modalOpenState && !pendingSave) {
                                  if (catalogStore.caliber) {
                                    return [
                                      {
                                        id: catalogStore.caliber,
                                        name: catalogStore.caliberName,
                                      },
                                    ];
                                  } else if (
                                    firearmCalibersStore.FirearmCalibers
                                      .length > 0
                                  ) {
                                    return firearmCalibersStore.FirearmCalibers;
                                  } else {
                                    return await getRelatedCalibers('');
                                  }
                                }
                              }}
                              handleSelectedOption={(data) => {
                                catalogStore.setCaliber(data ? data.id : '');
                                catalogStore.setCaliberName(
                                  data ? data.name : ''
                                );
                              }}
                            />
                          </Grid>

                          <Grid item md={6}>
                            <FormControl
                              fullWidth
                              variant='standard'
                              component='fieldset'
                            >
                              <InputLabel id='firearmCondition'>
                                {GeneralConst.LABELS.CONDITION}
                              </InputLabel>
                              <Select
                                labelId='firearmCondition'
                                id='demo-simple-select'
                                value={catalogStore.condition}
                                onChange={(
                                  e: React.ChangeEvent<{ value: unknown }>
                                ) =>
                                  catalogStore.setCondition(
                                    e.target.value as string
                                  )
                                }
                              >
                                <MenuItem
                                  value={GeneralConst.FIREARM_TERMS.NEW}
                                >
                                  {GeneralConst.FIREARM_TERMS.NEW}
                                </MenuItem>
                                <MenuItem
                                  value={GeneralConst.FIREARM_TERMS.EXCELLENT}
                                >
                                  {GeneralConst.FIREARM_TERMS.EXCELLENT}
                                </MenuItem>
                                <MenuItem
                                  value={GeneralConst.FIREARM_TERMS.GOOD}
                                >
                                  {GeneralConst.FIREARM_TERMS.GOOD}
                                </MenuItem>
                                <MenuItem
                                  value={GeneralConst.FIREARM_TERMS.FAIR}
                                >
                                  {GeneralConst.FIREARM_TERMS.FAIR}
                                </MenuItem>
                                <MenuItem
                                  value={GeneralConst.FIREARM_TERMS.POOR}
                                >
                                  {GeneralConst.FIREARM_TERMS.POOR}
                                </MenuItem>
                                <MenuItem
                                  value={GeneralConst.FIREARM_TERMS.DISPLAY}
                                >
                                  {GeneralConst.FIREARM_TERMS.DISPLAY}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item md={6}>
                            <FormControl fullWidth component='fieldset'>
                              <Typography>
                                {GeneralConst.LABELS.CURIOS_RELIC}
                              </Typography>

                              <RadioGroup
                                row
                                aria-label={
                                  GeneralConst.ARIA_LABELS.CURIOS_RELIC
                                }
                                name='curios_relics'
                                onChange={(e) =>
                                  catalogStore.setCurios(e.target.value)
                                }
                                value={
                                  catalogStore.curios === ''
                                    ? catalogStore.setCurios(
                                        GeneralConst.FIREARM_TERMS.NO
                                      )
                                    : catalogStore.curios
                                }
                              >
                                <FormControlLabel
                                  value={GeneralConst.FIREARM_TERMS.YES}
                                  control={<Radio color='primary' />}
                                  label={GeneralConst.FIREARM_TERMS.YES}
                                />

                                <FormControlLabel
                                  value={
                                    GeneralConst.FIREARM_TERMS.YES_NO_REPORT
                                  }
                                  control={<Radio color='primary' />}
                                  label={
                                    GeneralConst.FIREARM_TERMS.YES_NO_REPORT
                                  }
                                />

                                <FormControlLabel
                                  value={GeneralConst.FIREARM_TERMS.NO}
                                  control={<Radio color='primary' />}
                                  label={GeneralConst.FIREARM_TERMS.NO}
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>

                          <Grid item md={6}>
                            <FormControl
                              fullWidth
                              variant='standard'
                              component='fieldset'
                            >
                              <InputLabel id='firearmFinish'>
                                {GeneralConst.LABELS.FINISH}
                              </InputLabel>
                              <Select
                                labelId='firearmFinish'
                                id='firearmFinishSelect'
                                value={catalogStore.finishType}
                                onChange={(
                                  e: React.ChangeEvent<{ value: unknown }>
                                ) =>
                                  catalogStore.setFinishType(
                                    e.target.value as string
                                  )
                                }
                              >
                                <MenuItem
                                  value={GeneralConst.FIREARM_TERMS.BLUING}
                                >
                                  {GeneralConst.FIREARM_TERMS.BLUING}
                                </MenuItem>
                                <MenuItem
                                  value={GeneralConst.FIREARM_TERMS.PARKERIZING}
                                >
                                  {GeneralConst.FIREARM_TERMS.PARKERIZING}
                                </MenuItem>
                                <MenuItem
                                  value={GeneralConst.FIREARM_TERMS.ANODIZING}
                                >
                                  {GeneralConst.FIREARM_TERMS.ANODIZING}
                                </MenuItem>
                                <MenuItem
                                  value={GeneralConst.FIREARM_TERMS.CERAKOTE}
                                >
                                  {GeneralConst.FIREARM_TERMS.CERAKOTE}
                                </MenuItem>
                                <MenuItem
                                  value={
                                    GeneralConst.FIREARM_TERMS.NICKEL_BORON
                                  }
                                >
                                  {GeneralConst.FIREARM_TERMS.NICKEL_BORON}
                                </MenuItem>
                                <MenuItem
                                  value={
                                    GeneralConst.FIREARM_TERMS.NITROCARBURIZING
                                  }
                                >
                                  {GeneralConst.FIREARM_TERMS.NITROCARBURIZING}
                                </MenuItem>
                                <MenuItem
                                  value={GeneralConst.FIREARM_TERMS.QPQ}
                                >
                                  {GeneralConst.FIREARM_TERMS.QPQ}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item md={12}>
                            <FormControl fullWidth>
                              <TextField
                                id='description'
                                label={GeneralConst.LABELS.DESCRIPTION}
                                multiline
                                value={catalogStore.description}
                                onChange={(e) =>
                                  catalogStore.setDescription(e.target.value)
                                }
                                rows={4}
                                placeholder={
                                  FormsConst.USER_CATALOG.PLACEHOLDERS
                                    .ENTER_DESCRIPTION
                                }
                              />
                            </FormControl>
                          </Grid>

                          <Grid item md={12}>
                            <Dropzone
                              onChangeStatus={handleChangeStatus}
                              accept='image/*,video/*'
                              inputContent='Drag photo(s) or click to browse'
                            />
                          </Grid>
                        </Grid>

                        <Grid item md={6} className='content--center'>
                          <FormControl margin='normal'>
                            <LoadingButton
                              color='primary'
                              disabled={catalogStore.subtype ? false : true}
                              disableElevation
                              endIcon={<SaveOutlinedIcon />}
                              loadingPosition='end'
                              loading={pendingState}
                              type='submit'
                              size='large'
                              variant='outlined'
                            >
                              {ButtonsConst.USER_CATALOG.FINISH}
                            </LoadingButton>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </form>
                  </>
                )}
              </Grid>
            </Grid>
          </Slide>
        </Grid>
      </Grid>
    </>
  );
});
