import { Instance, SnapshotOut, types } from "mobx-state-tree";
import { EnvironmentExtension } from "../extensions/Environment";

/**
 * Store for ManufacturerType
 */
export const ManufacturerTypeStoreModel = types.model("ManufacturerTypeStore", {
  id: types.optional(types.string, ""),
  name: types.optional(types.string, ""),
});

/**
 * Store for ManufacturerTypes
 */
export const ManufacturerTypesStoreModel = types
  .model("ManufacturerTypesStore", {
    manufacturerTypes: types.array(ManufacturerTypeStoreModel),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setManufacturerTypes(manufacturerTypes) {
      self.manufacturerTypes = manufacturerTypes;
    },
  }))
  .actions((self) => ({
    async getManufacturerTypes(manufacturer_id: string) {
      const manufacturerTypeResponse = await self.environment.api.manufacturerTypes(
        manufacturer_id
      );

      if (
        manufacturerTypeResponse &&
        "manufacturer_types" in manufacturerTypeResponse
      ) {
        self.setManufacturerTypes(manufacturerTypeResponse.manufacturer_types);

        return manufacturerTypeResponse.manufacturer_types;
      } else {
        self.setManufacturerTypes([]);

        return [];
      }
    },
  }));

type ManufacturerTypeStoreType = Instance<typeof ManufacturerTypeStoreModel>;
export interface ManufacturerTypeStore extends ManufacturerTypeStoreType {}

type ManufacturerTypeStoreSnapshotType = SnapshotOut<
  typeof ManufacturerTypeStoreModel
>;
export interface ManufacturerTypeStoreSnapshot
  extends ManufacturerTypeStoreSnapshotType {}
