import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default (props: any) => {
  return (
    <SvgIcon {...props} viewBox='0 0 150 150'>
      <path
        className='color--primary'
        d='M60.3,67.2L60.3,67.2L60.3,67.2z M96,67.3v4.9c2.5-0.1,3-0.4,3-1h0V68C98.9,67.6,97.5,67.3,96,67.3z M17.3,76.5
        c-0.5-1-1.6-1.7-2.8-1.7c-1.7,0-3.1,1.4-3.1,3.2c0,1.8,1.4,3.2,3.1,3.2c1.6,0,2.9-1.2,3.1-2.8l1.1-0.3l-0.5-1.8L17.3,76.5z
         M99.1,71.2h9.5v-1.5h-9.5V71.2z M135,69.7v1.5h12.8c0.1-0.1,0.2-0.4,0.2-0.9c0-0.3,0-0.4-0.1-0.5H135z M114,71.2h15.6v-1.5H114
        V71.2z M80.8,65.2h0.4v-1.4h-0.4V65.2z M95.5,67.3C95.5,67.3,95.5,67.3,95.5,67.3l0.1,4.9c-2.8,0.1-38.6,0.7-41.5,0.6c0,0,0,0,0,0
        c0.1,0.1,0.2,0.1,0.2,0.2c0.4,0.4,0.6,1,0.6,1.6c0,1.1-0.6,3-4.8,3c-2.1,0-4.1-1.4-4.5-2.7c-0.3,0-1-0.1-1.8-0.1
        c-1.2,0-2.1,0.2-2.7,0.5C40,76,39.1,77,38.3,78c-0.7,0.9-1.4,1.6-2.1,1.9c-0.3,0.1-0.5,0.2-0.8,0.2c-0.6,0-1.1-0.2-1.5-0.9
        c-0.3-0.5-0.3-1.2-0.1-1.8c-2.1,0.9-4.1,1.8-5.9,2.7c-4.8,2.3-13.7,5.4-17,6.4c-2.4,0.8-6.5,2.3-8.5,2.6c0.7-1,1.9-3.1,1.9-6.6
        C4.3,79,3.4,76.6,3.2,76l5.4-1.1L8,73c8.4-1.5,21.4-3.5,22.4-3.8c2.2-0.5,3.1,0.7,4.6,0.9c1.5,0.1,4.4-1.1,9.6-2.9
        c5.2-1.8,7.4-1.7,8.2-1.7c0,0.1,0,0.3,0,0.4c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c-4.4,0.3-8.3,3-8.3,4c0,0.9,2.2,1.8,3.7,1.7
        c1.6,0,13.5-0.5,14.8-0.6c1.3-0.1,2.9-1.1,2.9-1.9c0-0.7-1.1-1.7-2.1-2H95.5z M24.9,75.9c0-0.6-0.5-1-1-1c-0.3,0-0.6,0.1-0.8,0.3
        c-0.1-0.4-0.6-0.5-0.6-0.5s-1.8,0.9-5.3,0.5c-0.7-0.7-1.7-1.1-2.8-1.1c-2.2,0-3.9,1.8-3.9,4c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1
        c0.2,0,0.4,0,0.5-0.1c0.7,1.2,2,2,3.4,2c1.6,0,3.1-1,3.6-2.5c2-2.1,5-1.8,5-1.8s0.5-0.4,0.4-0.9c0.1,0,0.2,0,0.3,0
        C24.4,76.9,24.9,76.5,24.9,75.9z M40.5,74.8c-1.3,0.4-2.7,1-4.2,1.5c0.3,0.2,0.5,0.5,0.6,0.9c0.1,0.4-0.1,0.7-0.3,1
        c-0.3,0.4-0.9,0.5-0.9,0.5c-0.2,0-0.4-0.1-0.5-0.3c0-0.2,0.1-0.4,0.3-0.5c0.1,0,0.4-0.1,0.5-0.3c0.1-0.1,0.2-0.2,0.1-0.4
        c0-0.2-0.2-0.3-0.3-0.4c-0.3-0.1-0.6-0.1-0.9,0c-0.2,0.1-0.3,0.3-0.4,0.5c-0.1,0.4-0.1,1,0.1,1.3c0.4,0.7,1.2,0.3,1.3,0.3
        c0.5-0.2,1.1-0.9,1.8-1.7C38.5,76.5,39.4,75.5,40.5,74.8z M45.5,73.5c-0.5,0.1-1,0.2-1.5,0.3c-0.4,0.1-0.7,0.2-1.1,0.3
        c0.3,0,0.6,0,0.9-0.1c0.7,0,1.3,0,1.7,0.1C45.5,73.9,45.5,73.7,45.5,73.5z M53.8,73.5c-0.4-0.4-1.2-0.7-2.2-0.7
        c-1.6,0-3.2,0.2-5.3,0.6c0,0.3-0.1,0.6,0,1.1c0,0,0,0,0,0.1c0.1,0.8,1.8,2.3,3.9,2.3c1.5,0,4.1-0.3,4.1-2.3
        C54.2,74.1,54.1,73.8,53.8,73.5z M52.7,60.9c0.3,0,0.4,0,0.5,0.1c0.4,0.2,0.4,1.1,0.4,1.8c0,0.3,0,0.5,0,0.7l0,0
        c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.2-0.1c0.2-0.1,0.5-0.1,0.7-0.1c0.7,0,1.9,0.7,2.2,1.6l-0.1,0.1
        c0.3,0,0.6,0.2,0.9,0.4h89.8c0.1,0,0.4,0.2,0.5,0.9H54.7c0-0.4,0.1-0.7,0.1-0.9h1.2c0,0,0,0,0,0c-0.3-0.3-0.5-0.6-0.9-0.6
        c-0.1,0-0.3,0-0.4,0.7c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.1c0,0.2,0,0.4-0.1,0.7c0,0.4,0,1,0,1.6
        c0,2-1.8,2.2-2.4,2.2c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5c0.2,0,0.4,0,0.6,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1
        c0.2-0.2,0.2-0.8,0.2-1.6c0-0.1,0-0.3,0-0.4c0-0.2,0-0.4,0-0.5c0-0.1,0.1-0.1,0.1-0.1c-0.2-0.1-0.4-0.3-0.5-0.8c0-0.3,0-0.6,0.1-1
        c0-0.2,0.1-0.5,0.1-0.7C52.8,61.6,52.8,61.2,52.7,60.9z M52.9,68.8c0-0.2,0-0.3-0.1-0.5l-0.1-0.1l0,0l0,0L52.5,68
        c-0.1-0.1-0.3-0.1-0.5-0.1c-0.5,0-0.9,0.4-0.9,0.9c0,0.2,0,0.3,0.1,0.5l0.1,0.1l0,0l0,0l0.1,0.1c0.1,0.1,0.3,0.1,0.5,0.1
        C52.5,69.6,52.9,69.3,52.9,68.8z M54.7,66.6C54.7,66.6,54.7,66.6,54.7,66.6L54.7,66.6L54.7,66.6z M59.3,66.7h-3.7
        C56.7,67.5,58.3,67.5,59.3,66.7z M99.1,68v0.3h49.4c0.1-0.2,0.1-0.4,0.1-0.7H98.9C99.1,67.8,99.1,67.9,99.1,68z'
      />
      <path
        className='color--secondary'
        d='M108.9,71.4h4.8v-1.8h-4.8V71.4z M129.9,71.4h4.8v-1.8h-4.8V71.4z M142,64.2v1h3.8c-0.2-0.6-0.8-1-1.4-1H142z
        M99.1,69.3h48.8c0.1-0.1,0.3-0.4,0.5-0.7H99.1V69.3z M80.5,64.3c-0.3,0.2-0.6,0.5-0.7,0.8h0.7V64.3z M89.7,65.2
       c-0.2-0.6-0.8-1-1.4-1h-2.4h-4.3v1h4.3H89.7z M49.3,68.2c-0.2,0-0.3,0.1-0.3,0.3s0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3
       S49.5,68.2,49.3,68.2z M58.8,68.3c-0.2,0-0.3,0.1-0.3,0.3c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3C59.1,68.4,59,68.3,58.8,68.3
       z M52,68.1c0.1,0,0.3,0,0.4,0.1l-0.9,0.9c-0.1-0.1-0.1-0.2-0.1-0.4C51.3,68.4,51.6,68.1,52,68.1z M62.7,68.5c-0.2,0-0.3,0.1-0.3,0.3
       c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3C63,68.7,62.9,68.5,62.7,68.5z M52.6,68.4c0.1,0.1,0.1,0.2,0.1,0.4
       c0,0.4-0.3,0.7-0.7,0.7c-0.1,0-0.3,0-0.4-0.1L52.6,68.4z M49.7,69.6c-0.2,0-0.3,0.1-0.3,0.3c0,0.2,0.1,0.3,0.3,0.3s0.3-0.1,0.3-0.3
       C50,69.7,49.9,69.6,49.7,69.6z M52.9,66.4c0,0.4,0,0.8-0.1,0.9c0,0,0,0,0,0c0,0,0,0,0,0l0,0c-0.2-0.1-0.4-0.1-0.7-0.1
       c-0.9,0-1.7,0.8-1.7,1.7s0.8,1.7,1.7,1.7c1.3,0,2.6-0.6,2.6-2.4c0-0.6,0-1.1,0-1.6v0h0.5c0.6,0.6,1.5,0.9,2.3,0.9
       c0.8,0,1.7-0.3,2.3-0.9h88.9c0,0.2,0,0.4,0,0.6c0,0,0,0.1,0,0.1H98.3c-0.8-0.2-2.1-0.3-2.8-0.3l-35.3,0v0.6h2.3v0
       c0.7,0,1.3,0.6,1.3,1.4c0,0.7-0.6,1.3-1.3,1.3v0c0,0-12.8,0.8-14,0.9c-1.2,0.1-2.3-0.1-2.3-1.3c0-1,1.4-2,3.2-2.8
       C50.6,66.8,51.9,66.5,52.9,66.4z M50.6,73.1c0,0-1.2,1.8,1.2,2.5c-1.8,0.5-2.8-1.2-2.3-2.4L50.6,73.1z M47.9,73.4
       c0,0-1.2,1.8,1.2,2.5c-1.8,0.5-2.8-1.2-2.3-2.4L47.9,73.4z M23.8,75.2c0.1,0,0.3,0,0.4,0.1l-0.9,0.9c-0.1-0.1-0.1-0.2-0.1-0.4
       C23.2,75.5,23.5,75.2,23.8,75.2z M24.4,75.6c0.1,0.1,0.1,0.2,0.1,0.4c0,0.4-0.3,0.7-0.7,0.7c-0.1,0-0.3,0-0.4-0.1L24.4,75.6z
        M21.7,75.9c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5C21.2,76.1,21.4,75.9,21.7,75.9z M10.5,78.4
       c0.1,0,0.3,0,0.4,0.1l-0.9,0.9c-0.1-0.1-0.1-0.2-0.1-0.4C9.8,78.7,10.1,78.4,10.5,78.4z M11.1,78.7c0.1,0.1,0.1,0.2,0.1,0.4
       c0,0.4-0.3,0.7-0.7,0.7c-0.1,0-0.3,0-0.4-0.1L11.1,78.7z M7.8,73.1l0.5,1.5l-5.5,1.1l0.1,0.2c0,0,1.2,2.6,1.1,6.5
       c0,2.6-0.7,4.4-1.3,5.5C2.4,88.4,2.2,88.8,2,89c-0.5,0-0.8,0-0.7-0.3C1.3,87.7,3,86.2,3,81.5c0-4.1-1.7-6.1-2-6.9
       C0.9,74.4,3.8,73.8,7.8,73.1z'
      />
    </SvgIcon>
  );
};
