import { grey } from '@material-ui/core/colors';
import * as MGCIcon from '../components/atoms/icons'; // importing all MGC Icons, so call them with something like <MGCIcon.SemiAuto />

/*
 * Contents:
 *
 * iconContainerProps
 */

export const iconContainerProps = {
  sx: {
    backgroundColor: grey[300],
    borderRadius: '50%',
    height: 300,
    margin: '40px auto',
    padding: '4rem',
    lineHeight: '1rem',
    width: 300,
  },
};

export const iconsComponent = {
  airgun: {
    main: MGCIcon.AirHandgun,
    subtypes: {
      pistol: MGCIcon.AirHandgun,
      rifle: MGCIcon.AirHandgun,
    },
  },
  automatic: {
    main: MGCIcon.FullAutoRifle,
    subtypes: {
      assault_rifle: MGCIcon.FullAutoRifle,
      machine_gun: MGCIcon.FullAutoRifle,
      pistol: MGCIcon.FullAutoRifle,
      shotgun: MGCIcon.FullAutoRifle,
      sub_machine_gun: MGCIcon.FullAutoRifle,
    },
  },
  black_powder: {
    main: MGCIcon.BlackPowderRifle,
    subtypes: {
      cannon: MGCIcon.BlackPowderRifle,
      pistol: MGCIcon.BlackPowderRifle,
      revolver: MGCIcon.BlackPowderRifle,
      rifle: MGCIcon.BlackPowderRifle,
      shotgun: MGCIcon.BlackPowderRifle,
    },
  },
  handgun: {
    main: MGCIcon.SemiAutoHandgun,
    subtypes: {
      semi_automatic: MGCIcon.SemiAutoHandgun,
      pepperbox: MGCIcon.SemiAutoHandgun,
      revolver: MGCIcon.RevolverHandgun,
      single_shot: MGCIcon.SemiAutoHandgun,
    },
  },
  rifle: {
    main: MGCIcon.SemiAutoRifle,
    subtypes: {
      bolt_action: MGCIcon.BoltActionRifle,
      combination_gun: MGCIcon.SemiAutoRifle,
      double_gun: MGCIcon.SemiAutoRifle,
      drillings: MGCIcon.SemiAutoRifle,
      laser: MGCIcon.SemiAutoRifle,
      lever_action: MGCIcon.SemiAutoRifle,
      semi_automatic: MGCIcon.SemiAutoRifle,
      single_shot: MGCIcon.SemiAutoRifle,
      slide_pump_action: MGCIcon.SemiAutoRifle,
    },
  },
  shotgun: {
    main: MGCIcon.PumpShotgun,
    subtypes: {
      bolt_action: MGCIcon.PumpShotgun,
      combination_gun: MGCIcon.PumpShotgun,
      lever_action: MGCIcon.PumpShotgun,
      slide_pump_action: MGCIcon.PumpShotgun,
      drillings: MGCIcon.PumpShotgun,
      over_under: MGCIcon.PumpShotgun,
      semi_automatic: MGCIcon.PumpShotgun,
      side_by_side: MGCIcon.PumpShotgun,
      single_shot: MGCIcon.PumpShotgun,
    },
  },
};
