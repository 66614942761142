import { useEffect, useState } from 'react';

export function useRunAsEffect(callback: Function) {
  const [call, setCall] = useState(false);
  useEffect(() => {
    if (call) {
      callback();
      setCall(false);
    }
  }, [call, callback, setCall]);
  return () => setCall(true);
}
