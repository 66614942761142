export default {
  COOKIE_BANNER: {
    CONTENT:
      'We use our own, and third-party, cookies to personalize content and to analyze web traffix.',
    LINK: {
      TEXT: 'Read more about cookies',
      URL: '/policies/cookies',
    },
  },
  FAQ: {
    ACCORDION: {
      SECTION: 'Data',
      CONTENT: [
        {
          HEADING: 'Where is my data stored?',
          DATA: 'Amazon Web Services (AWS) and your device.',
        },
        {
          HEADING: 'If I delete my account does it delete all my data?',
          DATA: 'Yes if you indicate your preference in the account deletion screen. But don’t come crying to us if you want to rejoin and expect to see your old data.',
        },
        {
          HEADING:
            'Can the government use my gun catalog data to take my guns away?',
          DATA: 'No, with one exception: if we receive a lawful subpoena, we are lawfully compelled to divulge your account information. This is another reason that we suggest using a VPN in conjunction with private and secure email, such as protonmail.com. This is why we also use military-grade encryption.',
        },
        {
          HEADING: 'Is my data secure?',
          DATA: 'Yes, we use military-grade encryption methods. With this level of security, you won’t be able to use our app on old 32-bit devices. With that said, no level of security can protect you if you get socially engineered or someone guesses or otherwise obtains your password and 2FA device code.',
        },
        {
          HEADING: 'Can I signup anonymously?',
          DATA: {
            CONTENT:
              'Yes, this can be done by obscuring your personal information during account signup.',
            LIST: [
              'Use Brave Browser. Use Brave with Tor if possible.',
              'Create a free obscure email via Protonmail. Use this new email to signup for MyGunCatalog.',
              "Don't use your real name.",
              'Leverage a VPN if possible.',
              "Don't reuse the same password, ever!",
            ],
          },
        },
        {
          HEADING: 'Do you sell my data?',
          DATA: 'No, but if you click any of our links to buy accessories for your gun, the manufacturer will know we referred you. This is the only way we can keep the app free and the dogs fed.',
        },
      ],
      SECTION2: 'Other',
      CONTENT2: [
        {
          HEADING: "Why can't I edit my catalog item(s)?",
          DATA: 'As of right now, there are a couple of known issues with editing. We have built a very robust system that has many relations from the manufacturer, all the way down to the types and models associated with the manufacturer. To properly edit an item, some gnarly magic needs to happen behind the scenes. The feature will be available shortly. For right now, you can leave the item and plan to make edits shortly, or you can remove and re-add the item to your catalog with the corrected information.',
        },
        {
          HEADING: "What do I do if there is a manufacturer that isn't listed?",
          DATA: 'If the manufacturer happens to not be listed, simply type the name of the manufacturer in the input, and then click "Add \'Manufacturer Name\'" from the dropdown. This will add the specified manufacturer to your account list for future use and will notify us that we are missing the specified manufacturer in our system.',
        },
        {
          HEADING:
            'The type/model is not listed for the manufacturer that I have selected.',
          DATA: 'This is an unfortunate and rare situation, but nevertheless something that you might experience. We have added a task to give the ability to create a type/model for an existing manufacturer and will be available shortly.',
        },
        {
          HEADING:
            'Does the app run in the background and run down my battery?',
          DATA: 'No. My Gun Catalog is a web app, which means it only uses power when visible in your web browser.',
        },
      ],
    },
  },
  HOME: {
    HERO: {
      FORM: {
        CALL_TO_ACTION:
          'We are developing new technology that will forever change the way you buy, sell, and collect firearms.',
      },
      HEADLINE: 'Gun Owners and Collectors',
      HEADLINE_SPAN: 'The Free Inventory App that',
      HEADLINE_META: 'Have Been Waiting For',
    },
    SECTION_1: {
      CONTENT:
        'Most gun collectors dread organizing and updating spreadsheets and notebooks that can get lost or stolen. Now you can use gun collection software to immediately see what you have in every sortable category you can imagine. Sort by manufacturer, type, caliber, barrel length, twist rate, and more.',
      HEADING: 'Gun Collection Software',
      SUB_HEADING: 'Retire your spreadsheets and receipts.',
    },
    SECTION_2: {
      CONTENT: [
        { LIST_ITEM: 'Personal firearm tracking and management' },
        { LIST_ITEM: 'Never forget upgrade details' },
        // { LIST_ITEM: 'Statistics about your personal catalog' },
        // {
        //   LIST_ITEM:
        //     'Print your catalog inventory. Print just one entry or all entries.',
        // },
        { LIST_ITEM: 'Access your account on any device, anywhere' },
        { LIST_ITEM: 'No limit on firearms catalogued' },
      ],
      HEADING: 'Powerful Features',
      SUB_HEADING:
        'Anonymize your identity with end-to-end, military-grade encryption',
    },
    SECTION_BOTTOM: {
      CALL_TO_ACTION: 'What are you waiting for? Sign up today!',
    },
  },
  LOGIN_MODAL: {
    HEADING: 'Sign in to your catalog.',
    SUB_HEADING: 'Enter your details below.',
  },
  SIGN_UP_MODAL: {
    HEADING: 'Get Started',
    SUB_HEADING: 'Secure. Free. Private.',
  },
  MANUFACTURER: {
    MANUFACTURER: 'Manufacturer',
    MANUFACTURERS: 'Manufacturers',
    NO_MANUFACTURER: 'No manufacturers',
    DELETE_CONFIRMATION: 'Are you sure you want to delete this manufacturer?',
  },
  USER_CATALOG: {
    EMPTY: {
      HEADING: 'There is nothing in your catalog.',
      SUB_HEADING: {
        PRE: 'Add a firearm to your catalog now.',
        POST: '',
      },
    },
    EMPTY_TYPE: {
      HEADING: 'Oh Snap!',
      SUB_HEADING: 'You do not currently have any %1 in your catalog.',
    },
    STEPS: {
      STEP_1: {
        HEADING: 'Add a firearm to your catalog',
      },
      STEP_2: {
        HEADING: 'Select a type for your %1 firearm',
      },
      STEP_3: {
        HEADING: 'Enter your %1 model',
      },
      STEP_4: {
        HEADING: 'Enter the details for your %1 firearm',
      },
    },
    FOUND_INCOMPLETE_ENTRY: {
      HEADING: 'For Whom We Have Traveled Through Time',
      CONTENT_1:
        'It looks like you were embarking on a most excellent adventure when you must have been interrupted by an Iron Maiden.',
      CONTENT_2:
        'Do you wish to continue and have a most triumphant time, or is this entry just dust in the wind?',
    },
    REMOVE_CATALOG_ITEM: {
      ALERT: {
        HEADLINE: 'Whoa there Wyld Stallyn!',
        CONTENT:
          'You are about to remove a most excellent item from your catalog. Are you sure that you want to do this?',
      },
    },
  },
};
