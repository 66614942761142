import { Instance, SnapshotOut, types } from "mobx-state-tree";
import { EnvironmentExtension } from "../extensions/Environment";

/**
 * Store for FirearmCaliber
 */
export const FirearmCaliberStoreModel = types.model("FirearmCaliberStore", {
  id: types.optional(types.string, ""),
  name: types.optional(types.string, ""),
});

/**
 * Store for FirearmCalibers
 */
export const FirearmCalibersStoreModel = types
  .model("FirearmCalibersStore", {
    FirearmCalibers: types.array(FirearmCaliberStoreModel),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setFirearmCalibers(FirearmCalibers) {
      self.FirearmCalibers = FirearmCalibers;
    },
  }))
  .actions((self) => ({
    async getFirearmCalibers(firearm_type_id: String, search: String) {
      const FirearmCaliberResponse = await self.environment.api.firearmCalibers(
        firearm_type_id,
        search
      );

      if (
        FirearmCaliberResponse &&
        "firearm_calibers" in FirearmCaliberResponse
      ) {
        self.setFirearmCalibers(FirearmCaliberResponse.firearm_calibers);

        return FirearmCaliberResponse.firearm_calibers;
      } else {
        self.setFirearmCalibers([]);

        return [];
      }
    },
  }));

type FirearmCaliberStoreType = Instance<typeof FirearmCaliberStoreModel>;
export interface FirearmCaliberStore extends FirearmCaliberStoreType {}

type FirearmCaliberStoreSnapshotType = SnapshotOut<
  typeof FirearmCaliberStoreModel
>;
export interface FirearmCaliberStoreSnapshot
  extends FirearmCaliberStoreSnapshotType {}
