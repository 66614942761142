import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  Divider,
  Grid,
  Hidden,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import moment from 'moment';
import GeneralConst from '../../../constants/GeneralConst';
import { replaceStringItems } from '../../../helpers/Data';
import { formatRegAndTrade } from '../../../helpers/Typography';
import { WrapWithLink } from '../../../hoc';
import { AuthContext } from '../../../providers/Auth';
import { isEmpty } from 'ramda';

interface Props {
  title?: string | undefined;
  variation?: 'full' | undefined;
}

export default (props: Props) => {
  const authContext = useContext(AuthContext);
  const { title, variation } = props;

  const FooterFullContent = () => {
    return (
      <Box
        sx={{
          borderBottomColor: grey[300],
          borderBottomStyle: 'solid',
          borderBottomWidth: 1,
          marginBottom: 2,
          paddingBottom: 4,
          paddingTop: 2,
        }}
        id='footerFullContent'
      >
        <Container maxWidth='lg' disableGutters>
          {title && (
            <Grid container justifyContent='center'>
              <Grid item>
                <Typography variant='h3' gutterBottom>
                  footer heading
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid container alignItems='flex-start'>
            <Grid item sm={4}>
              <Typography variant='h4' gutterBottom>
                Find us on Social Media
              </Typography>

              <List dense disablePadding>
                <ListItem disableGutters>
                  <Typography component='span'>
                    <WrapWithLink
                      link='https://www.facebook.com/MyGunCatalog/'
                      internal={false}
                      className='styled--link'
                      color='secondary'
                    >
                      Facebook
                    </WrapWithLink>
                  </Typography>
                </ListItem>

                <ListItem disableGutters>
                  <Typography component='span'>
                    <WrapWithLink
                      link='https://www.instagram.com/myguncatalog/'
                      internal={false}
                      className='styled--link'
                      color='secondary'
                    >
                      Instagram
                    </WrapWithLink>
                  </Typography>
                </ListItem>
              </List>

              <Hidden smUp>
                <Divider className='spacer' />
              </Hidden>
            </Grid>

            <Grid item sm={4}>
              <Typography variant='h4' gutterBottom>
                Learn More
              </Typography>

              <List dense disablePadding>
                <ListItem disableGutters>
                  <Typography component='span'>
                    <WrapWithLink
                      link='/faq'
                      className='styled--link'
                      color='secondary'
                    >
                      Frequently Asked Questions
                    </WrapWithLink>
                  </Typography>
                </ListItem>
                <ListItem disableGutters>
                  <Typography component='span'>
                    <WrapWithLink
                      link='/policies'
                      className='styled--link'
                      color='secondary'
                    >
                      Policies
                    </WrapWithLink>
                  </Typography>
                </ListItem>
                {/* <ListItem disableGutters>
                    <Typography component='span'>
                      <WrapWithLink
                        link='#'
                        className='styled--link'
                        color='secondary'
                      >
                        Contact Us
                      </WrapWithLink>
                    </Typography>
                  </ListItem> */}
              </List>

              <Hidden smUp>
                <Divider className='spacer' />
              </Hidden>
            </Grid>

            <Grid item sm={4}>
              <Typography variant='h4' gutterBottom>
                Patriotic Gear
              </Typography>

              <List dense disablePadding>
                <ListItem disableGutters>
                  <Typography component='span'>
                    <WrapWithLink
                      link='https://shop.myguncatalog.com'
                      className='styled--link'
                      color='secondary'
                      target='_self'
                      internal={false}
                    >
                      {GeneralConst.GENERAL.SITE_TITLE} Shop
                    </WrapWithLink>
                  </Typography>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  };

  const FooterBannerContent = () => (
    <Grid container alignItems='center' justifyContent='center'>
      <Grid item>
        <Typography variant='caption'>
          {formatRegAndTrade(
            replaceStringItems(GeneralConst.GENERAL.COPYRIGHT, [
              moment().format('YYYY'),
              GeneralConst.GENERAL.SITE_TITLE,
            ])
          )}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'secondary.contrastText',
          borderTopColor: grey[300],
          borderTopStyle: 'solid',
          borderTopWidth: 1,
          paddingBottom: 2,
          paddingTop: 2,
        }}
        id='footer'
      >
        <Container maxWidth='lg'>
          {/* {isEmpty(authContext.user) && */}
          {FooterFullContent()}

          {FooterBannerContent()}
        </Container>
      </Box>
    </>
  );
};
