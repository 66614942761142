import React from 'react';
import { Route } from 'react-router-dom';
import Container from '@material-ui/core/Container';

export default (props: any) => {
  return (
    <Container className='main-content' disableGutters={true} maxWidth={false}>
      <Route {...props} />
    </Container>
  );
};
