import React, { useContext, useState, useEffect, useCallback } from 'react';
import { AuthContext } from '../../../providers/Auth';
import { useHistory } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import Cropper from 'react-easy-crop';
import { Point } from 'react-easy-crop/types';
import { useStores } from '../../../models';
import { ImageUpload } from '../../molecules';
import { getPhoto } from '../../../helpers/Photo';
import GeneralConst from '../../../constants/GeneralConst';
import ButtonsConst from '../../../constants/ButtonsConst';
import { SecondaryHeader } from '../../organisms';
import { observer } from 'mobx-react-lite';
import LoadingButton from '@material-ui/lab/LoadingButton';
import { Alert, Snackbar } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  cropperContainer: {
    height: 300,
    position: 'relative',
    width: 300,
  },
  cropperOverride: {
    boxShadow: 'none',
  },
  formActions: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightRegular,
  },
  slider: {
    padding: theme.spacing(22, 0),
  },
  profilePhoto: {
    borderRadius: '50%',
  },
  controls: {},
}));

export default observer(() => {
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const history = useHistory();

  const [pendingState, setPendingState] = useState(false);
  const [pendingStatePassword, setPendingStatePassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [image, setImage] = useState<string>('');
  const [file, setFile] = useState<any>(null);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>({});
  const { authStore } = useStores();
  const [errors, setErrors] = useState<string>();

  const [value, setValue] = useState<number>(0);

  const getMe = useCallback(async () => {
    const results = await authStore.getMe();
    if (
      results?.user?.profile_picture !== null &&
      results?.user?.profile_picture !== undefined
    ) {
      authStore.setProfilePic(results.user.profile_picture);
    }

    return results.user;
  }, [authStore]);

  useEffect(() => {
    (async () => {
      await getMe();
    })();
  }, [getMe]);

  const handlePendingState = async (_pendingState: boolean) => {
    return setPendingState(_pendingState);
  };

  const handleChange = (event: any, newValue: number | number[]) => {
    setValue(newValue as number);
    onZoomChange(newValue as number);
  };

  const handleSave = async () => {
    handlePendingState(true);

    const metaData = {
      crop: {
        width: Math.round(croppedAreaPixels.width),
        height: Math.round(croppedAreaPixels.height),
        top: Math.round(croppedAreaPixels.y),
        left: Math.round(croppedAreaPixels.x),
      },
    };

    const user = await authStore.changeProfilePic(file, metaData);

    if (user.kind === 'ok' && 'profile_picture' in user.user) {
      await handlePendingState(false).then(() => {
        authStore.setProfilePic(user.user.profile_picture);
      });

      setImage('');
    }
  };

  const handleUpdatePassword = async () => {
    setPendingStatePassword(true);

    if (currentPassword === newPassword) {
      setErrors(
        "Your new password can't be the same as your current password."
      );
      setPendingStatePassword(false);
      return false;
    }

    if (newPassword !== confirmPassword) {
      setErrors('Your new password and confirm password must match.');
      setPendingStatePassword(false);
      return false;
    }

    if (!errors) {
      const result = await authStore.changePassword(
        currentPassword,
        newPassword
      );

      // console.log('***', 'Profile | handleUpdatePassword | result', result);

      if (result.kind === 'ok') {
        setPendingStatePassword(false);
        authContext.logout(authStore);
        history.push('/');
      }
    }
  };

  const onCropChange = (crop: Point) => {
    setCrop(crop);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onZoomChange = (zoom: number) => {
    if (zoom < 1) {
      setZoom(1);
    } else {
      setZoom(zoom);
    }
  };

  const changeFile = (e: any) => {
    e.preventDefault();
    const input: any = document.querySelector('input[type=file]');
    const file = input.files[0];
    setFile(file);
    const reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      setImage(reader.result);
    };
  };

  const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrors(null);
  };

  return (
    <>
      <SecondaryHeader>
        <Grid item xs={12}>
          <Typography variant='h2' gutterBottom>
            {GeneralConst.GENERAL.PROFILE}
          </Typography>
        </Grid>
      </SecondaryHeader>

      <Container maxWidth='lg'>
        <Grid
          alignItems='center'
          container
          direction='row'
          item
          justifyContent='center'
          spacing={10}
        >
          <Grid item container xs={12} sm={6}>
            <Grid item xs={3}>
              <img
                src={getPhoto(authStore.profile_pic, {
                  height: 100,
                  width: 100,
                })}
                className={classes.profilePhoto}
              />
            </Grid>

            <Grid item xs={12} sm={9}>
              <Typography variant='subtitle2' gutterBottom>
                {GeneralConst.GENERAL.AVATAR}
              </Typography>

              <ImageUpload changeFile={(event: any) => changeFile(event)} />
            </Grid>
          </Grid>
        </Grid>

        {image && (
          <>
            <Grid
              alignItems='center'
              container
              direction='row'
              item
              justifyContent='center'
              spacing={10}
              id='imageCropper'
            >
              <Grid item xs={12} sm={6}>
                <Box className={classes.cropperContainer}>
                  <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={4 / 4}
                    cropShape='round'
                    onCropChange={onCropChange}
                    onCropComplete={onCropComplete}
                    onZoomChange={onZoomChange}
                    zoomWithScroll={false}
                    onMediaLoaded={(mediaSize) => {
                      onZoomChange(300 / mediaSize.naturalHeight);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid
              alignItems='center'
              container
              direction='row'
              item
              justifyContent='center'
              spacing={10}
            >
              <Grid item xs={12} sm={6}>
                <Box className={classes.controls}>
                  <Slider
                    value={value}
                    onChange={handleChange}
                    aria-labelledby='imageCropper'
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box className={classes.formActions}>
                <Button variant='outlined'>{ButtonsConst.CANCEL}</Button>
                <LoadingButton
                  onClick={handleSave}
                  variant='contained'
                  loading={pendingState}
                >
                  {ButtonsConst.SAVE}
                </LoadingButton>
              </Box>
            </Grid>
          </>
        )}

        <Grid
          container
          justifyContent='center'
          alignContent='center'
          spacing={4}
        >
          <Grid
            container
            item
            justifyContent='center'
            alignContent='center'
            xs={12}
            sm={6}
          >
            <Grid item xs={12}>
              <FormControl required fullWidth margin='normal'>
                <TextField
                  name='email'
                  fullWidth
                  type='email'
                  disabled
                  label={GeneralConst.GENERAL.EMAIL}
                  value={authStore.email}
                  variant='standard'
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Box>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                  >
                    <Typography className={classes.heading}>
                      {GeneralConst.GENERAL.CHANGE_PASSWORD}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid
                      container
                      item
                      justifyContent='center'
                      alignContent='center'
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <FormControl required fullWidth margin='normal'>
                          <TextField
                            name='password'
                            fullWidth
                            type='password'
                            variant='standard'
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            label={GeneralConst.GENERAL.CURRENT_PASSWORD}
                          />
                        </FormControl>

                        <FormControl required fullWidth margin='normal'>
                          <TextField
                            name='password'
                            fullWidth
                            type='password'
                            variant='standard'
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            label={GeneralConst.GENERAL.NEW_PASSWORD}
                          />
                        </FormControl>

                        <FormControl required fullWidth margin='normal'>
                          <TextField
                            name='password'
                            fullWidth
                            type='password'
                            variant='standard'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            label={GeneralConst.GENERAL.CONFIRM_PASSWORD}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl>
                          <LoadingButton
                            color='primary'
                            disabled={
                              !currentPassword ||
                              !newPassword ||
                              !confirmPassword
                            }
                            loading={pendingStatePassword}
                            type='submit'
                            variant='contained'
                            onClick={() => handleUpdatePassword()}
                          >
                            {ButtonsConst.UPDATE_PASSWORD}
                          </LoadingButton>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {errors && (
                      <Snackbar
                        open={errors ? true : false}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        autoHideDuration={6000}
                        onClose={handleCloseAlert}
                      >
                        <Alert
                          onClose={handleCloseAlert}
                          severity='error'
                          variant='filled'
                        >
                          {errors}
                        </Alert>
                      </Snackbar>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
});
