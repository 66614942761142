import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { HelpOutlineOutlined } from '@material-ui/icons';
// import ContentConst from '../../../constants/ContentConst';
// import ButtonsConst from '../../../constants/ButtonsConst';

interface Props {
  children?: React.ReactElement | undefined;
}

export default (props: Props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <IconButton aria-label='help' onClick={handleOpen} size='small'>
        <HelpOutlineOutlined />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleOpen}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Why are only certain things editable?
        </DialogTitle>
        <DialogContent>
          <DialogContentText color='secondary'>
            When managing complex sets of data, we can only allow certain things
            to be edited.
          </DialogContentText>
          <DialogContentText color='secondary'>
            Manufacturers, models, types, and subtypes are all associated. If
            you need to edit any of these things, please remove the item from
            your catalog and re-add it.
          </DialogContentText>
          <DialogContentText color='secondary'>
            We are working on improving this experience in the near future. Just
            hold tight while we make changes.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOpen} variant='contained'>
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
