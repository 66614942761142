import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';

export default () => {
  return (
    <>
      <Grid container>
        <Typography variant='h1'>Not Found</Typography>
      </Grid>
    </>
  );
};
