import React, { SyntheticEvent, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { WithAnchor, WrapWithLink } from '../../../hoc';
import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { scrollToElement } from '../../../helpers/Data';

export default () => {
  return (
    <>
      <Typography variant='h2'>PRIVACY NOTICE</Typography>
      <Typography variant='subtitle1' gutterBottom>
        Last updated January 20, 2022
      </Typography>

      <Paper elevation={4} sx={{ padding: 2, marginBottom: 4 }}>
        <Container disableGutters maxWidth={false}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='body2' paragraph>
                For legal reasons, we must include the lame legal platitudes
                below to be in "compliance." But we thought this would be a good
                opportunity to convey how we feel about privacy.
              </Typography>

              <Typography variant='body2' paragraph>
                Everybody says they "care" about your privacy and data, yet most
                don't hire a cybersecurity red team, study on their own, or
                train their employees to combat social engineering (HUMINT),
                open-source intelligence (OSINT), and signal intelligence
                (SIGINT).{' '}
              </Typography>

              <Typography variant='body2' paragraph>
                Maybe they're well intended, but if one step is skipped over,
                it's over. There goes your data on the dark web. Miss a
                checkbox? Oops, now you're going to get quasi-adult ads on every
                social media platform you visit for the next 90 days. Click on a
                phishing link just that one time? Now hackers chased by alphabet
                soup agencies are sending Nigerian prince emails to everyone
                you've ever emailed.
              </Typography>

              <Typography variant='body2' paragraph>
                To the best of our collective abilities, we protect your
                identity and your data. The word protect comes from the Latin
                portmanteau protego—pro (to advocate or build) and tecto (a roof
                or wall). Quite literally and figuratively, we intend to
                continually build a wall around your data and a roof over your
                identity.
              </Typography>

              <Typography variant='body2' paragraph>
                We won't disclose the methods we use to protect your data, but
                we are fierce advocates for your personal choice to remain
                anonymous or lay it all out there if that's what you want.
              </Typography>

              <Typography variant='body2' paragraph>
                -Justin, Andrew, and Teddy
              </Typography>

              <Typography variant='body2' paragraph>
                Now back to the legalese…
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>

      <Typography variant='body1' paragraph>
        Thank you for choosing to be part of our community at Creative Lid, LLC
        (dba My Gun Catalog) ("<strong>Company</strong>," "<strong>we</strong>,"
        "<strong>us</strong>
        ," or "<strong>our</strong>"). We are committed to protecting your
        personal information and your right to privacy. If you have any
        questions or concerns about this privacy notice or our practices
        regarding your personal information, please contact us at{' '}
        <WrapWithLink
          link='mailto:help@creativelid.com'
          internal={false}
          className='styled--link'
          color='primary'
        >
          help@creativelid.com
        </WrapWithLink>
        .
      </Typography>
      <Typography variant='body1' paragraph>
        This privacy notice describes how we might use your information if you:
      </Typography>
      <List className='list--bulleted'>
        <ListItem disableGutters>
          Visit our website at{' '}
          <WrapWithLink link='/' className='styled--link' color='primary'>
            https://www.myguncatalog.com
          </WrapWithLink>
        </ListItem>
        <ListItem disableGutters>
          Use our mobile application — My Gun Catalog
        </ListItem>
        <ListItem disableGutters>
          Engage with us in other related ways ― including any sales, marketing,
          or events
        </ListItem>
      </List>
      <Typography variant='body1' paragraph>
        In this privacy notice, if we refer to:
      </Typography>
      <List className='list--bulleted'>
        <ListItem disableGutters>
          "<strong>Website</strong>," we are referring to any website of ours
          that references or links to this policy
        </ListItem>
        <ListItem disableGutters>
          "<strong>App</strong>," we are referring to any application of ours
          that references or links to this policy, including any listed above
        </ListItem>
        <ListItem disableGutters>
          "<strong>Services</strong>," we are referring to our Website, App, and
          other related services, including any sales, marketing, or events
        </ListItem>
      </List>
      <Typography variant='body1' paragraph>
        The purpose of this privacy notice is to explain to you in the clearest
        way possible what information we collect, how we use it, and what rights
        you have in relation to it. If there are any terms in this privacy
        notice that you do not agree with, please discontinue use of our
        Services immediately.
      </Typography>
      <Typography variant='body1' paragraph>
        <strong>
          Please read this privacy notice carefully, as it will help you
          understand what we do with the information that we collect.
        </strong>
      </Typography>

      <Divider className='spacer' />

      <Typography variant='h3' gutterBottom>
        TABLE OF CONTENTS
      </Typography>

      <List className='list--ordered' dense>
        <ListItem disableGutters>
          <WithAnchor
            anchor='#privacy--informationCollected'
            component={scrollToElement}
          >
            <WrapWithLink link='#' className='styled--link' color='primary'>
              WHAT INFORMATION DO WE COLLECT?
            </WrapWithLink>
          </WithAnchor>
        </ListItem>
        <ListItem disableGutters>
          <WithAnchor
            anchor='#privacy--informationUse'
            component={scrollToElement}
          >
            <WrapWithLink link='#' className='styled--link' color='primary'>
              HOW DO WE USE YOUR INFORMATION?
            </WrapWithLink>
          </WithAnchor>
        </ListItem>
        <ListItem disableGutters>
          <WithAnchor
            anchor='#privacy--informationShared'
            component={scrollToElement}
          >
            <WrapWithLink link='#' className='styled--link' color='primary'>
              WILL YOUR INFORMATION BE SHARED WITH ANYONE?
            </WrapWithLink>
          </WithAnchor>
        </ListItem>
        <ListItem disableGutters>
          <WithAnchor
            anchor='#privacy--informationSharedWithWho'
            component={scrollToElement}
          >
            <WrapWithLink link='#' className='styled--link' color='primary'>
              WHO WILL YOUR INFORMATION BE SHARED WITH?
            </WrapWithLink>
          </WithAnchor>
        </ListItem>
        <ListItem disableGutters>
          <WithAnchor anchor='#privacy--cookieUse' component={scrollToElement}>
            <WrapWithLink link='#' className='styled--link' color='primary'>
              DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </WrapWithLink>
          </WithAnchor>
        </ListItem>
        <ListItem disableGutters>
          <WithAnchor
            anchor='#privacy--socialLogins'
            component={scrollToElement}
          >
            <WrapWithLink link='#' className='styled--link' color='primary'>
              HOW DO WE HANDLE YOUR SOCIAL LOGINS?
            </WrapWithLink>
          </WithAnchor>
        </ListItem>
        <ListItem disableGutters>
          <WithAnchor
            anchor='#privacy--informationLength'
            component={scrollToElement}
          >
            <WrapWithLink link='#' className='styled--link' color='primary'>
              HOW LONG DO WE KEEP YOUR INFORMATION?
            </WrapWithLink>
          </WithAnchor>
        </ListItem>
        <ListItem disableGutters>
          <WithAnchor
            anchor='#privacy--informationSafe'
            component={scrollToElement}
          >
            <WrapWithLink link='#' className='styled--link' color='primary'>
              HOW DO WE KEEP YOUR INFORMATION SAFE?
            </WrapWithLink>
          </WithAnchor>
        </ListItem>
        <ListItem disableGutters>
          <WithAnchor
            anchor='#privacy--privacyRights'
            component={scrollToElement}
          >
            <WrapWithLink link='#' className='styled--link' color='primary'>
              WHAT ARE YOUR PRIVACY RIGHTS?
            </WrapWithLink>
          </WithAnchor>
        </ListItem>
        <ListItem disableGutters>
          <WithAnchor
            anchor='#privacy--doNotTrackControls'
            component={scrollToElement}
          >
            <WrapWithLink link='#' className='styled--link' color='primary'>
              CONTROLS FOR DO-NOT-TRACK FEATURES
            </WrapWithLink>
          </WithAnchor>
        </ListItem>
        <ListItem disableGutters>
          <WithAnchor
            anchor='#privacy--caPrivacyRights'
            component={scrollToElement}
          >
            <WrapWithLink link='#' className='styled--link' color='primary'>
              DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </WrapWithLink>
          </WithAnchor>
        </ListItem>
        <ListItem disableGutters>
          <WithAnchor
            anchor='#privacy--updatesNotice'
            component={scrollToElement}
          >
            <WrapWithLink link='#' className='styled--link' color='primary'>
              DO WE MAKE UPDATES TO THIS NOTICE?
            </WrapWithLink>
          </WithAnchor>
        </ListItem>
        <ListItem disableGutters>
          <WithAnchor anchor='#privacy--contactUs' component={scrollToElement}>
            <WrapWithLink link='#' className='styled--link' color='primary'>
              HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </WrapWithLink>
          </WithAnchor>
        </ListItem>
      </List>

      <Divider className='spacer' />

      <Box id='privacy--informationCollected'>
        <Typography variant='h3' gutterBottom>
          1. WHAT INFORMATION DO WE COLLECT?
        </Typography>

        <Typography variant='h4' gutterBottom>
          Personal information you disclose to us
        </Typography>
        <Typography variant='body1' paragraph>
          <strong>
            <em>In Short:</em>
          </strong>{' '}
          <em>We collect personal information that you provide to us.</em>
        </Typography>
        <Typography variant='body1' paragraph>
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services or otherwise when you
          contact us.
        </Typography>
        <Typography variant='body1' paragraph>
          The personal information that we collect depends on the context of
          your interactions with us and the Services, the choices you make and
          the products and features you use. The personal information we collect
          may include the following:
        </Typography>
        <Typography variant='body1' paragraph>
          <strong>Personal Information Provided by You.</strong> We collect
          email addresses; passwords; contact or authentication data; and other
          similar information.
        </Typography>
        <Typography variant='body1' paragraph>
          <strong>Social Media Login Data.&nbsp;</strong>We may provide you with
          the option to register with us using your existing social media
          account details, like your Facebook, Twitter or other social media
          account. If you choose to register in this way, we will collect the
          information described in the section called "HOW DO WE HANDLE YOUR
          SOCIAL LOGINS?" below.
        </Typography>
        <Typography variant='body1' paragraph>
          All personal information that you provide to us must be true, complete
          and accurate, and you must notify us of any changes to such personal
          information.
        </Typography>
        <Typography variant='h4' gutterBottom>
          Information automatically collected
        </Typography>
        <Typography variant='body1' paragraph>
          <em>
            <strong>In Short:</strong> Some information — such as your Internet
            Protocol (IP) address and/or browser and device characteristics — is
            collected automatically when you visit our Services.
          </em>
        </Typography>
        <Typography variant='body1' paragraph>
          We automatically collect certain information when you visit, use or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </Typography>
        <Typography variant='body1' paragraph>
          Like many businesses, we also collect information through cookies and
          similar technologies. You can find out more about this in our Cookie
          Notice:{' '}
          <WrapWithLink link='/policies' className='styled--link'>
            https://www.myguncatalog.com/policies
          </WrapWithLink>
        </Typography>
        <Typography variant='body1' paragraph>
          The information we collect includes:
        </Typography>
        <List className='list--bulleted'>
          <ListItem>
            <em>Log and Usage Data.</em> Log and usage data is service-related,
            diagnostic, usage and performance information our servers
            automatically collect when you access or use our Services and which
            we record in log files. Depending on how you interact with us, this
            log data may include your IP address, device information, browser
            type and settings and information about your activity in the
            Services (such as the date/time stamps associated with your usage,
            pages and files viewed, searches and other actions you take such as
            which features you use), device event information (such as system
            activity, error reports (sometimes called 'crash dumps') and
            hardware settings).
          </ListItem>

          <ListItem>
            <em>Device Data.</em> We collect device data such as information
            about your computer, phone, tablet or other device you use to access
            the Services. Depending on the device used, this device data may
            include information such as your IP address (or proxy server),
            device and application identification numbers, location, browser
            type, hardware model Internet service provider and/or mobile
            carrier, operating system and system configuration information.
          </ListItem>

          <ListItem>
            <em>Location Data.</em> We collect location data such as information
            about your device's location, which can be either precise or
            imprecise. How much information we collect depends on the type and
            settings of the device you use to access the Services. For example,
            we may use GPS and other technologies to collect geolocation data
            that tells us your current location (based on your IP address). You
            can opt out of allowing us to collect this information either by
            refusing access to the information or by disabling your Location
            setting on your device. Note however, if you choose to opt out, you
            may not be able to use certain aspects of the Services.
          </ListItem>
        </List>

        <Typography variant='h4' gutterBottom>
          Information collected through our App
        </Typography>

        <Typography variant='body1' paragraph>
          <strong>
            <em>In Short:</em>
          </strong>{' '}
          <em>
            We collect information regarding your mobile device, when you use
            our App.
          </em>
        </Typography>

        <Typography variant='body1' paragraph>
          If you use our App, we also collect the following information:
        </Typography>

        <List className='list--bulleted'>
          <ListItem>
            <em>Mobile Device Access.</em> We may request access or permission
            to certain features from your mobile device, including your mobile
            device's camera, and other features. If you wish to change our
            access or permissions, you may do so in your device's settings.
          </ListItem>
        </List>

        <Typography variant='body1' paragraph>
          This information is primarily needed to maintain the security and
          operation of our App, for troubleshooting and for our internal
          analytics and reporting purposes.
        </Typography>
      </Box>

      <Divider className='spacer' />

      <Box id='privacy--informationUse'>
        <Typography variant='h3' gutterBottom>
          2. HOW DO WE USE YOUR INFORMATION?
        </Typography>

        <Typography variant='body1' paragraph>
          <strong>
            <em>In Short:</em>
          </strong>{' '}
          <em>
            We process your information for purposes based on legitimate
            business interests, the fulfillment of our contract with you,
            compliance with our legal obligations, and/or your consent.
          </em>
        </Typography>

        <Typography variant='body1' paragraph>
          We use personal information collected via our Services for a variety
          of business purposes described below. We process your personal
          information for these purposes in reliance on our legitimate business
          interests, in order to enter into or perform a contract with you, with
          your consent, and/or for compliance with our legal obligations. We
          indicate the specific processing grounds we rely on next to each
          purpose listed below.
        </Typography>

        <Typography variant='body1' paragraph>
          We use the information we collect or receive:
        </Typography>

        <List className='list--bulleted'>
          <ListItem>
            <strong>To facilitate account creation and logon process.</strong>
            If you choose to link your account with us to a third-party account
            (such as your Google or Facebook account), we use the information
            you allowed us to collect from those third parties to facilitate
            account creation and logon process for the performance of the
            contract. See the section below headed "HOW DO WE HANDLE YOUR SOCIAL
            LOGINS?" for further information.
          </ListItem>

          <ListItem>
            <strong>To post testimonials.</strong> We post testimonials on our
            Services that may contain personal information. Prior to posting a
            testimonial, we will obtain your consent to use your name and the
            content of the testimonial. If you wish to update, or delete your
            testimonial, please contact us at{' '}
            <WrapWithLink
              link='mailto:adetwiler@creativelid.com'
              internal={false}
              className='styled--link'
              color='primary'
            >
              adetwiler@creativelid.com
            </WrapWithLink>{' '}
            and be sure to include your name, testimonial location, and contact
            information.
          </ListItem>

          <ListItem>
            <strong>Request feedback.</strong>We may use your information to
            request feedback and to contact you about your use of our Services.
          </ListItem>

          <ListItem>
            <strong>To enable user-to-user communications.</strong> We may use
            your information in order to enable user-to-user communications with
            each user's consent.
          </ListItem>

          <ListItem>
            <strong>To manage user accounts.</strong> We may use your
            information for the purposes of managing our account and keeping it
            in working order.
          </ListItem>

          <ListItem>
            <strong>To send administrative information to you.</strong> We may
            use your personal information to send you product, service and new
            feature information and/or information about changes to our terms,
            conditions, and policies.
          </ListItem>

          <ListItem>
            <strong>To protect our Services.</strong> We may use your
            information as part of our efforts to keep our Services safe and
            secure (for example, for fraud monitoring and prevention).
          </ListItem>

          <ListItem>
            <strong>
              To enforce our terms, conditions and policies for business
              purposes, to comply with legal and regulatory requirements or in
              connection with our contract.
            </strong>
          </ListItem>

          <ListItem>
            <strong>To respond to legal requests and prevent harm.</strong> If
            we receive a subpoena or other legal request, we may need to inspect
            the data we hold to determine how to respond.
          </ListItem>

          <ListItem>
            <strong>Fulfill and manage your orders.</strong> We may use your
            information to fulfill and manage your orders, payments, returns,
            and exchanges made through the Services.
          </ListItem>

          <ListItem>
            <strong>Administer prize draws and competitions.</strong> We may use
            your information to administer prize draws and competitions when you
            elect to participate in our competitions.
          </ListItem>

          <ListItem>
            <strong>
              To deliver and facilitate delivery of services to the user.
            </strong>{' '}
            We may use your information to provide you with the requested
            service.
          </ListItem>
          <ListItem>
            <strong>
              To respond to user inquiries/offer support to users.
            </strong>{' '}
            We may use your information to respond to your inquiries and solve
            any potential issues you might have with the use of our Services.
          </ListItem>
          <ListItem>
            <strong>
              To send you marketing and promotional communications.
            </strong>{' '}
            We and/or our third-party marketing partners may use the personal
            information you send to us for our marketing purposes, if this is in
            accordance with your marketing preferences. For example, when
            expressing an interest in obtaining information about us or our
            Services, subscribing to marketing or otherwise contacting us, we
            will collect personal information from you. You can opt-out of our
            marketing emails at any time (see the "WHAT ARE YOUR PRIVACY
            RIGHTS?" below).
          </ListItem>
          <ListItem>
            <strong>Deliver targeted advertising to you.</strong> We may use
            your information to develop and display personalized content and
            advertising (and work with third parties who do so) tailored to your
            interests and/or location and to measure its effectiveness. For more
            information see our Cookie Notice:{' '}
            <WrapWithLink link='/policies' className='styled--link'>
              https://www.myguncatalog.com/policies
            </WrapWithLink>
          </ListItem>
        </List>
      </Box>

      <Divider className='spacer' />

      <Box id='privacy--informationShared'>
        <Typography variant='h3' gutterBottom>
          3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
        </Typography>

        <Typography variant='body1' paragraph>
          <strong>
            <em>In Short:</em>
          </strong>
          <em>
            {' '}
            We only share information with your consent, to comply with laws, to
            provide you with services, to protect your rights, or to fulfill
            business obligations.
          </em>
        </Typography>

        <Typography variant='body1' paragraph>
          We may process or share your data that we hold based on the following
          legal basis:
        </Typography>

        <List className='list--bulleted'>
          <ListItem>
            <strong>Consent:</strong> We may process your data if you have given
            us specific consent to use your personal information for a specific
            purpose.
          </ListItem>

          <ListItem>
            <strong>Legitimate Interests:</strong> We may process your data when
            it is reasonably necessary to achieve our legitimate business
            interests.
          </ListItem>

          <ListItem>
            <strong>Performance of a Contract:</strong> Where we have entered
            into a contract with you, we may process your personal information
            to fulfill the terms of our contract.
          </ListItem>

          <ListItem>
            <strong>Legal Obligations:</strong> We may disclose your information
            where we are legally required to do so in order to comply with
            applicable law, governmental requests, a judicial proceeding, court
            order, or legal process, such as in response to a court order or a
            subpoena (including in response to public authorities to meet
            national security or law enforcement requirements).
          </ListItem>

          <ListItem>
            <strong>Vital Interests:</strong> We may disclose your information
            where we believe it is necessary to investigate, prevent, or take
            action regarding potential violations of our policies, suspected
            fraud, situations involving potential threats to the safety of any
            person and illegal activities, or as evidence in litigation in which
            we are involved.
          </ListItem>
        </List>

        <Typography variant='body1' paragraph>
          More specifically, we may need to process your data or share your
          personal information in the following situations:
        </Typography>

        <List className='list--bulleted'>
          <ListItem>
            <strong>Business Transfers.</strong> We may share or transfer your
            information in connection with, or during negotiations of, any
            merger, sale of company assets, financing, or acquisition of all or
            a portion of our business to another company.
          </ListItem>

          <ListItem>
            <strong>
              Vendors, Consultants and Other Third-Party Service Providers.
            </strong>{' '}
            We may share your data with third-party vendors, service providers,
            contractors or agents who perform services for us or on our behalf
            and require access to such information to do that work. Examples
            include: payment processing, data analysis, email delivery, hosting
            services, customer service and marketing efforts. We may allow
            selected third parties to use tracking technology on the Services,
            which will enable them to collect data on our behalf about how you
            interact with our Services over time. This information may be used
            to, among other things, analyze and track data, determine the
            popularity of certain content, pages or features, and better
            understand online activity. Unless described in this notice, we do
            not share, sell, rent or trade any of your information with third
            parties for their promotional purposes.
          </ListItem>

          <ListItem>
            <strong>Third-Party Advertisers.</strong> We may use third-party
            advertising companies to serve ads when you visit or use the
            Services. These companies may use information about your visits to
            our Website(s) and other websites that are contained in web cookies
            and other tracking technologies in order to provide advertisements
            about goods and services of interest to you. See our Cookie Notice:{' '}
            <WrapWithLink link='/policies' className='styled--link'>
              https://www.myguncatalog.com/policies
            </WrapWithLink>{' '}
            for further information.
          </ListItem>
        </List>
      </Box>

      <Divider className='spacer' />

      <Box id='privacy--informationSharedWithWho'>
        <Typography variant='h3' gutterBottom>
          4. WHO WILL YOUR INFORMATION BE SHARED WITH?
        </Typography>

        <Typography variant='body1' paragraph>
          <strong>
            <em>In Short:</em>
          </strong>{' '}
          <em>
            We only share information with the following categories of third
            parties.
          </em>
        </Typography>

        <Typography variant='body1' paragraph>
          We only share and disclose your information with the following
          categories of third parties. If we have processed your data based on
          your consent and you wish to revoke your consent, please contact us
          using the contact details provided in the section below titled "HOW
          CAN YOU CONTACT US ABOUT THIS NOTICE?".
        </Typography>

        <List className='list--bulleted'>
          <ListItem>Monitoring Tools</ListItem>
          <ListItem>
            User Account Registration &amp; Authentication Services
          </ListItem>
          <ListItem>Sales &amp; Marketing Tools</ListItem>
        </List>
      </Box>

      <Divider className='spacer' />

      <Box id='privacy--cookieUse'>
        <Typography variant='h3' gutterBottom>
          5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </Typography>

        <Typography variant='body1' paragraph>
          <strong>
            <em>In Short:</em>
          </strong>{' '}
          <em>
            We may use cookies and other tracking technologies to collect and
            store your information.
          </em>
        </Typography>

        <Typography variant='body1' paragraph>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice{' '}
          <WrapWithLink link='/policies' className='styled--link'>
            https://www.myguncatalog.com/policies
          </WrapWithLink>
        </Typography>
      </Box>

      <Divider className='spacer' />

      <Box id='privacy--socialLogins'>
        <Typography variant='h3' gutterBottom>
          6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
        </Typography>

        <Typography variant='body1' paragraph>
          <strong>
            <em>In Short:</em>
          </strong>{' '}
          <em>
            If you choose to register or log in to our services using a social
            media account, we may have access to certain information about you.
          </em>
        </Typography>

        <Typography variant='body1' paragraph>
          Our Services offers you the ability to register and login using your
          third-party social media account details (like your Facebook or
          Twitter logins). Where you choose to do this, we will receive certain
          profile information about you from your social media provider. The
          profile information we receive may vary depending on the social media
          provider concerned, but will often include your name, email address,
          friends list, profile picture as well as other information you choose
          to make public on such social media platform.
        </Typography>

        <Typography variant='body1' paragraph>
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant Services. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third-party social media provider. We recommend that you review
          their privacy notice to understand how they collect, use and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </Typography>
      </Box>

      <Divider className='spacer' />

      <Box id='privacy--informationLength'>
        <Typography variant='h3' gutterBottom>
          7. HOW LONG DO WE KEEP YOUR INFORMATION?
        </Typography>

        <Typography variant='body1' paragraph>
          <strong>
            <em>In Short:</em>
          </strong>{' '}
          <em>
            We keep your information for as long as necessary to fulfill the
            purposes outlined in this privacy notice unless otherwise required
            by law.
          </em>
        </Typography>

        <Typography variant='body1' paragraph>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us.
        </Typography>

        <Typography variant='body1' paragraph>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </Typography>
      </Box>

      <Divider className='spacer' />

      <Box id='privacy--informationSafe'>
        <Typography variant='h3' gutterBottom>
          8. HOW DO WE KEEP YOUR INFORMATION SAFE?
        </Typography>

        <Typography variant='body1' paragraph>
          <strong>
            <em>In Short:</em>
          </strong>{' '}
          <em>
            We aim to protect your personal information through a system of
            organizational and technical security measures.
          </em>
        </Typography>

        <Typography variant='body1' paragraph>
          We have implemented appropriate technical and organizational security
          measures designed to protect the security of any personal information
          we process. However, despite our safeguards and efforts to secure your
          information, no electronic transmission over the Internet or
          information storage technology can be guaranteed to be 100% secure, so
          we cannot promise or guarantee that hackers, cybercriminals, or other
          unauthorized third parties will not be able to defeat our security,
          and improperly collect, access, steal, or modify your information.
          Although we will do our best to protect your personal information,
          transmission of personal information to and from our Services is at
          your own risk. You should only access the Services within a secure
          environment.
        </Typography>
      </Box>

      <Divider className='spacer' />

      <Box id='privacy--privacyRights'>
        <Typography variant='h3' gutterBottom>
          9. WHAT ARE YOUR PRIVACY RIGHTS?
        </Typography>

        <Typography variant='body1' paragraph>
          <strong>
            <em>In Short:</em>
          </strong>{' '}
          <em>
            In some regions, such as the European Economic Area (EEA) and United
            Kingdom (UK), you have rights that allow you greater access to and
            control over your personal information. You may review, change, or
            terminate your account at any time.
          </em>
        </Typography>

        <Typography variant='body1' paragraph>
          In some regions (like the EEA and UK), you have certain rights under
          applicable data protection laws. These may include the right (i) to
          request access and obtain a copy of your personal information, (ii) to
          request rectification or erasure; (iii) to restrict the processing of
          your personal information; and (iv) if applicable, to data
          portability. In certain circumstances, you may also have the right to
          object to the processing of your personal information. To make such a
          request, please use the{' '}
          <WrapWithLink link='/contact-us' className='styled--link'>
            contact details
          </WrapWithLink>
          provided below. We will consider and act upon any request in
          accordance with applicable data protection laws.
        </Typography>

        <Typography variant='body1' paragraph>
          If we are relying on your consent to process your personal
          information, you have the right to withdraw your consent at any time.
          Please note however that this will not affect the lawfulness of the
          processing before its withdrawal, nor will it affect the processing of
          your personal information conducted in reliance on lawful processing
          grounds other than consent.
        </Typography>

        <Typography variant='body1' paragraph>
          If you are a resident in the EEA or UK and you believe we are
          unlawfully processing your personal information, you also have the
          right to complain to your local data protection supervisory authority.
          You can find their contact details here:
          <WrapWithLink
            link='https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm'
            internal={false}
            className='styled--link'
          >
            https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
          </WrapWithLink>
        </Typography>

        <Typography variant='body1' paragraph>
          If you are a resident in Switzerland, the contact details for the data
          protection authorities are available here:
          <WrapWithLink
            link='https://www.edoeb.admin.ch/edoeb/en/home.html'
            internal={false}
            className='styled--link'
          >
            https://www.edoeb.admin.ch/edoeb/en/home.html
          </WrapWithLink>
          .
        </Typography>

        <Typography variant='body1' paragraph>
          If you have questions or comments about your privacy rights, you may
          email us at{' '}
          <WrapWithLink
            link='mailto:help@creativelid.com'
            internal={false}
            className='styled--link'
            color='primary'
          >
            help@creativelid.com
          </WrapWithLink>
          .
        </Typography>

        <Typography variant='h4' gutterBottom>
          Account Information
        </Typography>

        <Typography variant='body1' paragraph>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </Typography>

        <List className='list--bulleted'>
          <ListItem>
            Log in to your account settings and update your user account.
          </ListItem>
        </List>

        <Typography variant='body1' paragraph>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          Terms of Use and/or comply with applicable legal requirements.
        </Typography>

        <Typography variant='body1' paragraph>
          <strong>
            <u>Cookies and similar technologies:</u>
          </strong>{' '}
          Most Web browsers are set to accept cookies by default. If you prefer,
          you can usually choose to set your browser to remove cookies and to
          reject cookies. If you choose to remove cookies or reject cookies,
          this could affect certain features or services of our Services. To
          opt-out of interest-based advertising by advertisers on our Services
          visit{' '}
          <WrapWithLink
            link='https://www.aboutads.info/choices/'
            internal={false}
            className='styled--link'
          >
            https://www.aboutads.info/choices/
          </WrapWithLink>
          . For further information, please see our Cookie Notice:{' '}
          <WrapWithLink link='/policies' className='styled--link'>
            https://www.myguncatalog.com/policies
          </WrapWithLink>
          .
        </Typography>

        <Typography variant='body1' paragraph>
          <strong>
            <u>Opting out of email marketing:</u>
          </strong>{' '}
          You can unsubscribe from our marketing email list at any time by
          clicking on the unsubscribe link in the emails that we send or by
          contacting us using the details provided below. You will then be
          removed from the marketing email list — however, we may still
          communicate with you, for example to send you service-related emails
          that are necessary for the administration and use of your account, to
          respond to service requests, or for other non-marketing purposes. To
          otherwise opt-out, you may:
        </Typography>

        <List className='list--bulleted'>
          <ListItem>
            Access your account settings and update your preferences.
          </ListItem>
        </List>
      </Box>

      <Divider className='spacer' />

      <Box id='privacy--doNotTrackControls'>
        <Typography variant='h3' gutterBottom>
          10. CONTROLS FOR DO-NOT-TRACK features
        </Typography>

        <Typography variant='body1' paragraph>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </Typography>
      </Box>

      <Divider className='spacer' />

      <Box id='privacy--caPrivacyRights'>
        <Typography variant='h3' gutterBottom>
          11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </Typography>

        <Typography variant='body1' paragraph>
          <strong>
            <em>In Short:</em>
          </strong>{' '}
          <em>
            Yes, if you are a resident of California, you are granted specific
            rights regarding access to your personal information.
          </em>
        </Typography>

        <Typography variant='body1' paragraph>
          California Civil Code Section 1798.83, also known as the "Shine The
          Light" law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </Typography>

        <Typography variant='body1' paragraph>
          If you are under 18 years of age, reside in California, and have a
          registered account with a Service, you have the right to request
          removal of unwanted data that you publicly post on the Services. To
          request removal of such data, please contact us using the contact
          information provided below, and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Services, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g. backups, etc.).
        </Typography>

        <Typography variant='h4' gutterBottom>
          CCPA Privacy Notice
        </Typography>

        <Typography variant='body1' paragraph>
          The California Code of Regulations defines a "resident" as:
        </Typography>

        <List dense>
          <ListItem>
            (1) every individual who is in the State of California for other
            than a temporary or transitory purpose and
          </ListItem>
          <ListItem>
            (2) every individual who is domiciled in the State of California who
            is outside the State of California for a temporary or transitory
            purpose
          </ListItem>
        </List>

        <Typography variant='body1' paragraph>
          All other individuals are defined as "non-residents."
        </Typography>

        <Typography variant='body1' paragraph>
          If this definition of "resident" applies to you, we must adhere to
          certain rights and obligations regarding your personal information.
        </Typography>

        <Typography variant='subtitle2'>
          What categories of personal information do we collect?
        </Typography>

        <Typography variant='body1' paragraph>
          We have collected the following categories of personal information in
          the past twelve (12) months:
        </Typography>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Category</TableCell>
                <TableCell>Examples</TableCell>
                <TableCell>Collected</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>A. Identifiers</TableCell>
                <TableCell>
                  Contact details, such as real name, alias, postal address,
                  telephone or mobile contact number, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address and account name
                </TableCell>
                <TableCell>NO</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  B. Personal information categories listed in the California
                  Customer Records statute
                </TableCell>
                <TableCell>
                  Name, contact information, education, employment, employment
                  history and financial information
                </TableCell>
                <TableCell>YES</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  C. Protected classification characteristics under California
                  or federal law
                </TableCell>
                <TableCell>Gender and date of birth</TableCell>
                <TableCell>NO</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>D. Commercial information</TableCell>
                <TableCell>
                  Transaction information, purchase history, financial details
                  and payment information
                </TableCell>
                <TableCell>NO</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>E. Biometric information</TableCell>
                <TableCell>Fingerprints and voiceprints</TableCell>
                <TableCell>NO</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  F. Internet or other similar network activity
                </TableCell>
                <TableCell>
                  Browsing history, search history, online behavior, interest
                  data, and interactions with our and other websites,
                  applications, systems and advertisements
                </TableCell>
                <TableCell>NO</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>G. Geolocation data</TableCell>
                <TableCell>Device location</TableCell>
                <TableCell>NO</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  H. Audio, electronic, visual, thermal, olfactory, or similar
                  information
                </TableCell>
                <TableCell>
                  Images and audio, video or call recordings created in
                  connection with our business activities
                </TableCell>
                <TableCell>NO</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  I. Professional or employment-related information
                </TableCell>
                <TableCell>
                  Business contact details in order to provide you our services
                  at a business level, job title as well as work history and
                  professional qualifications if you apply for a job with us
                </TableCell>
                <TableCell>NO</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>J. Education Information</TableCell>
                <TableCell>Student records and directory information</TableCell>
                <TableCell>NO</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  K. Inferences drawn from other personal information
                </TableCell>
                <TableCell>
                  Inferences drawn from any of the collected personal
                  information listed above to create a profile or summary about,
                  for example, an individual's preferences and characteristics
                </TableCell>
                <TableCell>NO</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant='body1' paragraph>
          We may also collect other personal information outside of these
          categories instances where you interact with us in-person, online, or
          by phone or mail in the context of:
        </Typography>

        <List className='list--bulleted'>
          <ListItem>
            Receiving help through our customer support channels;
          </ListItem>
          <ListItem>
            Participation in customer surveys or contests; and
          </ListItem>
          <ListItem>
            Facilitation in the delivery of our Services and to respond to your
            inquiries.
          </ListItem>
        </List>

        <Typography variant='subtitle2'>
          How do we use and share your personal information?
        </Typography>

        <Typography variant='body1' paragraph>
          Creative Lid LLC collects and shares your personal information
          through:
        </Typography>

        <List className='list--bulleted'>
          <ListItem>Targeting cookies/Marketing cookies</ListItem>
          <ListItem>Beacons/Pixels/Tags</ListItem>
        </List>

        <Typography variant='body1' paragraph>
          More information about our data collection and sharing practices can
          be found in this privacy notice and our Cookie Notice:{' '}
          <WrapWithLink link='/policies' className='styled--link'>
            https://www.myguncatalog.com/policies
          </WrapWithLink>
          .
        </Typography>

        <Typography variant='body1' paragraph>
          You may contact us by email at{' '}
          <WrapWithLink
            link='mailto:help@creativelid.com'
            internal={false}
            className='styled--link'
            color='primary'
          >
            help@creativelid.com
          </WrapWithLink>
          , or by referring to the contact details at the bottom of this
          document.
        </Typography>

        <Typography variant='body1' paragraph>
          If you are using an authorized agent to exercise your right to opt-out
          we may deny a request if the authorized agent does not submit proof
          that they have been validly authorized to act on your behalf.
        </Typography>

        <Typography variant='subtitle2'>
          Will your information be shared with anyone else?
        </Typography>

        <Typography variant='body1' paragraph>
          We may disclose your personal information with our service providers
          pursuant to a written contract between us and each service provider.
          Each service provider is a for-profit entity that processes the
          information on our behalf.
        </Typography>

        <Typography variant='body1' paragraph>
          We may use your personal information for our own business purposes,
          such as for undertaking internal research for technological
          development and demonstration. This is not considered to be "selling"
          of your personal data.
        </Typography>

        <Typography variant='body1' paragraph>
          Creative Lid LLC has disclosed the following categories of personal
          information to third parties for a business or commercial purpose in
          the preceding twelve (12) months:
        </Typography>

        <List className='list--bulleted'>
          <ListItem>
            Category B. Personal information, as defined in the California
            Customer Records law, such as your name, contact information,
            education, employment, employment history and financial information.
          </ListItem>
        </List>

        <Typography variant='body1' paragraph>
          The categories of third parties to whom we disclosed personal
          information for a business or commercial purpose can be found under
          "WHO WILL YOUR INFORMATION BE SHARED WITH?".
        </Typography>

        <Typography variant='body1' paragraph>
          Creative Lid LLC has not sold any personal information to third
          parties for a business or commercial purpose in the preceding twelve
          (12) months. Creative Lid LLC will not sell personal information in
          the future belonging to website visitors, users and other consumers.
        </Typography>

        <Typography variant='subtitle2' gutterBottom>
          Your rights with respect to your personal data
        </Typography>

        <Typography variant='body1' paragraph>
          <u>Right to request deletion of the data - Request to delete</u>
        </Typography>

        <Typography variant='body1' paragraph>
          You can ask for the deletion of your personal information. If you ask
          us to delete your personal information, we will respect your request
          and delete your personal information, subject to certain exceptions
          provided by law, such as (but not limited to) the exercise by another
          consumer of his or her right to free speech, our compliance
          requirements resulting from a legal obligation or any processing that
          may be required to protect against illegal activities.
        </Typography>

        <Typography variant='body1' paragraph>
          <u>Right to be informed - Request to know</u>
        </Typography>

        <Typography variant='body1' paragraph>
          Depending on the circumstances, you have a right to know:
        </Typography>

        <List className='list--bulleted'>
          <ListItem>
            whether we collect and use your personal information;
          </ListItem>
          <ListItem>
            the categories of personal information that we collect;
          </ListItem>
          <ListItem>
            the purposes for which the collected personal information is used;
          </ListItem>
          <ListItem>
            whether we sell your personal information to third parties;
          </ListItem>
          <ListItem>
            the categories of personal information that we sold or disclosed for
            a business purpose;
          </ListItem>
          <ListItem>
            the categories of third parties to whom the personal information was
            sold or disclosed for a business purpose; and
          </ListItem>
          <ListItem>
            the business or commercial purpose for collecting or selling
            personal information.
          </ListItem>
        </List>

        <Typography variant='body1' paragraph>
          In accordance with applicable law, we are not obligated to provide or
          delete consumer information that is de-identified in response to a
          consumer request or to re-identify individual data to verify a
          consumer request.
        </Typography>

        <Typography variant='body1' paragraph>
          <u>
            Right to Non-Discrimination for the Exercise of a Consumer's Privacy
            Rights
          </u>
        </Typography>

        <Typography variant='body1' paragraph>
          We will not discriminate against you if you exercise your privacy
          rights.
        </Typography>

        <Typography variant='body1' paragraph>
          <u>Verification process</u>
        </Typography>

        <Typography variant='body1' paragraph>
          Upon receiving your request, we will need to verify your identity to
          determine you are the same person about whom we have the information
          in our system. These verification efforts require us to ask you to
          provide information so that we can match it with information you have
          previously provided us. For instance, depending on the type of request
          you submit, we may ask you to provide certain information so that we
          can match the information you provide with the information we already
          have on file, or we may contact you through a communication method
          (e.g. phone or email) that you have previously provided to us. We may
          also use other verification methods as the circumstances dictate.
        </Typography>

        <Typography variant='body1' paragraph>
          We will only use personal information provided in your request to
          verify your identity or authority to make the request. To the extent
          possible, we will avoid requesting additional information from you for
          the purposes of verification. If, however, we cannot verify your
          identity from the information already maintained by us, we may request
          that you provide additional information for the purposes of verifying
          your identity, and for security or fraud-prevention purposes. We will
          delete such additionally provided information as soon as we finish
          verifying you.
        </Typography>

        <Typography variant='body1' paragraph>
          <u>Other privacy rights</u>
        </Typography>

        <List className='list--bulleted'>
          <ListItem>
            you may object to the processing of your personal data.
          </ListItem>
          <ListItem>
            you may request correction of your personal data if it is incorrect
            or no longer relevant, or ask to restrict the processing of the
            data.
          </ListItem>
          <ListItem>
            you can designate an authorized agent to make a request under the
            CCPA on your behalf. We may deny a request from an authorized agent
            that does not submit proof that they have been validly authorized to
            act on your behalf in accordance with the CCPA.
          </ListItem>
          <ListItem>
            you may request to opt-out from future selling of your personal
            information to third parties. Upon receiving a request to opt-out,
            we will act upon the request as soon as feasibly possible, but no
            later than 15 days from the date of the request submission.
          </ListItem>
        </List>

        <Typography variant='body1' paragraph>
          To exercise these rights, you can contact us by email at{' '}
          <WrapWithLink
            link='mailto:help@creativelid.com'
            internal={false}
            className='styled--link'
            color='primary'
          >
            help@creativelid.com
          </WrapWithLink>
          , or by referring to the contact details at the bottom of this
          document. If you have a complaint about how we handle your data, we
          would like to hear from you.
        </Typography>
      </Box>

      <Divider className='spacer' />

      <Box id='privacy--updatesNotice'>
        <Typography variant='h3' gutterBottom>
          12. DO WE MAKE UPDATES TO THIS NOTICE?
        </Typography>

        <Typography variant='body1' paragraph>
          <em>
            <strong>In Short:</strong> Yes, we will update this notice as
            necessary to stay compliant with relevant laws.
          </em>
        </Typography>

        <Typography variant='body1' paragraph>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </Typography>
      </Box>

      <Divider className='spacer' />

      <Box id='privacy--contactUs'>
        <Typography variant='h3' gutterBottom>
          13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </Typography>

        <Typography variant='body1' paragraph>
          If you have questions or comments about this notice, you may contact
          us at{' '}
          <WrapWithLink
            link='mailto:help@creativelid.com'
            internal={false}
            className='styled--link'
            color='primary'
          >
            help@creativelid.com
          </WrapWithLink>
          .
        </Typography>
      </Box>
    </>
  );
};
