import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { WithAnchor, WrapWithLink } from '../../../hoc';
import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { scrollToElement } from '../../../helpers/Data';

export default () => {
  return (
    <>
      <Typography variant='h2'>Cookie Policy</Typography>

      <Typography variant='subtitle1' gutterBottom>
        Last Updated January 19, 2022
      </Typography>

      <Typography variant='body1' paragraph>
        This Cookie Policy explains how Creative Lid, LLC (dba My Gun Catalog)
        uses cookies and similar technologies to recognize you when you visit
        our website at{' '}
        <WrapWithLink link='/' className='styled--link' color='primary'>
          http://www.myguncatalog.com
        </WrapWithLink>
        . It explains what these technologies are and why we use them, as well
        as your rights to control our use of them.
      </Typography>

      <Typography variant='body1' paragraph>
        In some cases we may use cookies to collect personal information, or
        that becomes personal information if we combine it with other
        information.
      </Typography>

      <Typography variant='h3' gutterBottom>
        What are cookies?
      </Typography>

      <Typography variant='body1' paragraph>
        Cookies are small data files that are placed on your computer or mobile
        device when you visit a website. Cookies are widely used by website
        owners in order to make their websites work, or to work more
        efficiently, as well as to provide reporting information.
      </Typography>

      <Typography variant='body1' paragraph>
        Cookies set by the website owner are called "first-party cookies."
        Cookies set by parties other than the website owner are called
        "third-party cookies." Third-party cookies enable third-party features
        or functionality to be provided on or through the website (e.g. like
        advertising, interactive content, and analytics). The parties that set
        these third party cookies can recognize your computer both when it
        visits the website in question and also when it visits certain other
        websites.
      </Typography>

      <Typography variant='h3' gutterBottom>
        Why do we use cookies?
      </Typography>

      <Typography variant='body1' paragraph>
        We use first- and third-party cookies for several reasons. Some cookies
        are required for technical reasons in order for our website to operate,
        and we refer to these as "essential" or "strictly necessary" cookies.
        Other cookies enable us to enhance the app experience based on
        unreported user pain points. Third parties serve cookies through our
        website for advertising, analytics, and other purposes. This is
        described in more detail below.
      </Typography>

      <Typography variant='body1' paragraph>
        The specific types of first- and third-party cookies served through our
        website and the purposes they perform are described below.
      </Typography>

      <Typography variant='h3' gutterBottom>
        How can I control cookies?
      </Typography>

      <Typography variant='body1' paragraph>
        You have the right to decide whether to accept or reject cookies. You
        can exercise your cookie rights by setting your preferences in the
        Cookie Consent Manager. The Cookie Consent Manager allows you to select
        which categories of cookies you accept or reject. Essential cookies
        cannot be rejected as they are strictly necessary to provide you with
        services.
      </Typography>

      <Typography variant='body1' paragraph>
        The Cookie Consent Manager can be found in the notification banner and
        on our website. If you choose to reject cookies, you may still use our
        website though your access to some functionality and areas of our
        website may be restricted. You may also set or amend your web browser
        controls to accept or refuse cookies. As the means by which you can
        refuse cookies through your web browser controls vary from
        browser-to-browser, you should visit your browser's help menu for more
        information. In addition, most advertising networks offer you a way to
        opt out of targeted advertising. If you would like to find out more
        information, please visit{' '}
        <WrapWithLink
          link='http://www.aboutads.info/choices/'
          internal={false}
          className='styled--link'
          color='primary'
        >
          http://www.aboutads.info/choices/
        </WrapWithLink>{' '}
        or{' '}
        <WrapWithLink
          link='http://www.youronlinechoices.com'
          internal={false}
          className='styled--link'
          color='primary'
        >
          http://www.youronlinechoices.com
        </WrapWithLink>
        .
      </Typography>

      <Typography variant='body1' paragraph>
        The specific types of first- and third-party cookies served through our
        website and the purposes they perform are described in the table below
        (please note that the specific cookies served may vary depending on the
        specific Online Properties you visit):
      </Typography>

      <Typography variant='h3' gutterBottom>
        Essential web site cookies:
      </Typography>

      <Typography variant='body1' paragraph>
        These cookies are strictly necessary to provide you with services
        available through our website and to use some of its features, such as
        access to secure areas.
      </Typography>

      <Typography variant='h3' gutterBottom>
        What about other tracking technologies, like web beacons?
      </Typography>

      <Typography variant='body1' paragraph>
        Cookies are not the only way to recognize or track visitors to a
        website. We may use other, similar technologies from time to time, like
        web beacons. These are tiny graphics files that contain a unique
        identifier that enable us to recognize when someone has visited our
        website or opened an e-mail including them. This allows us, for example,
        to monitor the traffic patterns of users from one page within a website
        to another, to deliver or communicate with cookies, to understand
        whether you have come to the website from an online advertisement
        displayed on a third-party website, to improve site performance, and to
        measure the success of e-mail marketing campaigns. In many instances,
        these technologies are reliant on cookies to function properly, and so
        declining cookies will impair their functioning.
      </Typography>

      <Typography variant='h3' gutterBottom>
        Do you serve targeted advertising?
      </Typography>

      <Typography variant='body1' paragraph>
        Third parties may serve cookies on your computer or mobile device to
        serve advertising through our website. These companies may use
        information about your visits to this and other websites in order to
        provide relevant advertisements about goods and services that you may be
        interested in. They may also employ technology that is used to measure
        the effectiveness of advertisements. This can be accomplished by them
        using cookies or web beacons to collect information about your visits to
        this and other sites in order to provide relevant advertisements about
        goods and services of potential interest to you. The information
        collected through this process does not enable us or them to identify
        your name, contact details, or other details that directly identify you
        unless you choose to provide these.
      </Typography>

      <Typography variant='h3' gutterBottom>
        How often will you update this Cookie Policy?
      </Typography>

      <Typography variant='body1' paragraph>
        We may update this Cookie Policy from time to time in order to reflect,
        for example, changes to the cookies we use or for other operational,
        legal, or regulatory reasons.
      </Typography>

      <Typography variant='h3' gutterBottom>
        Where can I get further information?
      </Typography>

      <Typography variant='body1' paragraph>
        If you have any questions about our use of cookies or other
        technologies, please email us at{' '}
        <WrapWithLink
          link='mailto:support@creativelid.com'
          internal={false}
          className='styled--link'
          color='primary'
        >
          support@creativelid.com
        </WrapWithLink>
        .
      </Typography>
    </>
  );
};
