import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  AppBar,
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  Hidden,
  IconButton,
  Link,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
  Toolbar,
  useScrollTrigger,
  Zoom,
} from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { TransitionProps } from '@material-ui/core/transitions';
import { Brand } from '../../atoms';
import { matchItem } from '../../../helpers/Data';
import DataConst from '../../../constants/DataConst';
import { Login, Register } from '../../molecules';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import loginImage from '../../../assets/images/login-background.jpg';
import registerImage from '../../../assets/images/register-background.jpg';
import { useStores } from '../../../models';
import { AuthContext } from '../../../providers/Auth';
import { isEmpty } from 'ramda';
import ButtonsConst from '../../../constants/ButtonsConst';
import {
  LoginButton,
  LoginMenuItem,
  RegisterButton,
  RegisterMenuItem,
} from '../../molecules/Dialog/Dialog';
import { WrapWithLink } from '../../../hoc/WrapWithLink';

interface Props {
  id: string | undefined;
  children: React.ReactElement;
  handleOpenLogin?: () => any | undefined;
  handleOpenRegister?: () => any | undefined;
}

function ElevationScroll(props: Props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const useStyles = makeStyles((theme: Theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
  },
  appbarNotScrolled: {
    backgroundColor: 'transparent',
  },
  dialogAppBar: {
    position: 'relative',
  },
  dialogToolbar: {
    maxWidth: 'unset',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  toolbar: {
    '& > *:not(div)': {
      margin: theme.spacing(1),
    },
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />;
});

interface ParamTypes {
  login: string;
}

export default (props: Props) => {
  const classes = useStyles();

  const { authStore } = useStores();
  const authContext = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [openMenu, setOpenMenu] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 });
  const location = useLocation();
  const pathname = matchItem(DataConst.LIGHT_LOGO_PAGE_PATH, location.pathname);
  const history = useHistory();

  const handleOpen = (event) => {
    let modal = null;

    const anchor = event.currentTarget.dataset.modal;

    if (anchor === 'login') {
      modal = <Login image={loginImage} handleClose={handleClose} />;
    } else if (anchor === 'register') {
      modal = <Register image={registerImage} />;
    }

    setDialogContent(modal);
    setOpen(true);
    if (openMenu) setOpenMenu(false);
  };

  const handleClose = () => {
    setDialogContent(null);
    setOpen(false);
  };

  const handleLogout = async () => {
    await authContext.logout(authStore);
    history.push('/');
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenMenu(false);
    }
  }

  const handleToggleMenu = () => {
    setOpenMenu((prevOpenMenu) => !prevOpenMenu);
  };

  const prevOpenMenu = React.useRef(openMenu);

  useEffect(() => {
    if (prevOpenMenu.current === true && openMenu === false) {
      anchorRef.current!.focus();
    }

    prevOpenMenu.current = openMenu;
  }, [openMenu]);

  return (
    <>
      <ElevationScroll {...props}>
        <AppBar
          id={props.id}
          className={`${classes.appBar} ${
            trigger === false ? classes.appbarNotScrolled : ''
          }`}
        >
          <Toolbar className={classes.toolbar}>
            <Brand
              className={classes.title}
              variation={`${
                trigger === false &&
                pathname === true &&
                isEmpty(authContext.user)
                  ? 'light'
                  : ''
              }`}
              link='/'
            />

            <Hidden smDown>
              <Button
                color='primary'
                href='https://shop.myguncatalog.com'
                disableElevation
                variant='text'
              >
                {ButtonsConst.SHOP}
              </Button>

              {isEmpty(authContext.user) && (
                <RegisterButton handleOpenRegister={props.handleOpenRegister} />
              )}

              <LoginButton handleOpenLogin={props.handleOpenLogin} />

              {!isEmpty(authContext.user) && (
                <IconButton
                  color='secondary'
                  onClick={() => {
                    if (openMenu) setOpenMenu(false);
                    history.push('/profile');
                  }}
                  aria-label='User Profile'
                >
                  <Person />
                </IconButton>
              )}
            </Hidden>

            <Hidden smUp>
              <IconButton
                color='primary'
                className={classes.menuButton}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup='true'
                onClick={handleToggleMenu}
              >
                <MoreVertIcon />
              </IconButton>

              <Popper
                open={openMenu}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={openMenu}
                          id='menu-list-grow'
                          onKeyDown={handleListKeyDown}
                        >
                          <WrapWithLink
                            link='https://shop.myguncatalog.com'
                            className='styled--link'
                            color='secondary'
                            target='_self'
                            internal={false}
                          >
                            <MenuItem>{ButtonsConst.SHOP}</MenuItem>
                          </WrapWithLink>

                          <Divider />

                          {isEmpty(authContext.user) && (
                            <RegisterMenuItem
                              handleOpenRegister={props.handleOpenRegister}
                            />
                          )}

                          {!isEmpty(authContext.user) && (
                            <MenuItem
                              onClick={() => history.push('/profile')}
                              aria-label='User Profile'
                            >
                              {ButtonsConst.USER_PROFILE}
                            </MenuItem>
                          )}

                          <LoginMenuItem
                            handleOpenLogin={props.handleOpenLogin}
                          />
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Hidden>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </>
  );
};
