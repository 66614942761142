import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { EnvironmentExtension } from '../extensions/Environment';
import moment from 'moment';

/**
 * Store for editing a single item in the user catalog
 */
export const EditCatalogStoreModel = types
  .model('EditCatalogStore', {
    caliber: types.optional(types.string, ''),
    caliberName: types.optional(types.string, ''),
    condition: types.optional(types.string, ''),
    curios: types.optional(types.string, ''),
    description: types.optional(types.string, ''),
    finishType: types.optional(types.string, ''),
    manufacturer: types.optional(types.string, ''),
    manufacturerName: types.optional(types.string, ''),
    model: types.optional(types.string, ''),
    modelName: types.optional(types.string, ''),
    purchaseAmount: types.optional(types.maybeNull(types.number), null),
    purchaseDate: types.optional(types.string, ''),
    serialNumber: types.optional(types.string, ''),
    subtype: types.optional(types.string, ''),
    subtypeName: types.optional(types.string, ''),
    type: types.optional(types.string, ''),
    typeName: types.optional(types.string, ''),
  })
  .extend(EnvironmentExtension)
  .props({})
  .actions((self) => ({
    setManufacturer(manufacturer: string) {
      self.manufacturer = manufacturer;
    },
    setManufacturerName(manufacturerName: string) {
      self.manufacturerName = manufacturerName;
    },
    setModel(model: string) {
      self.model = model;
    },
    setModelName(modelName: string) {
      self.modelName = modelName;
    },
    setSubtype(subtype: string) {
      self.subtype = subtype;
    },
    setSubtypeName(subtypeName: string) {
      self.subtypeName = subtypeName;
    },
    setType(type: string) {
      self.type = type;
    },
    setTypeName(typeName: string) {
      self.typeName = typeName;
    },
    setSerialNumber(serialNumber: string) {
      self.serialNumber = serialNumber;
    },
    setPurchaseDate(purchaseDate: string) {
      self.purchaseDate = purchaseDate
        ? moment(purchaseDate).format('MM/DD/YYYY')
        : '';
    },
    setPurchaseAmount(purchaseAmount: number | null) {
      self.purchaseAmount = purchaseAmount;
    },
    setFinishType(finishType: string) {
      self.finishType = finishType;
    },
    setCaliber(caliber: string) {
      self.caliber = caliber;
    },
    setCaliberName(caliberName: string) {
      self.caliberName = caliberName;
    },
    setCurios(curios: string) {
      self.curios = curios;
    },
    setCondition(condition: string) {
      self.condition = condition;
    },
    setDescription(description: string) {
      self.description = description;
    },
    async resetEditStore() {
      self.caliber = '';
      self.caliberName = '';
      self.condition = '';
      self.curios = '';
      self.description = '';
      self.finishType = '';
      self.manufacturer = '';
      self.manufacturerName = '';
      self.model = '';
      self.modelName = '';
      self.purchaseAmount = null;
      self.purchaseDate = '';
      self.serialNumber = '';
      self.subtype = '';
      self.subtypeName = '';
      self.type = '';
      self.typeName = '';
      return true;
    },
  }))
  .actions((self) => ({
    getCatalog(files = []) {
      const catalog = {
        condition: self.condition,
        curios: self.curios,
        description: self.description,
        files,
        finish_type: self.finishType,
        firearm_subtype_id: self.subtype,
        firearm_type_id: self.type,
        manufacturer_id: self.manufacturer,
        manufacturer_model_id: self.model,
        purchase_amount: self.purchaseAmount,
        purchase_date: self.purchaseDate,
        serial_number: self.serialNumber,
      };

      return catalog;
    },
  }))
  .actions((self) => ({
    async updateCatalogItem(catalogId: String, files = []) {
      if (files.length === 0) {
        files = null;
      }

      const catalog = self.getCatalog(files);

      return await self.environment.api.updateCatalog(catalogId, catalog);
    },
  }));

type EditCatalogStoreType = Instance<typeof EditCatalogStoreModel>;
export interface EditCatalogStore extends EditCatalogStoreType {}

type EditCatalogStoreSnapshotType = SnapshotOut<typeof EditCatalogStoreModel>;
export interface EditCatalogStoreSnapshot
  extends EditCatalogStoreSnapshotType {}
