import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Breadcrumbs,
  Container,
  FormControl,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  makeStyles,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import DatePicker from '@material-ui/lab/DatePicker';
import { LoadingButton } from '@material-ui/lab';
import {
  NavigateNext,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@material-ui/icons';
import { useStores } from '../../../models';
import { ComboBox, Loader } from '../../atoms';
import GeneralConst from '../../../constants/GeneralConst';
import {
  AddItemToCatalogModal,
  EditCatalogNavigation,
  InfoDialog,
} from '../../organisms';
import moment from 'moment';
import { AddItemToCatalog } from '../AddItemToCatalog';
import {
  formatMultiLineText,
  ifEmpty,
  maskSensitiveData,
} from '../../../helpers/Data';
import { iconsComponent } from '../../../helpers/Icons';
import { spaceToLowdash } from '../../../helpers/Typography';
import { getPhoto } from '../../../helpers/Photo';
import { isEmpty } from 'ramda';
import { toJS } from 'mobx';
import { WrapWithLink } from '../../../hoc/WrapWithLink';

interface RouteParams {
  id: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  centerContent: {
    display: 'flex',
    height: 'calc(100vh - 64px - 48px)',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  slider: {
    marginBottom: '50px',
    '& .awssld__bullets': {
      '& button': {
        transform: 'unset',
      },
    },
    '&.hide-controls': {
      '& .awssld__controls': {
        display: 'none',
      },
    },
  },
  gridList: {
    width: '100%',
    // height: 450,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  breadcrumbNav: {
    flexGrow: 1,
  },
  catalogToolbar: {
    marginBottom: theme.spacing(3),
    '& > *': {
      margin: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  masked: {
    paddingTop: theme.spacing(0.5),
    marginBottom: theme.spacing(-0.5),
  },
}));

export default observer(() => {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [deleteState, setDeleteState] = useState(false);
  const [editState, setEditState] = useState(false);
  const [pendingState, setPendingState] = useState(false);
  const [catalogItem, setCatalogItem] = useState([]);
  const [serialMask, setSerialMask] = useState(true);
  const [largeImage, setLargeImage] = useState();
  // eslint-disable-next-line
  const [uploads, setUploads] = useState([]);
  const {
    catalogStore,
    editCatalogStore,
    firearmCalibersStore,
    userCatalogItemsStore,
  } = useStores();

  const params = useParams<RouteParams>();

  const getCatalogItem = useCallback(async () => {
    if (!loading) return;

    const results = await catalogStore.catalog(params.id);
    setCatalogItem(results.catalog);
    setLargeImage(results.catalog.photos[0]);

    // console.log('***', 'results.catalog', results.catalog);

    setLoading(false);

    return results.catalog;
  }, [catalogStore, params.id]);

  const setEditCatalog = () => {
    editCatalogStore.setManufacturer(
      catalogItem['manufacturer'] && catalogItem['manufacturer'].id
        ? catalogItem['manufacturer'].id
        : ''
    );
    editCatalogStore.setManufacturerName(
      catalogItem['manufacturer'] && catalogItem['manufacturer'].name
        ? catalogItem['manufacturer'].name
        : ''
    );
    editCatalogStore.setModel(
      catalogItem['manufacturer_model'] && catalogItem['manufacturer_model'].id
        ? catalogItem['manufacturer_model'].id
        : ''
    );
    editCatalogStore.setModelName(
      catalogItem['manufacturer_model'] &&
        catalogItem['manufacturer_model'].name
        ? catalogItem['manufacturer_model'].name
        : ''
    );
    editCatalogStore.setSubtype(
      catalogItem['firearm_subtype'] && catalogItem['firearm_subtype'].id
        ? catalogItem['firearm_subtype'].id
        : ''
    );
    editCatalogStore.setSubtypeName(
      catalogItem['firearm_subtype'] && catalogItem['firearm_subtype'].name
        ? catalogItem['firearm_subtype'].name
        : ''
    );
    editCatalogStore.setType(
      catalogItem['firearm_type'] && catalogItem['firearm_type'].id
        ? catalogItem['firearm_type'].id
        : ''
    );
    editCatalogStore.setTypeName(
      catalogItem['firearm_type'] && catalogItem['firearm_type'].name
        ? catalogItem['firearm_type'].name
        : ''
    );
    editCatalogStore.setSerialNumber(
      catalogItem['serial_number'] ? catalogItem['serial_number'] : ''
    );
    editCatalogStore.setPurchaseDate(
      catalogItem['purchase_date'] ? catalogItem['purchase_date'] : ''
    );
    editCatalogStore.setPurchaseAmount(
      catalogItem['purchase_amount'] ? catalogItem['purchase_amount'] : null
    );
    editCatalogStore.setFinishType(
      catalogItem['finish_type'] ? catalogItem['finish_type'] : ''
    );
    editCatalogStore.setCaliber(
      catalogItem['firearm_caliber'] && catalogItem['firearm_caliber'].id
        ? catalogItem['firearm_caliber'].id
        : ''
    );
    editCatalogStore.setCaliberName(
      catalogItem['firearm_caliber'] && catalogItem['firearm_caliber'].name
        ? catalogItem['firearm_caliber'].name
        : ''
    );
    editCatalogStore.setCurios(
      catalogItem['curios'] ? catalogItem['curios'] : ''
    );
    editCatalogStore.setCondition(
      catalogItem['condition'] ? catalogItem['condition'] : ''
    );
    editCatalogStore.setDescription(
      catalogItem['description'] ? catalogItem['description'] : ''
    );
    // console.log('***', 'edit store updated', editCatalogStore);
  };

  const handleRemoveCatalogItem = () => {
    setDeleteState(true);
  };

  const toggleEditState = () => {
    setEditState(!editState);
    setEditCatalog();

    // editCatalogStore.resetEditStore();
  };

  const handleOpen = () => {
    // manufacturersStore.setManufacturers([]);
    // manufacturerModelsStore.setManufacturerModels([]);
    // subtypesStore.setSubtypes([]);
    // firearmCalibersStore.setFirearmCalibers([]);
    setOpen(!open);
  };

  const handleSaveChanges = async (e: any) => {
    e.preventDefault();

    setPendingState(true);

    // console.log('***', 'handleSaveChanges', 'save clicked', editCatalogStore);

    await editCatalogStore
      .updateCatalogItem(catalogItem['id'], uploads)
      .then(getCatalogItem)
      .then(async () => {
        const results = await catalogStore.allCatalogs(1, 30);
        userCatalogItemsStore.setUserCatalogItems([]);
        return results;
      })
      .then(async () => {
        setPendingState(false);
        setEditState(!editState);
      });
  };

  const toggleSerialMask = () => {
    setSerialMask(!serialMask);
  };

  const handleMouseDownSerialMask = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleBreadcrumbClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    const anchor = event.currentTarget.getAttribute('href');

    history.push(anchor);
  };

  const handleImageChange = (
    event: { preventDefault: () => void },
    image: any
  ) => {
    event.preventDefault();
    setLargeImage(image);
  };

  const Icon = (firearm: any) => {
    let Icon = null;

    Icon = iconsComponent[spaceToLowdash(firearm.type, 'lower')];

    const FetchedIcon =
      firearm.subtype in Icon['subtypes']
        ? iconsComponent[firearm.type]['subtypes'][firearm.subtype]
        : iconsComponent[firearm.type]['main'];

    return (
      <FetchedIcon
        className={firearm.className}
        fontSize='large'
        // style={{ fontSize: 400 }}
      />
    );
  };

  const getRelatedCalibers = async (search: String) => {
    const results = await firearmCalibersStore.getFirearmCalibers(
      catalogItem['firearm_type']['name'],
      search
    );

    return results;
  };

  useEffect(() => {
    (async () => {
      await getCatalogItem();
    })();
  }, [getCatalogItem]);

  useEffect(() => {
    // console.log('***', 'edit store updated', editCatalogStore);
  }, [editCatalogStore]);

  // useEffect(() => {
  //   console.log('***', 'largeImage', largeImage);
  // }, [largeImage]);

  useEffect(() => {
    (async () => {
      if (deleteState) {
        await catalogStore.deleteCatalogItem(catalogItem['id']).then(() => {
          history.push('/');
        });
      }
    })();
  }, [catalogItem, catalogStore, deleteState, history]);

  return (
    <>
      {loading || deleteState ? (
        <div className={classes.centerContent}>
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <Grid item xs={12}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                spacing={4}
              >
                <Grid item xs={12} md={8}>
                  <Loader color='primary' size={200} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : (
        <Grid
          alignItems='flex-start'
          container
          direction='row'
          item
          justifyContent='flex-start'
          spacing={4}
        >
          <AddItemToCatalogModal modalOpen={open} handleOpenModal={handleOpen}>
            <AddItemToCatalog />
          </AddItemToCatalogModal>

          <Grid item xs={12}>
            <Toolbar
              disableGutters
              variant='dense'
              className={classes.catalogToolbar}
            >
              <Breadcrumbs
                separator={<NavigateNext fontSize='small' color='secondary' />}
                aria-label='breadcrumb'
                className={classes.breadcrumbNav}
              >
                <Link
                  href='/'
                  color='secondary'
                  onClick={handleBreadcrumbClick}
                >
                  Dashboard
                </Link>
                <Typography color='textPrimary'>
                  {catalogItem['manufacturer'].name} -{' '}
                  {catalogItem['manufacturer_model'].name}
                </Typography>
              </Breadcrumbs>

              {editState && (
                <>
                  <Typography>Editing&hellip;</Typography>
                  <LoadingButton
                    color='primary'
                    disableElevation
                    loading={pendingState}
                    onClick={handleSaveChanges}
                    type='submit'
                    size='small'
                    variant='outlined'
                  >
                    Save Changes
                  </LoadingButton>
                  <InfoDialog />
                </>
              )}

              <EditCatalogNavigation
                catalogId={catalogItem['id']}
                handleRemoveCatalogItem={handleRemoveCatalogItem}
                toggleEditState={toggleEditState}
                editState={editState}
              />
            </Toolbar>

            <Typography variant='h1'>
              {catalogItem['manufacturer'].name}
            </Typography>

            <Typography variant='h2'>
              {catalogItem['manufacturer_model'].name}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              item
              spacing={4}
              direction='row'
              justifyContent='center'
              alignItems='flex-start'
            >
              <Grid item xs={12} sm={6}>
                {isEmpty(catalogItem['photos']) && (
                  <Icon
                    type={spaceToLowdash(
                      catalogItem['firearm_type']['name'],
                      'lower'
                    )}
                    subtype={spaceToLowdash(
                      catalogItem['firearm_subtype']['name'],
                      'lower'
                    )}
                    className='mgc-icon mega'
                  />
                )}
                {!isEmpty(catalogItem['photos']) && largeImage && (
                  <Grid container item direction='row' spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          width: '100%',
                          '& img': {
                            width: '100%',
                          },
                        }}
                      >
                        <img src={getPhoto(largeImage)} />
                      </Box>
                    </Grid>

                    {catalogItem['photos'].map((item: any, index: any) => (
                      <Grid item xs={2} key={index}>
                        <Box
                          sx={{
                            width: '100%',
                            '& img': {
                              width: '100%',
                            },
                          }}
                        >
                          <Link
                            href='#'
                            onClick={(event: any) =>
                              handleImageChange(event, item)
                            }
                          >
                            <img
                              src={getPhoto(item, {
                                height: 300,
                                width: 300,
                              })}
                              id={item}
                              alt={`${catalogItem['manufacturer']['name']} - ${catalogItem['firearm_type']['name']} - ${catalogItem['firearm_subtype']['name']} - photo-${index}`}
                            />
                          </Link>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={6}>
                    {!editState && (
                      <>
                        <Typography variant='subtitle2'>
                          {GeneralConst.LABELS.SERIAL}:
                        </Typography>
                        <Typography gutterBottom>
                          <Box component='span'>
                            <Box
                              component='span'
                              className={serialMask ? classes.masked : ''}
                            >
                              {catalogItem['serial_number'] === null && <>--</>}
                              {catalogItem['serial_number'] !== null &&
                              serialMask
                                ? maskSensitiveData(
                                    catalogItem['serial_number']
                                  )
                                : catalogItem['serial_number']}
                            </Box>
                            {catalogItem['serial_number'] !== null && (
                              <Box component='span'>
                                <IconButton
                                  aria-label='toggle serial number visibility'
                                  onClick={toggleSerialMask}
                                  onMouseDown={handleMouseDownSerialMask}
                                  size='small'
                                >
                                  {!serialMask ? (
                                    <VisibilityOutlined
                                      fontSize='inherit'
                                      aria-label='Hide Serial Number'
                                      color='primary'
                                    />
                                  ) : (
                                    <VisibilityOffOutlined
                                      fontSize='inherit'
                                      aria-label='Show Serial Number'
                                    />
                                  )}
                                </IconButton>
                              </Box>
                            )}
                          </Box>
                        </Typography>
                      </>
                    )}

                    {editState && (
                      <FormControl required fullWidth>
                        <InputLabel htmlFor='serialNumber'>
                          {GeneralConst.LABELS.SERIAL}
                        </InputLabel>

                        <Input
                          id='serialNumber'
                          onChange={(e) =>
                            editCatalogStore.setSerialNumber(e.target.value)
                          }
                          // defaultValue={catalogItem['serial_number']}
                          type={!serialMask ? 'text' : 'password'}
                          required
                          endAdornment={
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle serial number visibility'
                                onClick={toggleSerialMask}
                                onMouseDown={handleMouseDownSerialMask}
                                size='small'
                              >
                                {/* {isEmpty(catalogItem['serial_number']) && (
                                  <>--</>
                                )}
                                {!isEmpty(catalogItem['serial_number']) &&
                                !serialMask ? (
                                  <VisibilityOutlined
                                    fontSize='inherit'
                                    color='primary'
                                  />
                                ) : (
                                  <VisibilityOffOutlined
                                    fontSize='inherit'
                                    color='secondary'
                                  />
                                )} */}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant='subtitle2'>
                      {GeneralConst.LABELS.SUBTYPE}:
                    </Typography>

                    <Typography gutterBottom>
                      {ifEmpty(catalogItem['firearm_subtype']['name'])}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    {!editState && (
                      <>
                        <Typography variant='subtitle2'>
                          {GeneralConst.LABELS.PURCHASE_DATE}:
                        </Typography>

                        <Typography gutterBottom>
                          {ifEmpty(
                            catalogItem['purchase_date']
                              ? moment(catalogItem['purchase_date']).format(
                                  'LL'
                                )
                              : ''
                          )}
                        </Typography>
                      </>
                    )}

                    {editState && (
                      <FormControl fullWidth>
                        <DatePicker
                          label={GeneralConst.LABELS.PURCHASE_DATE}
                          value={
                            !editCatalogStore.purchaseDate
                              ? !catalogItem['purchase_date']
                                ? ''
                                : moment(
                                    moment(
                                      catalogItem['purchase_date']
                                    ).toDate()
                                  ).format('MM/DD/YYYY')
                              : moment(
                                  moment(editCatalogStore.purchaseDate).toDate()
                                ).format('MM/DD/YYYY')
                          }
                          onChange={(newValue) =>
                            editCatalogStore.setPurchaseDate(newValue)
                          }
                          disableFuture
                          renderInput={(textParams) => (
                            <TextField {...textParams} variant='standard' />
                          )}
                        />
                      </FormControl>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant='subtitle2'>
                      {GeneralConst.LABELS.AMOUNT}:
                    </Typography>

                    <Typography gutterBottom>
                      {`$${ifEmpty(catalogItem['purchase_amount'])}`}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    {!editState && (
                      <>
                        <Typography variant='subtitle2'>
                          {GeneralConst.LABELS.CALIBER}:
                        </Typography>

                        <Typography gutterBottom>
                          {!isEmpty(catalogItem['firearm_caliber']) &&
                            catalogItem['firearm_caliber'] !== null && (
                              <>{catalogItem['firearm_caliber']['name']}</>
                            )}
                          {isEmpty(catalogItem['firearm_caliber']) && <>--</>}
                        </Typography>
                      </>
                    )}

                    {editState && (
                      <ComboBox
                        getSearch={getRelatedCalibers}
                        handleCloseChangeBlur={() => {
                          firearmCalibersStore.setFirearmCalibers([]);
                        }}
                        handleChangeClear={() => {
                          firearmCalibersStore.setFirearmCalibers([]);
                        }}
                        textFieldLabel={GeneralConst.LABELS.CALIBER}
                        value={
                          editCatalogStore.caliber
                            ? {
                                id: catalogStore.caliber,
                                name: catalogStore.caliberName,
                              }
                            : null
                        }
                        startingOptions={async () => {
                          if (editCatalogStore.caliber) {
                            return [
                              {
                                id: catalogStore.caliber,
                                name: catalogStore.caliberName,
                              },
                            ];
                          } else if (
                            firearmCalibersStore.FirearmCalibers.length > 0
                          ) {
                            return firearmCalibersStore.FirearmCalibers;
                          } else {
                            return await getRelatedCalibers('');
                          }
                        }}
                        handleSelectedOption={(data) => {
                          catalogStore.setCaliber(data ? data.id : '');
                          catalogStore.setCaliberName(data ? data.name : '');
                        }}
                      />
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant='subtitle2'>
                      {GeneralConst.LABELS.CONDITION}:
                    </Typography>

                    <Typography gutterBottom>
                      {ifEmpty(catalogItem['condition'])}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant='subtitle2'>
                      {GeneralConst.LABELS.CURIOS_RELIC}:
                    </Typography>

                    <Typography gutterBottom>
                      {ifEmpty(catalogItem['curios'])}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant='subtitle2'>
                      {GeneralConst.LABELS.FINISH}:
                    </Typography>

                    <Typography gutterBottom>
                      {ifEmpty(catalogItem['finish_type'])}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant='subtitle2'>
                      {GeneralConst.LABELS.DESCRIPTION}:
                    </Typography>

                    <Typography gutterBottom>
                      {!isEmpty(catalogItem['description']) &&
                      catalogItem['description'] !== null
                        ? formatMultiLineText(catalogItem['description'])
                        : '--'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
});
