import { Box, Container, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { WrapWithLink } from '../../../hoc';

interface Props {
  children?: React.ReactElement | undefined;
  icon?: React.ReactElement | undefined;
  policyLinkInternal?: boolean | true;
  policyLinkURL?: string | undefined;
  policyLinkText?: string | undefined;
  text: string | undefined;
}

export default (props: Props) => {
  const {
    children,
    icon,
    policyLinkInternal,
    policyLinkURL,
    policyLinkText,
    text,
  } = props;

  return (
    <Box
      sx={{
        backgroundColor: 'primary.contrastText',
        bottom: 0,
        boxShadow:
          '0px -2px 4px -1px rgba(0,0,0,0.2), 0px -4px 5px 0px rgba(0,0,0,0.14), 0px -1px 10px 0px rgba(0,0,0,0.12)',
        display: 'flex',
        left: 0,
        paddingBottom: 2,
        paddingLeft: 3,
        paddingRight: 3,
        paddingTop: 2,
        position: 'fixed',
        width: '100vw',
      }}
    >
      <Container maxWidth='lg' disableGutters>
        <Grid
          container
          spacing={1}
          alignItems='center'
          justifyContent='flex-start'
        >
          <Grid item>{icon}</Grid>
          <Grid item flexGrow={1}>
            {text}

            {policyLinkURL && policyLinkText && (
              <Typography component='span'>
                {' '}
                <WrapWithLink
                  link={policyLinkURL}
                  internal={policyLinkInternal}
                  className='styled--link'
                  color='secondary'
                >
                  {policyLinkText}
                </WrapWithLink>
                .
              </Typography>
            )}
          </Grid>
          <Grid item>{children}</Grid>
        </Grid>
      </Container>
    </Box>
  );
};
