import React from 'react';

/*
 * Contents:
 *
 * formatRegAndTrade
 * pluralize
 * spaceToLowdash
 */

export const formatRegAndTrade = (text, sup = false) => (
  <span
    dangerouslySetInnerHTML={{
      __html: text
        .replace(/&reg;/gi, sup ? '<sup>&reg;</sup>' : '&reg;')
        .replace(/&trade;/gi, sup ? '<sup>&trade;</sup>' : '&trade;')
        .replace(/&copy;/gi, sup ? '<sup>&copy;</sup>' : '&copy;'),
    }}
  />
);

export function pluralize(val, word, plural = word + 's') {
  const _pluralize = (num, word, plural = word + 's') =>
    [1, -1].includes(Number(num)) ? word : plural;

  if (typeof val === 'object')
    return (num, word) => _pluralize(num, word, val[word]);

  return _pluralize(val, word, plural);
}

export function spaceToLowdash(text, fontcase) {
  return fontcase === 'lower'
    ? text.toLowerCase().replace(/-|\s|[\\|/]/g, '_')
    : text.replace(/-|\s|[\\|/]/g, '_');
}
