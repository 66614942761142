import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Zoom from '@material-ui/core/Zoom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Add, ImportContacts } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import { observer } from 'mobx-react-lite';
import { isEmpty } from 'ramda';
import InfiniteScroll from 'react-infinite-scroller';
import { Loader } from '../../atoms';
import { AddItemToCatalogModal } from '../../organisms';
import { useStores } from '../../../models';
import { AddItemToCatalog } from '../AddItemToCatalog';
import ContentConst from '../../../constants/ContentConst';
import { iconContainerProps } from '../../../helpers/Icons';
import {
  countMatchedFirearmTypes,
  formatStringStrong,
  replaceStringItems,
} from '../../../helpers/Data';
import { iconsComponent } from '../../../helpers/Icons';
import { spaceToLowdash } from '../../../helpers/Typography';
import { toJS } from 'mobx';

import { getPhoto } from '../../../helpers/Photo';

const useStyles = makeStyles((theme: Theme) => ({
  centerContent: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
  },
  appbarNotScrolled: {
    backgroundColor: 'transparent',
  },
  dialogAppBar: {
    position: 'relative',
  },
  dialogToolbar: {
    maxWidth: 'unset',
  },
  dialogTitle: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  toolbar: {
    '& > *:not(div)': {
      margin: theme.spacing(1),
    },
  },
  filterToolbar: {
    zIndex: 1,
    marginBottom: theme.spacing(4),
    '& > *:not(div)': {
      margin: theme.spacing(0, 2),
      '&:first-of-type': {
        marginLeft: 0,
      },
    },
  },
  noImage: {
    fontSize: '13.813rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '7.813rem',
    },
  },
  fab: {
    position: 'fixed',
    top: theme.spacing(10),
    right: theme.spacing(2),
  },
  media: {
    height: 224,
  },
  iconCard: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default observer(() => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState<boolean>(false);
  const [types, setTypes] = useState([]);
  const [typeFilter, setTypeFilter] = useState('all');
  const [emptyTypePage, setEmptyTypePage] = useState(false);
  const history = useHistory();
  const [openMenu, setOpenMenu] = React.useState(false);
  const [hasMorePages, setHasMorePages] = React.useState(true);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const {
    catalogStore,
    firearmCalibersStore,
    firearmTypesStoreModel,
    manufacturerModelsStore,
    manufacturersStore,
    subtypesStore,
    userCatalogItemsStore,
  } = useStores();

  const hideUnfoundTypes = useCallback(() => {
    const firearmCards = document.querySelectorAll('.card--firearm-selection');

    if (firearmCards.length) {
      firearmCards.forEach((firearmCard) => {
        if (
          window.getComputedStyle(firearmCard.parentElement).visibility ===
          'hidden'
        ) {
          if (!firearmCard.parentElement.classList.contains('hidden'))
            firearmCard.parentElement.classList.add('hidden');
        }
      });
    }
  }, []);

  const getUserCatalog = useCallback(
    async (page = 1, per_page = 30) => {
      const results = await catalogStore.allCatalogs(page, per_page);

      // console.log('***', 'results', results);

      const maxPages = Math.ceil(results.count / per_page);
      
      if (page > 1) {
        userCatalogItemsStore.appendUserCatalogItems(results.catalog_items);
      } else {
        userCatalogItemsStore.setUserCatalogItems(results.catalog_items);
      }

      if (page < maxPages) {
        setHasMorePages(true);
      } else {
        setHasMorePages(false);
      }

      hideUnfoundTypes();
    },
    [catalogStore, hideUnfoundTypes, userCatalogItemsStore]
  );

  const getTypesList = useCallback(async () => {
    const results = await firearmTypesStoreModel.getFirearmTypes();
    setTypes(results);
    return results;
  }, [firearmTypesStoreModel]);

  const handleOpen = () => {
    manufacturersStore.setManufacturers([]);
    manufacturerModelsStore.setManufacturerModels([]);
    subtypesStore.setSubtypes([]);
    firearmCalibersStore.setFirearmCalibers([]);
    setOpen(!open);
  };

  const handleCatalogEntryClick = (event: React.MouseEvent<HTMLDivElement>) => {
    history.push(`/catalog/${event.currentTarget.dataset.id}`);
  };

  const handleFilterCatalogOnType = (event) => {
    setTypeFilter(event.currentTarget.dataset.filterBy);

    if (event.currentTarget.dataset.numberOfItems === '0')
      setEmptyTypePage(true);
    else setEmptyTypePage(false);
  };

  const handleOnEnter = (element) => {
    if (element.classList.contains('hidden'))
      element.classList.remove('hidden');
  };

  const handleOnExit = (element) => {
    if (!element.classList.contains('hidden')) element.classList.add('hidden');
  };

  const handleToggleMenu = () => {
    setOpenMenu((prevOpenMenu) => !prevOpenMenu);
  };

  const handleClose = (event: any) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenMenu(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenMenu(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(openMenu);
  React.useEffect(() => {
    if (prevOpen.current === true && openMenu === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = openMenu;
  }, [openMenu]);

  useEffect(() => {
    (async () => {
      if (loading) {
        userCatalogItemsStore.setUserCatalogItems([]);
        await getUserCatalog();
        await getTypesList();

        // toggleLoading();
        setLoading(false);
      }
    })();

    hideUnfoundTypes();
  }, [
    emptyTypePage,
    getTypesList,
    getUserCatalog,
    hideUnfoundTypes,
    loading,
    // toggleLoading,
    userCatalogItemsStore,
  ]);

  const loader = (
    <div className='loader' key={0}>
      Loading ...
    </div>
  );

  return (
    <>
      {loading ? (
        <div className={classes.centerContent}>
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <Grid item xs={12}>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                spacing={4}
              >
                <Grid item xs={12} md={8}>
                  <Loader color='primary' size={200} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : (
        <>
          <Fab
            className={classes.fab}
            color='primary'
            aria-label='add'
            size='large'
            onClick={handleOpen}
          >
            <Add />
          </Fab>

          <AddItemToCatalogModal modalOpen={open} handleOpenModal={handleOpen}>
            <AddItemToCatalog />
          </AddItemToCatalogModal>

          {!isEmpty(toJS(userCatalogItemsStore.userCatalogItems)) && (
            <>
              <Toolbar
                className={classes.filterToolbar}
                disableGutters
                variant='dense'
              >
                <Hidden smDown>
                  <Link
                    variant='body1'
                    color='secondary'
                    component='button'
                    onClick={handleFilterCatalogOnType}
                    data-filter-by='all'
                  >
                    All
                  </Link>

                  {types.map((type, index) => {
                    return (
                      <Link
                        key={index}
                        variant='body1'
                        color='secondary'
                        component='button'
                        onClick={handleFilterCatalogOnType}
                        data-filter-by={type.name}
                        data-number-of-items={countMatchedFirearmTypes(
                          toJS(userCatalogItemsStore.userCatalogItems),
                          type.name
                        )}
                      >
                        {type.name}
                      </Link>
                    );
                  })}
                </Hidden>

                <Hidden smUp>
                  <Button
                    ref={anchorRef}
                    aria-controls={openMenu ? 'menu-list-grow' : undefined}
                    aria-haspopup='true'
                    onClick={handleToggleMenu}
                    color='secondary'
                  >
                    Filter by type
                  </Button>

                  <Popper
                    open={openMenu}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom'
                              ? 'center top'
                              : 'center bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={openMenu}
                              id='menu-list-grow'
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem
                                onClick={handleFilterCatalogOnType}
                                data-filter-by='all'
                              >
                                All
                              </MenuItem>

                              {types.map((type, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    onClick={handleFilterCatalogOnType}
                                    data-filter-by={type.name}
                                    data-number-of-items={countMatchedFirearmTypes(
                                      toJS(
                                        userCatalogItemsStore.userCatalogItems
                                      ),
                                      type.name
                                    )}
                                  >
                                    {type.name}
                                  </MenuItem>
                                );
                              })}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Hidden>
              </Toolbar>

              <Grid
                alignItems='flex-start'
                container
                direction='row'
                item
                justifyContent='flex-start'
                spacing={4}
              >
                {emptyTypePage && (
                  <Grid item container direction='column'>
                    <Typography variant='h2'>
                      {ContentConst.USER_CATALOG.EMPTY_TYPE.HEADING}
                    </Typography>

                    <Typography>
                      {formatStringStrong(
                        replaceStringItems(
                          ContentConst.USER_CATALOG.EMPTY_TYPE.SUB_HEADING,
                          [typeFilter]
                        ),
                        typeFilter
                      )}
                    </Typography>
                  </Grid>
                )}

                {!emptyTypePage && (
                  <Grid
                    item
                    container
                    direction='row'
                    xs={12}
                    component={InfiniteScroll}
                    pageStart={1}
                    loadMore={getUserCatalog}
                    hasMore={hasMorePages}
                    loader={loader}
                  >
                    <Grid item container spacing={4}>
                      {toJS(userCatalogItemsStore.userCatalogItems).map(
                        (item, index) => {
                          let Icon = null;

                          Icon =
                            iconsComponent[
                              spaceToLowdash(item.firearm_type.name, 'lower')
                            ];

                          if (
                            !(
                              spaceToLowdash(
                                item.firearm_subtype.name,
                                'lower'
                              ) in Icon['subtypes']
                            )
                          ) {
                            Icon = Icon['main'];
                          } else {
                            Icon =
                              Icon['subtypes'][
                                spaceToLowdash(
                                  item.firearm_subtype.name,
                                  'lower'
                                )
                              ];
                          }

                          return (
                            <Zoom
                              in={
                                typeFilter === item.firearm_type.name ||
                                typeFilter === 'all'
                                  ? true
                                  : false
                              }
                              key={index}
                              onEnter={handleOnEnter}
                              onExit={handleOnExit}
                            >
                              <Grid item key={index} xs={12} sm={4}>
                                <Card
                                  className='card--firearm-selection'
                                  elevation={8}
                                  data-id={item.id}
                                  data-manufacturer={item.manufacturer.name}
                                  data-firearm-subtype={
                                    item.firearm_subtype.name
                                  }
                                  onClick={handleCatalogEntryClick}
                                >
                                  <CardActionArea>
                                    {item.photos.length > 0 && (
                                      <CardMedia
                                        className={classes.media}
                                        image={getPhoto(item.photos[0], {
                                          width: 390,
                                          height: 224,
                                        })}
                                        title={item.manufacturer.name}
                                      />
                                    )}
                                    {item.photos.length === 0 && (
                                      <CardMedia className={classes.iconCard}>
                                        <Icon className='mgc-icon no-image--placeholder' />
                                      </CardMedia>
                                    )}
                                    <CardContent>
                                      <Typography variant='h5' component='h2'>
                                        {item.manufacturer.name}
                                      </Typography>
                                      <Typography variant='body2'>
                                        {item.manufacturer_model.name}
                                      </Typography>
                                    </CardContent>
                                  </CardActionArea>
                                </Card>
                              </Grid>
                            </Zoom>
                          );
                        }
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </>
          )}
          {isEmpty(toJS(userCatalogItemsStore.userCatalogItems)) && (
            <div className={classes.centerContent}>
              <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    spacing={4}
                  >
                    <Grid item xs={12} md={8}>
                      <Avatar {...iconContainerProps}>
                        <ImportContacts
                          className='icon--empty-catalog'
                          style={{ color: grey[400], fontSize: '11.25rem' }}
                        />
                      </Avatar>

                      <Typography
                        variant='h4'
                        align='center'
                        className='preferred-font'
                        gutterBottom
                      >
                        {ContentConst.USER_CATALOG.EMPTY.HEADING}
                      </Typography>

                      <Typography
                        variant='subtitle1'
                        align='center'
                        gutterBottom
                      >
                        {ContentConst.USER_CATALOG.EMPTY.SUB_HEADING.PRE}{' '}
                        <Fab
                          onClick={handleOpen}
                          size='small'
                          color='primary'
                          aria-label='Add to your catalog'
                        >
                          <Add />
                        </Fab>{' '}
                        {ContentConst.USER_CATALOG.EMPTY.SUB_HEADING.POST}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
        </>
      )}
    </>
  );
});
