import React from 'react';
import { MGCIconLogo } from '../icons/general';
import './Loader.scss';

interface LoaderProps {
  color?: string | undefined;
  size?: number | undefined;
  linear?: boolean | false; // use linear or linear={true} to make animation linear
}

export default (props: LoaderProps) => (
  <div>
    <MGCIconLogo
      className={`loaderMGC ${props.linear ? 'linear' : ''}`}
      color={props.color}
      style={{ fontSize: props.size }}
    />
  </div>
);
