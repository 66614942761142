import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default (props: any) => {
  return (
    <SvgIcon {...props} viewBox='0 0 150 150'>
      <path
        className='color--primary'
        d='M105.8,61.8H81.6c-2.4,0-4.3,1.8-4.3,3.9h4.3h4.3h19.9V61.8z M72.4,68.6h-4.1c0,3.8-1.9,5-4.6,5v0
        c0,0.3-0.1,0.6-0.1,0.9h2.8C71,74.5,72.4,73.5,72.4,68.6L72.4,68.6z M60.3,69.6c-0.3-0.1-0.6,0-0.7,0.3l-0.7,1.9
        c-0.4,0.1-0.7,0.3-0.8,0.7l-0.9,2.6c-1.1,0.1-2,0.9-2.3,2l0,0l-5.7,15.7l0,0c-0.2,0.4-0.2,0.8-0.2,1.2c0,1.7,1.3,3,2.9,3
        c1,0,1.8-0.5,2.3-1.2l0,0l0,0c0.3-0.4,0.5-0.9,0.5-1.4l5.5-14.9c0-0.1,0.1-0.2,0.1-0.3l0.1-0.1l0,0c0.1-0.3,0.1-0.5,0.1-0.8
        c0-1-0.5-1.9-1.2-2.4l0.8-2.4c0.1-0.4,0.1-0.8-0.2-1.1l0.7-1.9C60.7,70.1,60.6,69.7,60.3,69.6L60.3,69.6z M55.5,56.9
        c-0.2-0.1-0.5-0.1-0.7,0.2l-2,3.8c-0.1,0.3-0.1,0.6,0.2,0.7c0.1,0,0.2,0.1,0.2,0.1c0.2,0,0.3-0.1,0.4-0.3l2-3.8
        C55.8,57.4,55.7,57.1,55.5,56.9L55.5,56.9z M50.8,61.5l2-3.8c0.1-0.3,0.1-0.6-0.2-0.7c-0.2-0.1-0.5-0.1-0.7,0.2l-2,3.8
        c-0.1,0.3-0.1,0.6,0.2,0.7c0.1,0,0.2,0.1,0.2,0.1C50.5,61.8,50.7,61.7,50.8,61.5L50.8,61.5z M47.8,61.5l2-3.8
        c0.1-0.3,0.1-0.6-0.2-0.7c-0.2-0.1-0.5-0.1-0.7,0.2l-2,3.8c-0.1,0.3-0.1,0.6,0.2,0.7c0.1,0,0.2,0.1,0.2,0.1
        C47.6,61.8,47.8,61.7,47.8,61.5L47.8,61.5z M106.8,50v4v12.3H77.3v3.3c0,4.8-3.9,8.6-8.7,8.6h-4.8c0,0-1,13.1-1.3,16.9
        c-0.3,3.8-1.7,6-4.2,6h-13c-1.7,0-3-1.7-1.9-5.6c1.2-3.9,7.1-14.8,7.1-20.4s-5.2-8.8-5.2-8.8v-1.2h30.2v-1.1H45.3v-7.2h59.8v-1.1
        H45.3V54h2.8v-1.2v-0.5v-1.2c0-1,0.7-1.9,1.6-2.1v2.2h1.1V49c0.9,0.2,1.6,1.1,1.6,2.1v1.2h7.8V54h39.9L106.8,50z'
      />
      <path
        className='color--secondary'
        d='M89.1,62.2c0.1-0.2,0.3-0.2,0.5-0.1c0.2,0.1,0.2,0.3,0.1,0.5l0,0l-1.5,2.6
        c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.2-0.3-0.1-0.5l0,0L89.1,62.2z M85.8,62.2c0.1-0.2,0.3-0.2,0.5-0.1
        c0.2,0.1,0.2,0.3,0.1,0.5l0,0L85,65.1c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.2-0.3-0.1-0.5l0,0L85.8,62.2z M83,62
        c0.2,0.1,0.2,0.3,0.1,0.5l-1.5,2.6c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.2-0.3-0.1-0.5l1.5-2.6
        C82.6,62,82.8,61.9,83,62z'
      />
    </SvgIcon>
  );
};
